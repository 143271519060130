
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.06,"time":63600,"words":212}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Magical Attunement - 0 SP`}</strong><br parentName="p"></br>{`
`}{`Your senses attune to the hum of magical energies around you, giving you information about the class, spell points, and conditions of other wizards.`}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`When I move, you move - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose up to three wizards and perform a dance move.
Whenever you do the move again, those wizards must do it with you.
You may do this 3 times over the span of a few minutes.
Any wizards who don’t perform the dance move within a few seconds of you each time will lose 10 GHD points.  `}</p>
    <p><strong parentName="p">{`Invisibility - 1 SP`}</strong><br parentName="p"></br>{`
`}{`You cannot be targeted by other wizards’ spells until you cast another spell.
However, spells that can trigger a penalty without directly targeting you (such as `}<em parentName="p">{`Scorched Earth`}</em>{` or `}<em parentName="p">{`Blizzard`}</em>{`) can affect you and cancel your invisibility.`}</p>
    <p><strong parentName="p">{`Rush of Knowledge - 2 SP`}</strong><br parentName="p"></br>{`
`}{`Pick a standard spell (not a Class Feature or Archmage Ability) from any other wizard type and use it once.`}</p>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Bittersweet Symphony - 3 SP`}</strong><br parentName="p"></br>{`
`}{`Every other wizard gains one free cast of any of their standard spells.
They must all use this spell within the next 30 seconds.
You are immune to the effects of any spells cast during this time.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

