import React, { useState } from 'react';
import { GameNames } from '../../../../constants';
import { SpellOptions } from '../../../../types';
import { usePlayerData } from '../../../data-providers/players-provider';

interface HeatThingsUpProps {
  handleSelect: (options: SpellOptions) => void;
}

export const HeatThingsDoubleGamePoints = 'Double Game Points';
// export const HeatThingsUpRiskReward = 'Risk / Reward';
// export const HeatThingsUpEmpower = 'Empowerment';
export const HeatThingsUpConditions = 'Wild Magic';

const HeatThingsUp = ({ handleSelect }: HeatThingsUpProps) => {
  const [typeSelection, setTypeSelection] = useState<string>('');
  const { currentPlayer } = usePlayerData();
  const gameList: string[] = currentPlayer && Object.keys(currentPlayer.score) || [];
  const invalidGames = [GameNames.WisestWizard, GameNames.HorseRace, GameNames.Mournival];
  const filteredGameList = gameList.filter(x => !invalidGames.includes(x));

  const chooseOption = (option) => {
    setTypeSelection(option);
    handleSelect({ heatThingsUp: { type: option } });
  }

  const handleGameSelection = (game) => {
    handleSelect({ 
      heatThingsUp: {
        type: typeSelection,
        game,
      }
    })
  }

  return (
    <div >
      <p className='is-size-5 mb-4'>How do you want to heat things up?</p>
      <div className='block'>
        <label className='label'>1. Double game points</label>
        <span>All scores from the next game are doubled</span>
      </div>
      {/* <div className='block'>
        <label className='label'>2. Risk / Reward</label>
        <span>First place wizards gain a level, losers lose 3 HP and 10 GHD points</span>
      </div> */}
      <div className='block'>
        <label className='label'>2. Wild Magic</label>
        <span>Everybody gets assigned a random condition (Invisible, Silenced, Empowered, etc.)</span>
      </div>
      {/* <div className='block'>
        <label className='label'>3. Everyone empowered</label>
        <span>
          The next spell each wizard casts is <strong>empowered</strong>, meaning they can choose to either double their spell targets or double the effects of their spell.
        </span>
      </div> */}
      <div className='select mt-4'>
        <select onChange={(e) => chooseOption(e.target.value)}>
          <option value=''>select</option>
          <option value={HeatThingsDoubleGamePoints}>Double game points</option>
          {/* <option value={HeatThingsUpRiskReward}>Risk / Reward</option> */}
          <option value={HeatThingsUpConditions}>Wild Magic</option>
        </select>
      </div>

      {typeSelection === HeatThingsDoubleGamePoints &&
        <div className='mt-4'>
          <label className='label'>Which game scores should be doubled?</label>
          <div className='select'>
            <select onChange={(e) => handleGameSelection(e.target.value)}>
              <option value=''>select</option>
              {filteredGameList.map(gameId => (
                <option value={gameId}>{GameNames[gameId]}</option>
              ))}
            </select>
          </div>
        </div>
      }
      
    </div>
  );
}

export default HeatThingsUp;
