import React, {
  FunctionComponent,
  ReactElement,
  useState,
} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useAuth, signUp } from '../lib/auth';

interface LocationState {
  from: {
    pathname: string;
  };
}

const SignUp: FunctionComponent = (): ReactElement => {
  const currentUser = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<LocationState>();

  let { from } = location.state || { from: { pathname: "/" } };

  const handleSignup = async () => {
    try { 
      setLoading(true);
      await signUp(email, password, displayName);
    } catch (error) {
      alert(`error: ${error}`);
    }
    setLoading(false);
  }

   // redirect to main page or previous page after auth
   if (currentUser) history.replace(from);

  return (
    <div className='section'>
      <h1 className="title">Sign up</h1>
      <div className="field">
        <label className="label">Display name</label>
        <div className="control has-icons-left has-icons-right">
          <input 
            className="input" 
            type="text" 
            placeholder="Text input" 
            value={displayName} 
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-user"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Email</label>
        <div className="control has-icons-left has-icons-right">
          <input 
            className="input" 
            type="email" 
            placeholder="Email input" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Password</label>
        <p className="control has-icons-left">
          <input 
            className="input" 
            type="password"
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"></i>
          </span>
        </p>
      </div>

      <div className="field">
        <div className="control is-flex">
          <button className="button is-link" onClick={handleSignup} disabled={(!displayName || !password || !email)}>
            Submit
          </button>
          <a className="button is-ghost" href='/login'>
            Have an account? Log in
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
