import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

// data providers
import TournamentDataProvider from './components/data-providers/tournament-provider';
import PlayersDataProvider from './components/data-providers/players-provider';
import NotificationsDataProvider from './components/data-providers/notifications-provider';
import SpellHistoryDataProvider from './components/data-providers/spell-history-provider';

ReactDOM.render(
  <React.StrictMode>
    <TournamentDataProvider>
      <PlayersDataProvider>
        <SpellHistoryDataProvider>
          <NotificationsDataProvider>
            <App />
          </NotificationsDataProvider>
        </SpellHistoryDataProvider>
      </PlayersDataProvider>
    </TournamentDataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);