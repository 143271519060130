import React, { useState } from 'react';
import { Player } from '../../../../types';

interface PenaltyMap { [id: string]: number };
interface BlizzardTrackerProps {
  handleChange: ( penalties: PenaltyMap) => void;
  targets: Player[];
}

const BlizzardTracker = ({ handleChange, targets }: BlizzardTrackerProps) => {
  const [penalties, setPenalties] = useState<PenaltyMap>({});
  const maxBalls = targets.length;
  const ballCountReducer = (prev, current) => prev + current;
  const totalSunkBalls: any = Object.values(penalties).reduce(ballCountReducer, 0);
  // create a list of options based on number of players
  let options: number[] = [];
  for (let i=0; i <= maxBalls; i++) {
    options.push(i);
  }

  const handlePenaltyChange = (target: Player, balls: string) => {
    const ballNumber = parseInt(balls);
    const updatedPenalties = {
      ...penalties,
      [target.id]: ballNumber,
    };

    setPenalties(updatedPenalties);

    handleChange(updatedPenalties);
  }

  return (
    <div >
      <label className='label'>Select how many ping pong balls landed in each wizard's cup</label>
      {targets.map(t => (
        <div>
          <label className='label'>{t.userDetails?.displayName}</label>
          <div className='select is-medium is-fullwidth'>
            <select 
              onChange={(e) => handlePenaltyChange(t, e.target.value)}
            >
              <option value={0}>select</option>
              {options.map(number => (
                <option value={number}>{number}</option>
              ))}
            </select>
          </div>
        </div>
      ))}

      {totalSunkBalls > maxBalls && 
        <p className="help is-danger">
          You have selected more balls than you can distribute. The maximum number of balls should equal the number of affected players.
        </p>
      }
     
    </div>
  );
}

export default BlizzardTracker;
