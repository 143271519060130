import React, { createContext, useState, useEffect, useContext } from 'react';
import { SpellEvent } from '../../types';
import { useAuth } from '../../lib/auth';
import { getSpellHistoryQuery } from '../../lib/wizards';
import { onSnapshot } from '@firebase/firestore';

export interface SpellHistoryContextState {
  status: 'loading'|'error'|'success'; 
  allSpellHistory: SpellEvent[];
  playerSpellHistory: SpellEvent[];
}

const Context = createContext<SpellHistoryContextState|null>(null);

export const useSpellHistory = (): SpellHistoryContextState => {
  const SpellHistorycontextState = useContext(Context);
  if (SpellHistorycontextState === null) {
    throw new Error('Tournament provider error');
  }
  return SpellHistorycontextState;
}

const SpellHistoryDataProvider = (props) => {
  const [state, setState] = useState<SpellHistoryContextState>({status: 'loading', allSpellHistory: [], playerSpellHistory: []});
  const currentUser: any = useAuth();
  const uid = currentUser && currentUser.uid;

  // if authenticated, fetch spell history data
  useEffect(() => {
    // TODO: only fetch if user has an active tournament
    if (!currentUser) return;
    
    let unsubscribe: any;
    getSpellHistoryQuery(currentUser)
      .then((query) => {
        if (query) {
          unsubscribe = onSnapshot(query, (querySnapshot) => {
            const allSpellHistory: SpellEvent[] = [];
            const playerSpellHistory: SpellEvent[] = [];
            querySnapshot.forEach((doc) => {
              const spell = doc.data() as SpellEvent;

              allSpellHistory.push(spell);

              // separate spells that involve the current user
              if (spell.casterId === uid || spell.targetIds.includes(uid)) playerSpellHistory.push(spell);
            });

            setState({
              status: 'success',
              allSpellHistory,
              playerSpellHistory,
            });
          });
        }
      })
    

    if (unsubscribe) return unsubscribe();
  }, [currentUser]);

  return (
    <Context.Provider value={state}>
      {props.children}
    </Context.Provider>
  )
}

export default SpellHistoryDataProvider;

// Allows easily mocking data for unit tests without mocking API calls
export const TestSpellHistoryDataProvider = (props) => (
  <Context.Provider value={props.state}>
    {props.children}
  </Context.Provider>
);