import React, { useState } from 'react';
import { SpellNames, WizardClasses, WizardConditions } from '../../../../constants';
import { playerHasCondition } from '../spell-helpers';
import { Player, Spell } from '../../../../types';
import { usePlayerData } from '../../../data-providers/players-provider';
import Countdown from '../../../countdown';
import { castSpell, deletePlayerProperty } from '../../../../lib/players';

interface FireballTrackersProps {
  activePlayer: Player;
}

const FireballPenalty: Spell = {
  name: SpellNames.FireballPenalty,
  class: WizardClasses.Pyromancer,
  description: "The target loses 3 HP for not drinking a shot in time.",
  type: 'Standard Spell',
  spellPointCost: 0,
  targets: 1,
  selfTargetOnly: false,
  casterHitPointEffect: 0,
  casterSpellPointEffect: 0,
  casterScoreEffect: 0,
  casterLevelEffect: 0,
  casterConditionEffect: null,
  targetHitPointEffect: -3,
  targetSpellPointEffect: 0,
  targetScoreEffect: 0,
  targetLevelEffect: 0,
  targetConditionEffect: null,
}

const FireballTrackers = ({ activePlayer }: FireballTrackersProps) => {
  const [drankInTime, setDrankInTime] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>();
  const { allPlayers } = usePlayerData();
  const activeWizard = activePlayer.wizard;
  const activeWizardConditions = activeWizard.conditions;

  let fireballTarget;
  // TODO: In the future, handle more than 1 fireball targets at once
  if (activeWizardConditions[WizardConditions.Firing]) fireballTarget = allPlayers.find(p => activeWizardConditions[WizardConditions.Firing].affectedPlayers.includes(p.id));

  const toggleModal = () => {
    setDrankInTime('');
    setShowModal(!showModal);
  }

  const handleConditionOver = async (conditionName: string) => {
    if (!activePlayer) return;
    await deletePlayerProperty(activePlayer, `wizard.conditions.${conditionName}`);
  }

  const submitFireballResults = async () => {
    if (!activePlayer || !fireballTarget) return;
    
    if (drankInTime === 'no') {
      await castSpell(FireballPenalty, activePlayer, [fireballTarget], allPlayers, null);
    } 

    // remove Firing condition from caster after result is decided
    await deletePlayerProperty(activePlayer, `wizard.conditions.${WizardConditions.Firing}`);
  }

  return (
    <div className=''>
      {activePlayer && playerHasCondition(activePlayer, WizardConditions.Combustible) &&
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              You have&nbsp;
              <strong>
                <Countdown 
                  expirationTime={activeWizardConditions[WizardConditions.Combustible].expirationTime}
                  onTimerFinish={() => handleConditionOver(WizardConditions.Combustible)}
                />
              </strong>
              <span>&nbsp;to cast <strong>Fight Fire with Fire</strong>!</span>
            </p>
          </div>
        </article>
      }

      {activePlayer && playerHasCondition(activePlayer, WizardConditions.TakingFire) &&
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              You have&nbsp;
              <strong>
                <Countdown 
                  expirationTime={activeWizardConditions[WizardConditions.TakingFire].expirationTime}
                  onTimerFinish={() => handleConditionOver(WizardConditions.TakingFire)}
                />
              </strong>
              <span>&nbsp;to drink a shot!</span>
            </p>
          </div>
        </article>
      }

      {activePlayer && playerHasCondition(activePlayer, WizardConditions.Firing) && fireballTarget &&
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              <strong>{fireballTarget.userDetails.displayName}</strong> has&nbsp;
              <strong>
                <Countdown 
                  expirationTime={activeWizardConditions[WizardConditions.Firing].expirationTime}
                />
              </strong>
              <span>&nbsp;to drink a shot!</span>
            </p>
            <button className='button is-small is-warning' onClick={toggleModal}>
              More
            </button>
          </div>
        </article>
      }

      {fireballTarget &&
        <div className={`modal ${showModal ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Submit Fireball Results</p>
              <button className="delete" aria-label="close" onClick={toggleModal}/>
            </header>
            <section className="modal-card-body">
              <label className='label'>Did {fireballTarget.userDetails.displayName} drink a shot in time?</label>
              <div className='select'>
                <select onChange={(e) => setDrankInTime(e.target.value)}>
                  <option value=''>select</option>
                  <option value={'yes'}>Yes</option>
                  <option value={'no'}>No</option>
                </select>
              </div>

            </section>
            <footer className="modal-card-foot">
              <button 
                className="button is-primary" 
                onClick={submitFireballResults}
                disabled={Boolean(!drankInTime)}
              >
                Confirm
              </button>
              <button className="button" onClick={toggleModal}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      }
      
    </div>
  );
}

export default FireballTrackers;
