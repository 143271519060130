import React, {
  FunctionComponent,
  ReactElement,
  useState,
} from 'react';
import { useAuth, logOut } from '../lib/auth';


const Header: FunctionComponent = (): ReactElement => {
  const currentUser = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [gameListOpen, setGameListOpen] = useState(false);

  const toggleGames = () => {
    setGameListOpen(!gameListOpen);
  }

  return (
    <nav className="navbar is-dark has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand is-flex is-justify-content-space-between">
        <span className="navbar-item">
          Groundhog Day
        </span>

        <div className='is-flex is-align-items-center'>
          {/* <i className="fa fa-bell"></i> */}
          <a 
            onClick={() => setMenuOpen(!menuOpen)} 
            role="button" className={`navbar-burger ${menuOpen ? 'is-active' : ''}`} 
            aria-label="menu" 
            aria-expanded={menuOpen} 
            data-target="app-nav"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        
      </div>

      <div id='app-nav' className={`navbar-menu ${menuOpen ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <a className="navbar-item" href="/home">
            Home
          </a>

          <a className="navbar-item" href='/score'>
            Score
          </a>

          <div className={`navbar-item has-dropdown ${gameListOpen ? 'is-active' : ''}`}>
            <a className="navbar-link" onClick={toggleGames}>
              Games
            </a>

            {gameListOpen && 
              <div className="navbar-dropdown">
                <a className="navbar-item" href='/overall-rules'>
                  Overall Rules
                </a>
                <a className="navbar-item" href='/wisest-wizard'>
                  Wisest Wizard
                </a>
                <a className="navbar-item" href='/horse-race'>
                  Horse Race
                </a>
                <a className="navbar-item" href='/beer-ball'>
                  Beer Ball
                </a>
                <a className="navbar-item" href='/beerio-kart-relay'>
                  Beerio Kart Relay
                </a>
                <a className="navbar-item" href='/boat-race'>
                  Boat Race
                </a>
                <a className="navbar-item" href='/civil-war'>
                  Civil War
                </a>
                <a className="navbar-item" href='/flip-tac-toe'>
                  Flip Tac Toe
                </a>
                <a className="navbar-item" href='/flip-cup-survivor'>
                  Flip Cup Survivor
                </a>
                <a className="navbar-item" href='/shot-roulette'>
                  Shot Roulette
                </a>
                <a className="navbar-item" href='/hungry-hippos'>
                  Hungry Hungry Hippos
                </a>
                <a className="navbar-item" href='/mournival'>
                  Mournival
                </a>
              </div>
            }
            
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              {!currentUser &&
                <>
                  <a className="button is-primary" href='/signup'>
                    <strong>Sign up</strong>
                  </a>

                  <a className="button is-light" href='/login'>
                    Log in
                  </a>
                </>
              }

              {currentUser &&
                <a className="button is-light" onClick={logOut}>
                  Log out
                </a>
              }

            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
