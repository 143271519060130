import React from 'react';
import { Switch, useLocation, Route } from 'react-router-dom';
import PrivateRoute from '../../auth/private-route';
import Spellbook from './spellbook';
import WizardClasses from './wizard-classes';
import WizardStatTracker from './wizard-stat-tracker';
import WisestWizardRules from '../../../rules/wisest-wizard.mdx';
import { usePlayerData } from '../../data-providers/players-provider';

const WisestWizard = () => {
  const location = useLocation();
  const { currentPlayer } = usePlayerData();

  function isTabActive(path) {
    if (location.pathname === path) return 'is-active';
    return '';
  }


  return (
    <div className='section'>
      <nav className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <p className="title is-4">
              <strong>Wisest Wizard</strong>
            </p>
          </div>
        </div>

        <div className="level-right">
          <p className="level-item subtitle is-6"><strong>{currentPlayer?.userDetails?.displayName}</strong></p>
        </div>
      </nav>

      <WizardStatTracker />

      <div className="tabs is-fullwidth">
        <ul>
          <li className={`${isTabActive('/wisest-wizard')}`}>
            <a href='/wisest-wizard'>Rules</a>
          </li>
          <li className={`${isTabActive('/wisest-wizard/classes')}`}>
            <a href='/wisest-wizard/classes'>Classes</a>
          </li>
          <li className={`${isTabActive('/wisest-wizard/spellbook')}`}>
            <a href='/wisest-wizard/spellbook'>Spellbook</a>
          </li>
        </ul>
      </div>

      <Switch>
        <Route exact path='/wisest-wizard'>
          <div className="content">
            <WisestWizardRules />
          </div>
        </Route>
        <PrivateRoute path='/wisest-wizard/classes'>
          <WizardClasses />
        </PrivateRoute>

        <PrivateRoute path='/wisest-wizard/spellbook'>
          <Spellbook />
        </PrivateRoute>
      </Switch>

    </div>
  );
}

export default WisestWizard;
