
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.01,"time":60600,"words":202}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "beer-ball"
    }}>{`Beer Ball`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`To have all members of your team finish your beers before the opposing team.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Similar to beer pong this game is played on a long table.
Players are split into teams of 2 or 3.
Each team places one approved beverage on their side for each player on the team.  `}</p>
    <p>{`Each player takes a turn throwing a ping pong ball at the opposing team’s cans.
If the ball hits a can, the player that made the throw chugs their beer until the other team can touch the ball back to the table.
Once that ball has touched the table the beer drinking must immediately stop.  `}</p>
    <p>{`The first team to finish all the beers on their side wins.
While the game is usually played with four players, two on each side, the number of players doesn’t really change the rules any.  `}</p>
    {
      /* #### Heats
      There will be 3 heats. 14 players will split into 6 teams of 2-3 people.
      Heat 1: 2v2
      Heat 2: 2v2
      Heat 3: 3v3 */
    }
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring:`}</h4>
    <ul>
      <li parentName="ul">{`Each player on the winning team earns 100 points.`}</li>
      <li parentName="ul">{`The first player to finish his or her drink earns 10 bonus points.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "beer-ball",
    level: 3,
    title: "Beer Ball",
    children: [
        
      ]
  }
]

export const frontMatter = {}

