import React from 'react';
import { SpellNames, WizardConditions } from '../../../constants';
import { convertStringToId } from '../../../lib/utils';
import { playerHasAnyOfConditions, playerHasCondition } from './spell-helpers';
import { Player, Spell } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import { useTournamentData } from '../../data-providers/tournament-provider';

interface SpellCardProps {
  spell: Spell;
  expanded: boolean;
  spellSelectText: string;
  toggleExpand: (spellId: string) => void;
  onSpellSelect: (spell: Spell) => void;
  player: Player;
}

const SpellCard = ({spell, expanded, spellSelectText, toggleExpand, onSpellSelect, player}: SpellCardProps) => {
  const tournamentData = useTournamentData();
  const { currentPlayer } = usePlayerData();
  const wizard = player?.wizard;
  const spellId = convertStringToId(spell.name);

  const isSpellDisabled = (spell: Spell) => {
    if (!wizard || !player || !currentPlayer) return true;
    if (tournamentData?.value?.status !== 'InProgress') return true;
    if (spell.type === 'Archmage Ability' && !wizard.isArchmage) return true;
    // Divine Shield happens automatically, it is not casted
    if (spell.name === SpellNames.DivineShield) return true;

    const playerIsControlled = player.id !== currentPlayer.id;
    const notEnoughSpellPoints = spell.spellPointCost !== 0 && spell.spellPointCost > wizard?.spellPoints;
    // TODO: Allow controlling wizards to use their SP if the wizard under their control has no SP
    // const notEnoughSpellPoints = spell.spellPointCost > wizard?.spellPoints && spell.spellPointCost > currentPlayer.wizard?.spellPoints;
    if (notEnoughSpellPoints && !playerHasCondition(player, WizardConditions.Inspired)) return true;

    if (spell.name === SpellNames.AgniKai && playerHasCondition(player, WizardConditions.Dueling)) return true;
    // disable non-standard spells when player is Inspired
    if (spell.type !== 'Standard Spell' && playerHasCondition(player, WizardConditions.Inspired)) return true;

    //if the currentPlayer is controlling a player's spellbook, some spells should be enabled for currentPlayer
    if (!playerIsControlled) {
      // check for conditions such as Silenced
      if (playerHasAnyOfConditions(player, [
        WizardConditions.Reanimated, WizardConditions.Silenced
      ])) {
        return true;
      }
    }

    if (spell.name === SpellNames.FightFireWithFire && !playerHasCondition(player, WizardConditions.Combustible)) {
      // Fight Fire with Fire only enabled if target has been attacked recently
      return true;
    }

    return false;
  }

  return (
    <div className="card">
      <header className="card-header is-align-items-center">
        <p className="card-header-title is-justify-content-space-between">
          <button
            className="card-header-icon button pl-0"
            aria-label="more options"
            onClick={() => toggleExpand(spellId)}
          >
            <span className="icon">
              <i className={`fas fa-angle-${expanded ? 'up' : 'down'}`} aria-hidden="true"/>
            </span>
          </button>

          <nav className="level is-mobile mb-0 is-align-items-center is-justify-content-center">
            <div className="level-item has-text-centered">
              <div>
                <p className="title is-size-6">{spell.name} - {spell.spellPointCost} SP</p>
                <p className="heading has-text-weight-normal">{spell.type}</p>
              </div>
            </div>
          </nav>
          <span></span>
        </p>

        <button
          className='button is-link mr-2'
          onClick={() => onSpellSelect(spell)}
          disabled={isSpellDisabled(spell)}
        >
          {spellSelectText}
        </button>

      </header>

      {expanded &&
        <div className="card-content">
          <div className="content">
            {spell.description}
          </div>
        </div>
      }
    </div>
  );
}

export default SpellCard;
