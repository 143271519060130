import React, { useState } from 'react';
import { calculateRaceResults, updateRaceProperties } from '../../../lib/horse-race';
import { HorseRaceRoundResults } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import { useTournamentData } from '../../data-providers/tournament-provider';
import { HORSE_RACE_WAGERS } from './constants';
import { SuitSelect } from './horse-race-bid-sheet'


export const RaceResult = ({ raceNumber }) => {
  const [raceResults, setRaceResults] = useState<HorseRaceRoundResults|any>({});
  const { currentPlayer, allPlayers } = usePlayerData();
  const { value: tournamentData } = useTournamentData();
  const horseRaceData = tournamentData?.horseRace;
  const thisRaceData = horseRaceData && horseRaceData[raceNumber];
  const thisRaceStatus = thisRaceData?.status || '';
  const isHost = currentPlayer?.isHost;
  const raceNotStarted = Boolean(!thisRaceStatus || thisRaceStatus === 'NotStarted');
  const raceInProgress = Boolean(thisRaceStatus && thisRaceStatus === 'InProgress');
  const raceFinished = Boolean(thisRaceStatus && thisRaceStatus === 'Ended');
  const disableScore = !isHost || raceNotStarted || raceFinished;

  const handleResultSelect = async (id, selectedValue = '') => {
    setRaceResults({
      ...raceResults,
      [id]: selectedValue,
    });

    if (!tournamentData?.id) return;
    await updateRaceProperties(tournamentData?.id, raceNumber, 'InProgress', raceResults);
  }

  const handleUpdateRaceStatus = async (newStatus: string) => {
    if (!tournamentData?.id) return;

    await updateRaceProperties(tournamentData?.id, raceNumber, newStatus, raceResults);
  }

  const handleCalculateResults = async () => {
    if (!tournamentData || !tournamentData?.id) return;
    await updateRaceProperties(tournamentData?.id, raceNumber, 'Ended', raceResults);
    await calculateRaceResults(tournamentData, raceNumber, allPlayers);
  }

  return (
    <div className='box mb-6'>
      <h5 className='subtitle is-5'>Results</h5>
      <label className='label'>First out the gate</label>
      <SuitSelect 
        id='firstOut' 
        handleSelect={handleResultSelect}
        value={thisRaceData?.results.firstOut}
        disabled={!isHost || raceFinished}
      />

      <label className='label'>First place</label>
      <SuitSelect 
        id='firstPlace' 
        handleSelect={handleResultSelect}
        value={thisRaceData?.results.firstPlace}
        disabled={!isHost || raceFinished}
      />

      <label className='label'>Second place</label>
      <SuitSelect 
        id='secondPlace' 
        handleSelect={handleResultSelect}
        value={thisRaceData?.results.secondPlace}
        disabled={!isHost || raceFinished}
      />

      <label className='label'>Third place</label>
      <SuitSelect 
        id='thirdPlace' 
        handleSelect={handleResultSelect}
        value={thisRaceData?.results.thirdPlace}
        disabled={!isHost || raceFinished}
      />

      <label className='label'>Fourth place</label>
      <SuitSelect 
        id='fourthPlace' 
        handleSelect={handleResultSelect}
        value={thisRaceData?.results.fourthPlace}
        disabled={!isHost || raceFinished}
      />

      <div className='mt-4 is-flex'>
        <button 
          className='button is-light mr-3' 
          onClick={() => handleUpdateRaceStatus('InProgress')}
          disabled={!isHost || raceInProgress || raceFinished}
        >
          Start
        </button>
        <button 
          className='button is-light mr-3'
          onClick={() => handleUpdateRaceStatus('NotStarted')}
          disabled={!isHost || raceNotStarted || raceFinished}
        >
          Reset
        </button>
        <button 
          className='button is-primary'
          onClick={() => handleCalculateResults()}
          disabled={disableScore}
        >
          End and score
        </button>
      </div>
    </div>
  )
}

export const BetReview = ({ raceNumber }) => {
  const { currentPlayer } = usePlayerData();
  const betDetails = currentPlayer?.horseRaceBets && currentPlayer?.horseRaceBets[raceNumber];

  if (!betDetails) return (<></>);

  const wager = HORSE_RACE_WAGERS[betDetails.wager];

  return (
    <div className='box'>
      <h5 className='subtitle is-5'>Bet details</h5>
      <p><strong>Wager: </strong>{wager.name}</p>
      <p><strong>Points staked: </strong>{betDetails.stakedPoints}</p>
      {wager.choices.map(choice => (
        <p><strong>{choice.label}: </strong>{betDetails.choices[choice.id]}</p>
      ))}

      {betDetails.correct !== undefined &&
        <p><strong>Correct: </strong>{betDetails.correct ? 'Yes' : 'No'}</p>
      }
      {betDetails.pointChange &&
        <p><strong>Point change: </strong>{betDetails.pointChange}</p>
      }
      
    </div>
  )
}

const HorseRaceReview = () => {
  const races = [1, 2, 3];

  return (
    <div className=''>
      {races.map(x => (
        <div key={`horse-race-review-race-${x}`}>
          <h4 className='title is-4'>Race {x}</h4>
          <BetReview raceNumber={x} />
          <RaceResult raceNumber={x}/>
        </div>
      ))}
    </div>
  );
}

export default HorseRaceReview;
