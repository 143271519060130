import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { updatePlayerProperties } from '../lib/players';
import { useTournamentData } from './data-providers/tournament-provider';
import { usePlayerData } from './data-providers/players-provider';
import { roundTo2Decimals } from '../lib/utils';
import SpellActivityFeed from './games/wisest-wizard/spell-activity-feed';

const ScoreBoard = () => {
  const tournamentData = useTournamentData();
  const { allPlayers, currentPlayer } = usePlayerData();
  const [expandedRow, setExpandedRow] = useState<string>('');
  const [scoreAdjustments, setScoreAdjustments] = useState({});
  const [activeTab, setActiveTab] = useState<'playerScores'|'gameScores'>('playerScores');

  //TODO: allow (only) Hosts to make manual add rows for additional games
  allPlayers.sort((a, b) => {
    if (!a.hasOwnProperty('totalScore') || !b.hasOwnProperty('totalScore')) return 0;
    return b.totalScore - a.totalScore;
  });

  const isHost = currentPlayer && currentPlayer.isHost;
  const gameList = Object.keys(currentPlayer && currentPlayer.score || {});
  // sort list alphabetically
  gameList.sort((a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
  });

  const sortPlayersByGameScores = (gameId) => {
    const playerCopy = cloneDeep(allPlayers);
    playerCopy.sort((a, b) => {
      if (!a.score[gameId]) a.score[gameId] = 0;
      if (!b.score[gameId]) b.score[gameId] = 0;
      return b.score[gameId] - a.score[gameId];
    });
    return playerCopy;
  }

  const toggleExpandedRow = (gameId) => {
    if (expandedRow === gameId) setExpandedRow('');
    else setExpandedRow(gameId);
  }

  const camelCaseToTitleCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const handleScoreAdjustment = (player, gameId: string, value: string) => {
    const numVal = parseInt(value);
    if (isNaN(numVal)) return;
    // TODO: validate text input to enforce numbers
    const update = {
      ...player.score,
      [gameId]: numVal,
    };

    setScoreAdjustments({
      [player.id]: update,
    });
  }
  const saveScoreAdjustment = async (player, gameId: string) => {
    const newScore = scoreAdjustments[player.id][gameId];
    const scoreUpdates = {
      [`score.${gameId}`]: newScore,
    }

    await updatePlayerProperties(player.id, scoreUpdates);
    setScoreAdjustments({});
  }

  // TODO: display tournament conditions from Heat Things Up
  const tournamentConditions = tournamentData.value?.conditions || [];

  return (
    <div className='columns'>
      <div className="column">
        <div className='section'>
          <nav className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <p className="title is-4">
                  <strong>Scoreboard</strong>
                </p>
              </div>
            </div>

            <div className="level-right">
              <p className="level-item subtitle is-6"><strong>Join Code:</strong></p>
              <p className="level-item subtitle is-6">{tournamentData.value && tournamentData.value.joinCode}</p>
            </div>
          </nav>

          <div className="tabs is-fullwidth">
            <ul>
              <li className={`${activeTab === 'playerScores' ? 'is-active' : ''}`}>
                <a onClick={()=> setActiveTab('playerScores')}>By Player</a>
              </li>
              <li className={`${activeTab === 'gameScores' ? 'is-active' : ''}`}>
                <a onClick={()=> setActiveTab('gameScores')}>By Game</a>
              </li>
            </ul>
          </div>

          {activeTab === 'playerScores' &&
            <table className='table is-striped is-fullwidth'>
              <thead>
                <tr>
                  <th className='has-text-centered'>Place</th>
                  <th className='has-text-centered'>Player</th>
                  <th className='has-text-centered'>Total Score</th>
                </tr>
              </thead>
              <tbody>
                {allPlayers.map((p, i) => {
                  const name = p.userDetails?.displayName;
                  const place = i + 1;
                  const isArchmage = p.wizard?.isArchmage;
                  return (
                    <tr>
                      <th className='has-text-centered'>{place}</th>
                      {/* TODO: re-evaluate whether the Archmage indicator belongs here*/}
                      <td className='has-text-centered'>{`${name}${isArchmage ? ' (A)' : ''}`}</td>
                      <td className='has-text-centered'>{roundTo2Decimals(p.totalScore)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }

          {activeTab === 'gameScores' &&
            gameList.map(gameId => (
              <div className="card">
                <header className="card-header is-align-items-center">
                  <p className="card-header-title">
                    <button
                      className="card-header-icon button"
                      aria-label="more options"
                      onClick={() => toggleExpandedRow(gameId)}
                    >
                      <span className="icon">
                        <i className={`fas fa-angle-${expandedRow === gameId ? 'up' : 'down'}`} aria-hidden="true"/>
                      </span>
                    </button>
                    {camelCaseToTitleCase(gameId)}
                  </p>
                </header>

                {expandedRow == gameId &&
                  <table className='table is-striped is-fullwidth has-text-centered'>
                    <thead>
                      <tr>
                        <th>Place</th>
                        <th>Player</th>
                        <th>Score</th>
                        <th>Adjust</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortPlayersByGameScores(gameId).map((p, i) => {
                        const name = p.userDetails?.displayName;
                        const playerId = p.id || '';
                        const place = i + 1;
                        return (
                          <tr>
                            <th className='has-text-centered'>{place}</th>
                            <td className='has-text-centered'>{name}</td>
                            <td className='has-text-centered'>{roundTo2Decimals(p.score[gameId])}</td>
                            <td className='has-text-centered'>
                              <div className="field has-addons is-justify-content-center">
                                <div className="control">
                                  <input
                                    type='number'
                                    className='input is-small'
                                    disabled={!isHost}
                                    value={scoreAdjustments[playerId]?.[gameId] || ''}
                                    onChange={(e) => handleScoreAdjustment(p, gameId, e.target.value)}
                                  />
                                </div>
                                <div className="control">
                                  <button
                                    className='button is-small is-danger'
                                    onClick={() => saveScoreAdjustment(p, gameId)}
                                    disabled={!isHost}
                                  >
                                    <span className="icon">
                                      <i className="fa fa-pencil"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              </div>
            ))
          }

          {tournamentConditions.length > 0 &&
            <div>
              <label className='label'>Tournament Conditions</label>
              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th className='has-text-centered'>Game</th>
                    <th className='has-text-centered'>Effect</th>
                    <th className='has-text-centered'>Added by</th>
                  </tr>
                </thead>
                <tbody>
                  {tournamentConditions.map(x => {
                    const addedBy = allPlayers.find(p => p.id === x.addedBy);
                    return (
                      <tr>
                        <td className='has-text-centered'>{x.affectedGame}</td>
                        <td className='has-text-centered'>{x.name}</td>
                        <td className='has-text-centered'>{addedBy?.userDetails?.displayName}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>

      {/* spell history */}
      <div className='column'>
        <SpellActivityFeed type='allPlayers'/>
      </div>
    </div>
  );
}

export default ScoreBoard;
