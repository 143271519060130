import React from 'react';
import { WizardConditions } from '../../../constants';
import { roundTo2Decimals } from '../../../lib/utils';
import { playerHasAnyOfConditions, playerHasCondition } from './spell-helpers';
import { usePlayerData } from '../../data-providers/players-provider';
import { useNotifications } from '../../data-providers/notifications-provider';
import WizardNotifications from './wizard-notifications';

// spell displays
import ConditionDetails from './condition-details';
import AgniKaiResults from './spell-displays/agni-kai';
import FireballTrackers from './spell-displays/fireball-trackers';
import EmpowermentTimer from './spell-displays/empowerment-timer';
import ScorchedEarthTracker from './spell-displays/scorched-earth-tracker';
import SpellPenaltyTracker from './spell-displays/spell-penalty-tracker';

const WizardStatTracker = () => {
  const { currentPlayer } = usePlayerData();
  const currentWizard = currentPlayer?.wizard;
  const totalScore = currentPlayer?.totalScore;
  const conditionList = Object.keys(currentWizard?.conditions || []);
  const { value: notifications } = useNotifications();
  

  //TODO: show stats for controlled wizard

  return (
    <>
      <nav className="level is-mobile">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Level</p>
            <p className="title">{currentWizard?.level}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">HP</p>
            <p className="title">{currentWizard?.hitPoints}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">SP</p>
            <p className="title">{currentWizard?.spellPoints}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">GHDP</p>
            <p className="title">{roundTo2Decimals(totalScore)}</p>
          </div>
        </div>
      </nav>

      <article className="message is-small">
        <div className="message-body">
          <div className="columns is-mobile">
            <div className="column">
              <div>
                <strong>Class: </strong>
                {currentWizard?.class}
              </div>
              <div>
                <strong>Archmage: </strong>
                {currentWizard?.isArchmage ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="column">
              <div>
                <strong>Conditions: </strong>
                {conditionList.map(condition => (
                  <span className="tag">{condition}</span>
                ))}
              </div>
              <div>
                <strong>Spells cast: </strong>
                {currentWizard?.spellsCast}
              </div>
            </div>
          </div>

        </div>
      </article>

      <ConditionDetails show={conditionList.length > 0} />

      {currentPlayer && playerHasCondition(currentPlayer, WizardConditions.Dueling) &&
        <AgniKaiResults activePlayer={currentPlayer}/>
      }

      {currentPlayer && playerHasAnyOfConditions(currentPlayer, [WizardConditions.Combustible, WizardConditions.Firing, WizardConditions.TakingFire]) &&
        <FireballTrackers activePlayer={currentPlayer} />
      }

      {currentPlayer && playerHasCondition(currentPlayer, WizardConditions.Empowered) &&
        <EmpowermentTimer activePlayer={currentPlayer}/>
      }

      {currentPlayer && playerHasCondition(currentPlayer, WizardConditions.Scorching) &&
        <ScorchedEarthTracker activePlayer={currentPlayer} />
      }

      {currentPlayer &&
        <SpellPenaltyTracker activePlayer={currentPlayer} />
      }

      {notifications && notifications.length > 0 &&
        <WizardNotifications notifications={notifications} />
      }

    </>
  );
}

export default WizardStatTracker;
