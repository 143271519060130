import { db } from '../firebase';
import { 
  collection, 
  deleteDoc, 
  doc, 
  query,
  where,
} from "firebase/firestore"; 
import { getUserDetails } from './user-details';
import { User } from '@firebase/auth';


export async function getTournamentNotificationsQuery(user: User|null|undefined) {
  //TODO: handle undefined user?
  if (!user) return;

  try {
    //fetch user details to get active tournament
    const userDetails = await getUserDetails(user);
    const activeTournament = userDetails && userDetails['activeTournament'];
    return query(collection(db, 'notifications'), where('tournamentId', '==', activeTournament), where('players', 'array-contains', user.uid));
  } catch (error) {
    // TODO: do something
    alert(error);
  }

}

export async function getPlayerNotificationsQuery(user: User|null|undefined) {
  //TODO: handle undefined user?
  if (!user) return;

  try {
    //fetch user details to get active tournament
    const userDetails = await getUserDetails(user);
    const activeTournament = userDetails && userDetails['activeTournament'];
    return query(collection(db, 'notifications'), where('tournamentId', '==', activeTournament), where('uid', '==', user.uid));
  } catch (error) {
    // TODO: do something
    alert(error);
  }

}

export async function deleteNotification(notificationId: string) {
  await deleteDoc(doc(db, 'notifications', notificationId));
}

