import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import { Player, PlayerMap, Spell, SpellEvent, SpellOptions, SpellPlayerDetails, TournamentCondition, WizardConditionDetails } from '../types';
import { BASE_HP, CombustibleDurationInSeconds, MaxTithePoints, RandomizedConditionList, REINCARNATION_COST, SpellConditionEffects, SpellNames, WizardClasses, WizardConditions } from '../constants';
import { handleAddDoc } from './firestore-helpers';
import { ConvertHPtoSP } from '../components/games/wisest-wizard/spell-displays/equivalent-exchange';
import { playerHasCondition } from '../components/games/wisest-wizard/spell-helpers';
import { User } from '@firebase/auth';
import { getUserDetails } from './user-details';
import { where, collection, query, orderBy, runTransaction, doc} from '@firebase/firestore';
import { db } from '../firebase';
import { HeatThingsDoubleGamePoints, HeatThingsUpConditions } from '../components/games/wisest-wizard/spell-displays/heat-things-up';
import { getRandomInt } from './utils';
import { updateTournamentConditions } from './tournaments';

export const levelUpWizard = async (activePlayer: Player, allPlayers: Player[]) => {
  // TODO: replace player params with just their IDs
  if (!activePlayer.id) return;

  try {
    await runTransaction(db, async (transaction) => { 
      const allPlayerIds = allPlayers.map(x => x.id);
      const allOtherPlayerIds = allPlayerIds.filter(id => id !== activePlayer.id);
      const currentPlayerRef = doc(db, 'players', activePlayer.id);
      const currentPlayerDoc = await transaction.get(currentPlayerRef);
      const currentPlayer = currentPlayerDoc.data() as Player;

      let otherPlayers: Player[] = await Promise.all(allOtherPlayerIds.map(async (id) => {
        const playerRef = doc(db, 'players', id);
        const playerDoc = await transaction.get(playerRef);
        return playerDoc.data() as Player;
      }));

      const allPlayersLatest = [currentPlayer, ...otherPlayers];

      const wizard = currentPlayer.wizard;
      const currentWizardNewHitPoints = wizard.hitPoints + 1;

      // recalculate current archmage(s)
      const { currentPlayerIsArchmage, dethronedArchmages } = calculateArchmagesOnEvent(currentPlayer, 'level', allPlayersLatest);

      // Clear level-specific conditions
      let updatedConditions = { ...wizard.conditions };
      Object.keys(updatedConditions).forEach(c => {
        if (updatedConditions[c].clearOnLevel) delete updatedConditions[c];
      });

      let updates = {
        ...wizard,
        level: wizard.level + 1,
        hitPoints: currentWizardNewHitPoints,
        spellPoints: wizard.spellPoints + 1,
        isArchmage: currentPlayerIsArchmage,
      };

      // Handle Cleric Divine Shield feature
      if (wizard.class === 'Cleric') updatedConditions.Invulnerable = {
        conditionName: 'Invulnerable',
        clearOnEnemyCast: true,
        clearOnLevel: false,
        castBy: currentPlayer.id,
        affectedPlayers: [],
        durationInSeconds: 300, // TODO: not working yet, make the shield last 5 min before expiring
      };

      const newPlayerProperties = {
        wizard: updates,
        'wizard.conditions': updatedConditions,
      };
  
      await transaction.update(currentPlayerRef, newPlayerProperties);
  
      dethronedArchmages.forEach(player => {
        const playerRef = doc(db, 'players', player.id);
        const updates = { 'wizard.isArchmage': false };
        transaction.update(playerRef, updates);
      })

    })
  } catch (error) {
    // TODO: do something
    console.log(error);
  }
}

// When a trigger event happens, compares the current player's new HP to everyone else to determine if there are
// any changes in Archmage status
export function calculateArchmagesOnEvent(currentPlayer: Player, triggerEvent: 'level'|'reincarnation', allPlayers: Player[]) {
  // Calculate current archmage(s)
  const wizard = currentPlayer.wizard;
  const reincarnateHP = getReincarnatedHealth(wizard.level);
  const currentWizardNewHitPoints = triggerEvent === 'level' ? wizard.hitPoints + 1 : reincarnateHP;
  const highestHP = Math.max.apply(Math, allPlayers.map(x => { return x.wizard.hitPoints; }));

  const currentPlayerIsArchmage = currentWizardNewHitPoints >= highestHP;
  // Remove archmage status from other players if needed
  const dethronedArchmages: Player[] = allPlayers.filter(x => x.wizard.isArchmage && x.wizard.hitPoints < currentWizardNewHitPoints && x.id !== currentPlayer.id);

  return { currentPlayerIsArchmage, dethronedArchmages };
}

// Takes a map of current players and returns a list of new archmages and dethroned archmages based on the highest HP
export function determineNewArchmages(playerMap: PlayerMap) {
  // convert player map to an array
  const allPlayers: Player[] = Object.keys(playerMap).map(playerId => playerMap[playerId]);
  // Calculate current archmage(s)
  const highestHP = Math.max.apply(Math, allPlayers.map(x => { return x.wizard.hitPoints; }));
  // Remove archmage status from other players if needed
  const newArchmages: Player[] = allPlayers.filter(x => !x.wizard.isArchmage && x.wizard.hitPoints >= highestHP);
  const dethronedArchmages: Player[] = allPlayers.filter(x => x.wizard.isArchmage && x.wizard.hitPoints < highestHP);

  return { newArchmages, dethronedArchmages };
}

export function getReincarnatedHealth(wizardLevel: number = 0) {
  return Math.floor(BASE_HP + wizardLevel / 2); // 5 HP + level / 2 rounded down
}

export async function reincarnateWizard(activePlayer: Player, newClass: string, allPlayers: Player[]) {
  try {
    await runTransaction(db, async (transaction) => {
      // get fresh player data
      const allPlayerIds = allPlayers.map(x => x.id);
      const allOtherPlayerIds = allPlayerIds.filter(id => id !== activePlayer.id);
      const currentPlayerRef = doc(db, 'players', activePlayer.id);
      const currentPlayerDoc = await transaction.get(currentPlayerRef);
      const currentPlayer = currentPlayerDoc.data() as Player;

      let otherPlayers: Player[] = await Promise.all(allOtherPlayerIds.map(async (id) => {
        const playerRef = doc(db, 'players', id);
        const playerDoc = await transaction.get(playerRef);
        return playerDoc.data() as Player;
      }));

      const allPlayersLatest = [currentPlayer, ...otherPlayers];

      const score = currentPlayer.score.WisestWizard || 0;
      const newScore = score - REINCARNATION_COST;
      const updatedReincarnations = [...currentPlayer.wizard.reincarnations, newClass] || [];
      const currentLevel = currentPlayer.wizard.level || 0;
      const newHP = getReincarnatedHealth(currentLevel);

      // recalculate current archmage(s)
      const { currentPlayerIsArchmage, dethronedArchmages } = calculateArchmagesOnEvent(currentPlayer, 'reincarnation', allPlayersLatest);

      const updates = {
        'wizard.class': newClass, // set new class
        'wizard.reincarnations': updatedReincarnations, // add new class to list of reincarnations
        'wizard.hitPoints': newHP,
        'wizard.isArchmage': currentPlayerIsArchmage,
        'score.WisestWizard': newScore,
      };

      // TODO: log reincarnation
      // update current player
      await transaction.update(currentPlayerRef, updates);

      // update other wizards who are no longer Archmages
      dethronedArchmages.forEach(player => {
        const playerRef = doc(db, 'players', player.id);
        const updates = { 'wizard.isArchmage': false };
        transaction.update(playerRef, updates);
      })
    })
  } catch (e) {
    console.log(e);
    // TODO: do something
  }
}

export function getSpellEffects(spell: Spell, caster: Player, targets: Player[], allPlayers: Player[], spellOptions: any) {
  let playerMap: { [playerId: string]: Player } = {};
  let playersWithUpdates = new Set<string>([caster.id]);
  const targetIds = targets.map(t => t.id);
  let messages: {[playerId: string]: string[] } = {};

  // clone original player data
  allPlayers.forEach(player => {
    playerMap[player.id] = cloneDeep(player);
  });

  const hasConditionUpdateExceptions = (player: Player, condition: WizardConditionDetails) => {
    // don't remove Invisibility from bards casting Magical Attunement
    if (spell.name === SpellNames.MagicalAttunement && condition.conditionName === WizardConditions.Invisible && caster.id === player.id) {
      return true;
    }

    return false;
  }

  // determine if any player conditions should expire when the caster casts a spell
  Object.keys(playerMap).forEach(playerId => {
    const player = playerMap[playerId];
    const wizardConditions = player.wizard.conditions;

    Object.keys(wizardConditions).forEach(condition => {
      const exceptions = hasConditionUpdateExceptions(player, wizardConditions[condition]);
      const casterSpellClearsCondition = wizardConditions[condition].clearOnCasterSpellCast && wizardConditions[condition].castBy === caster.id; // condition inflicted by another wizard is cleared when they cast a spell
      const enemySpellClearsCondition = wizardConditions[condition].clearOnEnemyCast && caster.id !== playerId && targetIds.includes(playerId);
      const selfCastClearsCondition = wizardConditions[condition].clearOnSelfCast && playerId === caster.id;
      const shouldClear = casterSpellClearsCondition || enemySpellClearsCondition || selfCastClearsCondition;

      if (shouldClear && !exceptions) {
        // updates will be made after spell effects are determined so that conditions such
        // as Invulnerable are not removed before being accounted for
        playerMap[playerId].wizard.conditions[condition].markedForRemoval = true;
        playersWithUpdates.add(playerId);

        // TODO: trigger a message to caster or target when a spell clears a condition
        if (playerId === caster.id) {
          let msg = `Your spell ${spell.name} removed the ${condition} condition from you.`;
          if (messages[playerId]) messages[playerId].push(msg);
          else messages[playerId] = [msg];
        } 
        // if (targetIds.includes(playerId)) {
          let playerMsg = `${caster.userDetails?.displayName}'s spell ${spell.name} removed the ${condition} condition from you.`;
          const displayName = selfCastClearsCondition ? 'you' : playerMap[playerId].userDetails?.displayName;
          let casterMsg = `Your spell ${spell.name} removed the ${condition} condition from ${displayName}`;
          // messages[playerId].push(playerMsg);
          // messages[caster.id].push(casterMsg);

          if (messages[playerId]) messages[playerId].push(playerMsg);
          else messages[playerId] = [playerMsg];

          if (messages[caster.id]) messages[caster.id].push(casterMsg);
          else messages[caster.id] = [casterMsg];
        // }
      }
    });
  });

  //apply caster updates
  playerMap[caster.id] = getCasterSpellEffects(spell, caster.id, targetIds, playerMap);

  //get target effects
  const { allTargetUpdates } = getTargetSpellEffects(spell, caster.id, targetIds, playerMap);
  // add target updates to playerMap
  allTargetUpdates.forEach(target => {
    playersWithUpdates.add(target.playerId);
    playerMap[target.playerId] = target.updates;
  });

  // get unique spell effects
  const playerDetails = {
    casterId: caster.id,
    targetIds: targetIds,
    playerMap,
    playersWithUpdates,
  };

  const { playerMap: uniquePlayerUpdates, playersWithUpdates: playerUpdateList } = getUniqueSpellEffects(spell, spellOptions, playerDetails);

  playerMap = uniquePlayerUpdates;
  playersWithUpdates = playerUpdateList;

  // remove the conditions that were marked for removal earlier
  playersWithUpdates.forEach(async playerId => {
    const wizardConditions = playerMap[playerId].wizard.conditions;
    Object.keys(wizardConditions).forEach(condition => {
      if (wizardConditions[condition].markedForRemoval) delete wizardConditions[condition];
    });
  });

  // recalculate archmage statuses
  const { newArchmages, dethronedArchmages } = determineNewArchmages(playerMap);
  newArchmages.forEach(p => {
    playersWithUpdates.add(p.id);
    playerMap[p.id].wizard.isArchmage = true;
  });
  dethronedArchmages.forEach(p => {
    playersWithUpdates.add(p.id);
    playerMap[p.id].wizard.isArchmage = false;
  });

  return { playerMap, playersWithUpdates, messages };
}

export const getCasterSpellEffects = (spell: Spell, casterId: string, targetIds: string[], playerMap) => {
  const casterUpdates: Player = playerMap[casterId];

  const targetScale = Math.max(targetIds.length, 1); //multiple effects by 1 if no targets specified
  casterUpdates.wizard.hitPoints += (spell.casterHitPointEffect * targetScale);
  // don't let HP go negative
  if (casterUpdates.wizard.hitPoints < 0) casterUpdates.wizard.hitPoints = 0;
  casterUpdates.wizard.spellPoints += (spell.casterSpellPointEffect * targetScale);
  if (!playerHasCondition(casterUpdates, WizardConditions.Inspired) && spell.type === 'Standard Spell') {
    // subtract cost of standard spell unless wizard is Inspired
    casterUpdates.wizard.spellPoints -= spell.spellPointCost;
  }
  // don't let SP go negative
  if (casterUpdates.wizard.spellPoints < 0) casterUpdates.wizard.spellPoints = 0;
  casterUpdates.wizard.spellsCast += 1;
  casterUpdates.score.WisestWizard += spell.casterScoreEffect;
  casterUpdates.wizard.conditions = getCasterConditionUpdates(spell, casterUpdates, targetIds);

  // healing can't exceed max health
  const maxHP = casterUpdates.wizard.level + BASE_HP;
  if (casterUpdates.wizard.hitPoints > maxHP) casterUpdates.wizard.hitPoints = maxHP;

  return casterUpdates;
}

// adds dynamic details to the conditions defined by a spell
export const getCasterConditionUpdates = (spell: Spell, caster: Player, targetIds: string[]) => {
  let casterConditions = { ...caster.wizard.conditions };
  const newConditionName = spell.casterConditionEffect?.conditionName || '';
  let newCasterCondition = spell.casterConditionEffect as WizardConditionDetails;

  if (newCasterCondition) {
    newCasterCondition.castBy = caster.id;

    // add any new updates that are created by this spell
    // track which players are affected by certain conditions
    if (newCasterCondition?.conditionName === WizardConditions.Controlling) {
      let affectedPlayers = targetIds;
      if (casterConditions[WizardConditions.Controlling]) {
        affectedPlayers.push(...casterConditions[WizardConditions.Controlling].affectedPlayers);
      }
      newCasterCondition.affectedPlayers = affectedPlayers;
    }
    if (newCasterCondition?.conditionName === WizardConditions.Dueling) {
      newCasterCondition.affectedPlayers = [caster.id, ...targetIds];
    }
    if (newCasterCondition.conditionName === WizardConditions.Firing) {
      newCasterCondition.affectedPlayers = targetIds;
    }

    // TODO: test all time-bound conditions: Force of Nature,
    // if condition is time-bound, calculate expiration time
    if (newCasterCondition.durationInSeconds) {
      newCasterCondition.expirationTime = dayjs().add(newCasterCondition.durationInSeconds, 'second').format();
    }

    casterConditions[newConditionName] = newCasterCondition;
  }

  return casterConditions;
}

export function getTargetSpellEffects(spell: Spell, casterId: string, targetIds: string[], playerMap) {
  let allTargetUpdates: {playerId: string, updates: Player}[] = [];

  targetIds.forEach(id => {
    if (!id) return;
    let targetUpdates = playerMap[id];

    //apply target updates - unless invulnerable
    if (!playerHasCondition(targetUpdates, WizardConditions.Invulnerable)) {
      targetUpdates.wizard.hitPoints += spell.targetHitPointEffect;
      // don't let HP go negative
      if (targetUpdates.wizard.hitPoints < 0) targetUpdates.wizard.hitPoints = 0;
      targetUpdates.wizard.spellPoints += spell.targetSpellPointEffect;
      targetUpdates.score.WisestWizard += spell.targetScoreEffect;
      targetUpdates.wizard.conditions = getTargetConditionUpdates(spell, casterId, targetUpdates);
    }

    // healing can't exceed max health
    const maxHP = targetUpdates.wizard.level + BASE_HP;
    if (targetUpdates.wizard.hitPoints > maxHP) targetUpdates.wizard.hitPoints = maxHP;

    targetUpdates.wizard.timesTargeted += 1;

    allTargetUpdates.push({playerId: targetUpdates.id, updates: targetUpdates});
  });

  return { allTargetUpdates };
}

// adds dynamic details to the conditions defined by a spell
export const getTargetConditionUpdates = (spell: Spell, casterId: string, target: Player) => {
  let targetConditions = { ...target.wizard.conditions };
  const newConditionName = spell.targetConditionEffect?.conditionName || '';
  let newTargetCondition = spell.targetConditionEffect as WizardConditionDetails;

  if (newTargetCondition) {
    // add any new updates that are created by this spell
    newTargetCondition.castBy = casterId;
    targetConditions[newConditionName] = newTargetCondition;

    if (newTargetCondition.conditionName === WizardConditions.Dueling) {
      newTargetCondition.affectedPlayers = [casterId, target.id];
    }

    // if condition is time-bound, calculate expiration time
    if (newTargetCondition.durationInSeconds) {
      newTargetCondition.expirationTime = dayjs().add(newTargetCondition.durationInSeconds, 'second').format();
    }
  }

  // Trigger Fight Fire With Fire for Pyromancers unless it is a spell penalty
  if (target.wizard.class === WizardClasses.Pyromancer && spell.type !== 'Penalty') {
    let affectedPlayers = [casterId];
    if (targetConditions[WizardConditions.Combustible]) {
      // if target already has Combustible, add the new casterId and reset the clock
      affectedPlayers = [casterId, ...targetConditions[WizardConditions.Combustible].affectedPlayers];
    }

    const combustible: WizardConditionDetails = {
      conditionName: WizardConditions.Combustible,
      clearOnLevel: false,
      expirationTime: dayjs().add(CombustibleDurationInSeconds, 'second').format(),
      castBy: casterId,
      affectedPlayers: affectedPlayers,
    }
    targetConditions[WizardConditions.Combustible] = combustible;
  }

  return targetConditions;
}

export const getPlayerSpellNotification = () => {

}

export const getMessageForSpellOptions = (spellOptions: SpellOptions) => {
  if (!spellOptions) return '';

  //tithe
  if (spellOptions.gameToTithe) return `(Game: ${spellOptions.gameToTithe})`;
  // equivalent exchange
  if (spellOptions.exchange && spellOptions.exchange === 'HP to SP') return `(Converted HP to SP)`;
  if (spellOptions.exchange && spellOptions.exchange === 'SP to HP') return `(Converted SP to HP)`;
  // heat things up
  if (spellOptions.heatThingsUp && spellOptions.heatThingsUp.type === HeatThingsDoubleGamePoints) {
    return `(${spellOptions.heatThingsUp.type} - ${spellOptions.heatThingsUp.game})`;
  }
  if (spellOptions.heatThingsUp) return `(${spellOptions.heatThingsUp.type})`;

  return '';
}

export const getEmpoweredSpellMessage = (empowered: 'doubleTargets'|'doubleEffects'|undefined) => {
  if (!empowered) return '';

  if (empowered === 'doubleTargets') return '(Empowered: Extra targets)';
  if (empowered === 'doubleEffects') return '(Empowered: Doubled effects)';

  return '';
}

// add spell to spell-history collection
export const trackSpellCast = async (spell: Spell, caster: Player, targets: Player[], spellOptions) => {
  try {
    const SpellEvent: SpellEvent = {
      tournamentId: caster.tournamentId,
      // TODO: determine if entire spell data is needed or just key fields
      spell,
      // spellName: spell.name,
      // spellClass: spell.class,
      // spellType: spell.type,
      spellOptionsMessage: getMessageForSpellOptions(spellOptions),
      empoweredMessage: getEmpoweredSpellMessage(spell.empowered),
      // spellOptions: spellOptions,
      casterId: caster.id,
      targetIds: targets.map(x => x.id),
      timestamp: dayjs().format(),
    };

    await handleAddDoc('spell-history', SpellEvent);
  } catch (error) {
    // TODO: do something
    alert(error);
  }
}

export const getUniqueSpellEffects = (spell: Spell, spellOptions: SpellOptions, playerDetails: SpellPlayerDetails) => {
  const { casterId, targetIds, playerMap, playersWithUpdates } = playerDetails;
  const caster = playerMap[casterId];

  //tithe - for chosen game, subtract 10% of points from each player and add to the caster's Wisest Wizard score.
  if (spell.name === SpellNames.Tithe && spellOptions.gameToTithe) {
    const titheGame = spellOptions.gameToTithe;
    let casterPoints = 0;

    Object.keys(playerMap).forEach(playerId => {
      if (playerId === casterId) return;

      const player = playerMap[playerId];
      const scoreTithe = player.score[titheGame] / 10;
      player.score[titheGame] -= scoreTithe;
      casterPoints += scoreTithe;
      playersWithUpdates.add(playerId);
    });

    caster.score['WisestWizard'] += Math.min(MaxTithePoints, casterPoints);

    // mark game as already having a tithe collected
    const tournamentCondition: TournamentCondition = {
      name: spell.name,
      affectedGame: spellOptions.gameToTithe,
      addedBy: casterId,
    };

    updateTournamentConditions(caster.tournamentId, tournamentCondition);
  }

  if (spell.name === SpellNames.EquivalentExchange) {
    if (spellOptions.exchange && spellOptions.exchange === ConvertHPtoSP) {
      caster.wizard.hitPoints -= 1;
      caster.wizard.spellPoints += 1;
    } else {
      caster.wizard.hitPoints += 1;
      caster.wizard.spellPoints -= 1;
    }
    playersWithUpdates.add(casterId);
  }

  if (spell.name === SpellNames.HeatThingsUp && spellOptions.heatThingsUp) {

    if (spellOptions.heatThingsUp.type === HeatThingsUpConditions) {
      // wild magic conditions
      Object.keys(playerMap).forEach(playerId => {
        const player = playerMap[playerId];

        // get list of valid conditions that the player does not already have
        const existingConditions = player.wizard.conditions;
        const filteredRandomConditionList = RandomizedConditionList.filter(c => !Object.keys(existingConditions).includes(c));

        // generate a random index
        const randomIndex = getRandomInt(filteredRandomConditionList.length);

        // add condition
        const randomConditionName = filteredRandomConditionList[randomIndex];

        // update if a new condition can be added
        if (randomConditionName) {
          let newCondition: WizardConditionDetails = {
            ...SpellConditionEffects[randomConditionName],
            castBy: caster.id,
            affectedPlayers: [],
          }

          if (newCondition.durationInSeconds) {
            newCondition.expirationTime = dayjs().add(newCondition.durationInSeconds, 'second').format();
          }

          playersWithUpdates.add(playerId);
          player.wizard.conditions[randomConditionName] = newCondition;
        }
      })
    } else {
      // Double points and Risk / Reward set a tournament condition
      const tournamentCondition: TournamentCondition = {
        name: spellOptions.heatThingsUp.type,
        affectedGame: spellOptions.heatThingsUp.game || '',
        addedBy: casterId,
      };

      updateTournamentConditions(caster.tournamentId, tournamentCondition);
    }
  }

  return { playerMap, playersWithUpdates };
}

export const submitAgniKaiResults = async (winner: Player, loser: Player) => {
  try {
    await runTransaction(db, async (transaction) => {
      const winnerRef = doc(db, 'players', winner.id);
      const winnerDoc = await transaction.get(winnerRef);
      const winnerData = winnerDoc.data() as Player;
      const loserRef = doc(db, 'players', loser.id);
      const loserDoc = await transaction.get(loserRef);
      const loserData = loserDoc.data() as Player;

      // TODO: may not need the deep clone here now that this runs in a transaction
      const winnerUpdates: Player = cloneDeep(winnerData);
      const loserUpdates: Player = cloneDeep(loserData);
    
      winnerUpdates.score.WisestWizard += 10;
      if (playerHasCondition(winner, WizardConditions.Dueling)) delete winnerUpdates.wizard.conditions[WizardConditions.Dueling];
    
      loserUpdates.score.WisestWizard -= 10;
      loserUpdates.wizard.hitPoints -= 1;
      if (playerHasCondition(loser, WizardConditions.Dueling)) delete loserUpdates.wizard.conditions[WizardConditions.Dueling];
    
      await transaction.update(winnerRef, {
        'score.WisestWizard': winnerUpdates.score.WisestWizard,
        'wizard.conditions': winnerUpdates.wizard.conditions,
      });
    
      await transaction.update(loserRef, {
        'score.WisestWizard': loserUpdates.score.WisestWizard,
        'wizard.conditions': loserUpdates.wizard.conditions,
        'wizard.hitPoints': loserUpdates.wizard.hitPoints,
      });
    })
  } catch (error) {
    console.log(error);
    //TODO: do something
  }
}

export const getSpellHistoryQuery = async (user: User) => {
  if (!user) return null;

  try {
    //fetch user details to get active tournament
    const userDetails = await getUserDetails(user);
    const activeTournament = userDetails && userDetails['activeTournament'];
    return query(collection(db, 'spell-history'), where('tournamentId', '==', activeTournament), orderBy('timestamp', 'desc'));
  } catch (error) {
    // TODO: do something
    alert(error);
  }

}
