import React from 'react';
import { GameNames } from '../../../../constants';
import { usePlayerData } from '../../../data-providers/players-provider';
import { useTournamentData } from '../../../data-providers/tournament-provider';


interface TitheProps {
  handleSelect: (options: { gameToTithe: string }) => void;
}

const Tithe = ({ handleSelect }: TitheProps) => {
  const { currentPlayer } = usePlayerData();
  const tournamentData = useTournamentData();
  const gameList: string[] = currentPlayer && Object.keys(currentPlayer.score) || [];
  const tournamentConditions = tournamentData?.value?.conditions || [];
  const hasGameBeenTithed = (gameId) => tournamentConditions.some(x => {
    return x.affectedGame === gameId && x.name === 'Tithe';
  })

  return (
    <div >
      <label className='label'>Select a game to collect points from</label>
      <div className='select is-medium is-fullwidth'>
        <select 
          onChange={(e) => handleSelect({gameToTithe: e.target.value})}
        >
          <option value=''>select</option>
          {gameList.map(gameId => {
            return (
              <option value={gameId} disabled={hasGameBeenTithed(gameId) || gameId === 'HorseRace'}>
                {GameNames[gameId]}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  );
}

export default Tithe;
