
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.125,"time":67500,"words":225}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "flip-cup-survivor"
    }}>{`Flip Cup Survivor`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Eliminate all players on the opposing team. `}</p>
    <h4 {...{
      "id": "setup"
    }}>{`Setup`}</h4>
    <p>{`Two teams line up on opposite sides of a long table.
Each player fills his or her cup up to an agreed upon level with a beverage of choice.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Players will be randomly divided into even teams and form a line on opposite sides of the table.
Opposing team members face off one at a time, starting with a cup turned upside down on the edge of the table and using their fingers to try to flip the cup right side up.
Once a player successfully flips a cup, the next player on the team may go.  `}</p>
    <p>{`The first team to have all players flip their cups successfully wins the round and gets to choose a member of the opposing team to eliminate.
The remaining players then refill their cups and play another round.
The cups of any eliminated players must also be refilled and consumed / flipped by someone else on their team.
For large groups, the teams may agree to eliminate more than one player per round. `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <ul>
      <li parentName="ul">{`All players on the winning team earn 100 points.  `}</li>
      <li parentName="ul">{`Each team earns 15 points for each round they win.  `}</li>
      <li parentName="ul">{`Each team nominates an MVP for the game. That player earns 25 bonus points.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "flip-cup-survivor",
    level: 3,
    title: "Flip Cup Survivor",
    children: [
        
      ]
  }
]

export const frontMatter = {}

