
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.02,"time":61200,"words":204}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "mournival-4-way-beer-pong"
    }}>{`Mournival (4-way Beer Pong)`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Be the last player standing in a modified game of 4-way beer pong.
The winner becomes the new Groundhog Day champion.  `}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`The game is played like beer pong but with a few changes.
Using one or more large tables, set up a rack of cups for each player so that each player is directly across from another player.  `}</p>
    <p>{`Each player will have a different number of starting cups depending on their GHD score.  `}</p>
    <p><strong parentName="p">{`Option 1:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`1st place:`}</strong>{` 15 cups`}</li>
      <li parentName="ul"><strong parentName="li">{`2nd place:`}</strong>{` 12 cups`}</li>
      <li parentName="ul"><strong parentName="li">{`3rd place:`}</strong>{` 9 cups`}</li>
      <li parentName="ul"><strong parentName="li">{`4th place:`}</strong>{` 6 cups  `}</li>
    </ul>
    <p><strong parentName="p">{`Option 2: (Bridge Club Edition)`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`1st:`}</strong>{` 13 cups arranged like a Spade  `}</li>
      <li parentName="ul"><strong parentName="li">{`2nd:`}</strong>{` 10 cups arranged like a Heart  `}</li>
      <li parentName="ul"><strong parentName="li">{`3rd:`}</strong>{` 9 cups arranged like a Diamond  `}</li>
      <li parentName="ul"><strong parentName="li">{`4th:`}</strong>{` 5 cups arranged like a Club  `}</li>
    </ul>
    <p>{`Starting with the person in 4th place (then 3rd place, then 2nd place, then 1st place), players will take turns shooting at any of the other players’ cups.  `}</p>
    <p>{`The player in 1st place (most GHD points) gets to shoot twice each turn.  `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`No additional points are awarded.
The order that players are eliminated determines their overall place for Groundhog Day.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "mournival-4-way-beer-pong",
    level: 3,
    title: "Mournival (4-way Beer Pong)",
    children: [
        
      ]
  }
]

export const frontMatter = {}

