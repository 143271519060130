import React, { createContext, useState, useEffect, useContext } from 'react';
import { Player } from '../../types';
import { useAuth } from '../../lib/auth';
import { getTournamentPlayersQuery } from '../../lib/players';
import { onSnapshot } from '@firebase/firestore';
import { getTotalScore } from '../../lib/utils';

export interface PlayerContextState {
  status: 'loading'|'error'|'success'; 
  allPlayers: Player[];
  currentPlayer: Player|null;
  setRefresh: (refresh: boolean) => void;
}

const Context = createContext<PlayerContextState|null>(null);

export const usePlayerData = (): PlayerContextState => {
  const PlayercontextState = useContext(Context);
  if (PlayercontextState === null) {
    throw new Error('Player provider error');
  }
  return PlayercontextState;
}

const PlayerDataProvider = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [state, setState] = useState<PlayerContextState>({
    status: 'loading', allPlayers: [], currentPlayer: null, setRefresh: setRefresh
  });
  const currentUser: any = useAuth();
  const uid = currentUser && currentUser.uid;

  // if authenticated, fetch player data
  useEffect(() => {
    // TODO: only fetch if user has an active tournament
    if (!currentUser) return;
    
    let unsubscribe: any;
    let currentPlayer: Player|null;
    getTournamentPlayersQuery(currentUser)
      .then((query) => {
        if (query) {
          unsubscribe = onSnapshot(query, (querySnapshot) => {
            const players: Player[] = [];
            querySnapshot.forEach((doc) => {
              const player = {id: doc.id, ...doc.data()} as Player;

              // compute total score and add to Player
              const totalScore = getTotalScore(player.score);
              player.totalScore = totalScore;

              // Only show wizard conditions that have not expired
              // Note: This will not automatically update the client when the time expires. The conditions
              // stored in the DB will update on the next change to the player
              // const currentWizardConditions = (player.wizard.conditions);

              players.push(player);
              if (player.userDetails && player.userDetails.uid === uid) currentPlayer = player;
            });

            setState({
              ...state,
              status: 'success',
              allPlayers: players,
              currentPlayer,
            });
            setRefresh(false);
          });
        }
      })

    if (unsubscribe) return unsubscribe();
  }, [currentUser, refresh]);

  return (
    <Context.Provider value={state}>
      {props.children}
    </Context.Provider>
  )
}

export default PlayerDataProvider;

// Allows easily mocking data for unit tests without mocking API calls
export const TestPlayerDataProvider = (props) => (
  <Context.Provider value={props.state}>
    {props.children}
  </Context.Provider>
);