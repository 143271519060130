
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.35,"time":81000,"words":270}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "shot-roulette"
    }}>{`Shot Roulette`}</h3>
    <h4 {...{
      "id": "materials"
    }}>{`Materials`}</h4>
    <p>{`A deck of cards and two six-sided dice.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Take one of each type of card (Two through Queen) from a deck of cards and deal them face up on a table.
Place a shot glass (filled with some type of alcohol or other liquid) on top of each card.
Ideally each shot glass should be filled with something different and most people should not know what is in every glass as long as any allergies or other restrictions are considered.`}</p>
    <p>{`Players gather round the table and take turns rolling the two dice and drinking the shot on whichever card matches the number they rolled (for face cards, Jack = 11, Queen = 12).`}</p>
    <p>{`If there are more players than cards, deal a few extra cards with values between 2 and 12.
If a player rolls a number that matches one of these extra cards, the player can choose which shot glass to drink.`}</p>
    <p>{`If a player rolls a number and the shot on the card matching that number has already been taken, the player can pass the dice or roll again.`}</p>
    <p>{`Play continues until all of the shots have been consumed.`}</p>
    <h4 {...{
      "id": "non-alcoholic-option"
    }}>{`Non-Alcoholic Option`}</h4>
    <p>{`If there are any players who choose not to drink alcohol, set up a separate group of cards and shot glasses filled with non-alcoholic liquids.
When they roll, they will drink from this group of shot glasses.
Adjust the number of cards / shots in each group to match the number of players drinking / not drinking alcohol.`}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`No points, this game is just for fun.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "shot-roulette",
    level: 3,
    title: "Shot Roulette",
    children: [
        
      ]
  }
]

export const frontMatter = {}

