import React, { useState } from 'react';
import { SpellNames, WizardClasses, WizardConditions } from '../../../../constants';
import { playerHasCondition } from '../spell-helpers';
import { Player, Spell } from '../../../../types';
import Countdown from '../../../countdown';
import { castSpell, deletePlayerProperty } from '../../../../lib/players';
import { usePlayerData } from '../../../data-providers/players-provider';
import SpellTargeter from '../spell-targeter';

interface ScorchedEarthProps {
  activePlayer: Player;
}

const ScorchedEarthPenalty: Spell = {
  name: SpellNames.ScorchedEarthPenalty,
  class: WizardClasses.Pyromancer,
  description: "The target loses 1 HP for walking through Scorched Earth. If they were Invisible, their Invisibility ends.",
  type: 'Standard Spell',
  spellPointCost: 0,
  targets: 20,
  selfTargetOnly: false,
  casterHitPointEffect: 0,
  casterSpellPointEffect: 0,
  casterScoreEffect: 0,
  casterLevelEffect: 0,
  casterConditionEffect: null,
  targetHitPointEffect: -1,
  targetSpellPointEffect: 0,
  targetScoreEffect: 0,
  targetLevelEffect: 0,
  targetConditionEffect: null,
}

const ScorchedEarth = ({ activePlayer }: ScorchedEarthProps) => {
  const { allPlayers } = usePlayerData();
  const [showModal, setShowModal] = useState<boolean>();
  const [selectedTargets, setSelectedTargets] = useState<Array<Player>>([]);
  const activeWizard = activePlayer.wizard;
  const activeWizardConditions = activeWizard.conditions;

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const handleScorchingOver = async () => {
    if (!activePlayer) return;
    await deletePlayerProperty(activePlayer, `wizard.conditions.${WizardConditions.Scorching}`);
  }

  const submitScorchedEarthPenalty = async () => {
    await castSpell(ScorchedEarthPenalty, activePlayer, selectedTargets, allPlayers, null);
    setShowModal(!showModal);
    setSelectedTargets([]);
  }

  return (
    <div className=''>

      {activePlayer && playerHasCondition(activePlayer, WizardConditions.Scorching) &&
        
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              <strong>{SpellNames.ScorchedEarth}</strong> is in effect for&nbsp;
              <strong>
                <Countdown 
                  expirationTime={activeWizardConditions[WizardConditions.Scorching].expirationTime}
                  onTimerFinish={handleScorchingOver}
                />
              </strong>
            </p>
            <button className='button is-small is-warning' onClick={toggleModal}>
              More
            </button>
          </div>
        </article>
      }

      <div className={`modal ${showModal ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Track Scorched Earth Penalties</p>
            <button className="delete" aria-label="close" onClick={toggleModal}/>
          </header>
          <section className="modal-card-body">
            <label className='label'>Select which wizards walked through Scorched Earth</label>
              
            <SpellTargeter 
              show={true}
              spell={ScorchedEarthPenalty}
              handleSelect={setSelectedTargets}
              playerTargets={allPlayers}
              selectedTargets={selectedTargets}
              activePlayer={activePlayer}
            />

          </section>
          <footer className="modal-card-foot">
            <button 
              className="button is-primary" 
              onClick={submitScorchedEarthPenalty}
              disabled={selectedTargets.length < 1}
            >
              Confirm
            </button>
            <button className="button" onClick={toggleModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>

    </div>
  );
}

export default ScorchedEarth;
