
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.855,"time":51300,"words":171}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "overall-rules"
    }}>{`Overall Rules`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Winning:`}</strong>{` At the end of the night, the top 3 scorers and 1 wildcard (the winner of another game) will face off in a 4-way version of beer pong (aka “Mournival”). The Groundhog Day podium will be decided by the outcome of Mournival.`}</li>
      <li parentName="ul"><strong parentName="li">{`Non-alcoholic options:`}</strong>{` If at any point you feel like you’ve reached your drinking limit (or you staying sober during the night) each game has a non-alcoholic option. Basically if a can is needed, use a can of some sort of carbonated beverage (sparkling water, soda, juice, etc). If no can is needed, use water cups.`}</li>
      <li parentName="ul">{`If for some reason you can’t participate at all in a game, you will get points equal to the lowest score in that game.`}</li>
      <li parentName="ul"><strong parentName="li">{`Decorum:`}</strong>{` We’re all here to have fun. It takes a lot of work to organize Groundhog Day and it’s hard to anticipate every possible outcome of various games so be kind, don’t argue with the hosts, and buckle up for some chaos.  `}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "overall-rules",
    level: 3,
    title: "Overall Rules",
    children: [
        
      ]
  }
]

export const frontMatter = {}

