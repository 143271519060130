import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { logInWithEmailAndPassword, useAuth, resetPassword } from '../lib/auth';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showResetError, setShowResetError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUser = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = async () => {
    try { 
      setLoading(true);
      await logInWithEmailAndPassword(email, password);
    } catch (error) {
      alert(`error: ${error}`);
    }
    setLoading(false);
  }

  const handleForgotPassword = async () => {
    setShowResetModal(true);
  }

  const sendResetEmail = async () => {
    try { 
      setLoading(true);
      const response = await resetPassword(email);
      console.log('response :>> ', response);
      if (response === 'success') {
        setShowResetSuccess(true);
      } else {
        setShowResetError(true);
      }
    } catch (error) {
      alert(`error: ${error}`);
    }
    setLoading(false);
    setShowResetModal(false);
  }

  // redirect to main page or previous page after auth
  if (currentUser) history.replace(from);

  return (
    <div className='section'>
      <h1 className="title">Log in</h1>
      <div className="field">
        <label className="label">Email</label>
        <div className="control has-icons-left has-icons-right">
          <input 
            className="input" 
            type="email" 
            placeholder="Email input" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Password</label>
        <p className="control has-icons-left">
          <input 
            className="input" 
            type="password"
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-lock"></i>
          </span>
        </p>
      </div>

      <div className="field">
        <div className="control is-flex">
          <button className="button is-primary" onClick={handleLogin} disabled={(!password || !email)}>
            Log in
          </button>
        </div>
      </div>

      <div className="field">
        <div className="control is-flex">
          <button className="button is-ghost pl-0" onClick={handleForgotPassword}>
            Forgot password?
          </button>
        </div>
      </div>

      <div className="field">
        <div className="control is-flex">
          <a className="button is-ghost pl-0" href='/signup'>
            Don't have an account? Sign up
          </a>
        </div>
      </div>

      <div className={`modal ${showResetModal ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reset password</p>
            <button className="delete" aria-label="close" onClick={() => setShowResetModal(false)}/>
          </header>
          <section className="modal-card-body">
            <input 
              className="input"
              type="email" 
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={sendResetEmail}>
              Send password reset email
            </button>
            <button className="button" onClick={() => setShowResetModal(false)}>
              Cancel
            </button>
          </footer>
        </div>
      </div>

      {showResetSuccess &&
        <article className="message is-success">
          <div className="message-header">
            <p>Reset email sent!</p>
            <button className="delete" aria-label="delete" onClick={() => setShowResetSuccess(false)}/>
          </div>
          <div className="message-body">
            A password reset link has been sent to the email address you provided.
          </div>
        </article>
      }

      {showResetError &&
        <article className="message is-danger">
          <div className="message-header">
            <p>User not found</p>
            <button className="delete" aria-label="delete" onClick={() => setShowResetError(false)}/>
          </div>
          <div className="message-body">
            We don't recognize that email address. Please make sure you have entered the correct address.
          </div>
        </article>
      }
    </div>
  );
}

export default Login;
