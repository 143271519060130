import { Spell, SpellCondition } from '../types';

export const BASE_HP = 5;
export const BASE_SP = 0;
export const REINCARNATION_COST = 10;
export const MaxTithePoints = 25;
export const MaxSpellTargets = 20;
export const CombustibleDurationInSeconds = 10;
export const FiringDurationInSeconds = 30;


export const WizardClasses = {
  Bard: 'Bard',
  Cleric: 'Cleric',
  Druid: 'Druid',
  Hydromancer: 'Hydromancer',
  Necromancer: 'Necromancer',
  Pyromancer: 'Pyromancer',
}

export const SpellNames = {
  DisarmingCharm: 'Disarming Charm',
  MagicalAttunement: 'Magical Attunement',
  WhenIMoveYouMove: 'When I Move, You Move',
  WhenIMoveYouMovePenalty: 'When I Move, You Move - Penalty',
  Invisibility: 'Invisibility',
  RushOfKnowledge: 'Rush of Knowledge',
  BittersweetSymphony: 'Bittersweet Symphony',
  DivineShield: 'Divine Shield',
  Heal: 'Heal',
  Silence: 'Silence',
  RighteousChampion: 'Righteous Champion',
  Tithe: 'Tithe',
  Enervate: 'Enervate',
  ChainLightning: 'Chain Lightning',
  NaturesGrasp: "Nature's Grasp",
  ForceOfNature: 'Force of Nature',
  Whirlwind: 'Whirlwind',
  WhirlwindPenalty: 'Whirlwind - Penalty',
  HailStones: 'Hail Stones',
  IceBarrier: 'Ice Barrier',
  FrostLance: 'Frost Lance',
  FrostBite: 'Frost Bite',
  Blizzard: 'Blizzard',
  BlizzardPenalty: 'Blizzard - Penalty',
  EquivalentExchange: 'Equivalent Exchange',
  CurseOfVulnerability: 'Curse of Vulnerability',
  CurseOfVulnerabilityPenalty: 'Curse Of Vulnerability - Penalty',
  RitualSacrifice: 'Ritual Sacrifice',
  DrainLife: 'Drain Life',
  RaiseDead: 'Raise Dead',
  FightFireWithFire: 'Fight Fire With Fire',
  Fireball: 'Fireball',
  FireballPenalty: 'Fireball - Penalty',
  ScorchedEarth: 'Scorched Earth',
  ScorchedEarthPenalty: 'Scorched Earth - Penalty',
  AgniKai: 'Agni Kai',
  HeatThingsUp: 'Heat Things Up',
}

/* Future conditions
 * Warded = deal damage upon taking damage
 */
export const WizardConditions = {
  Invisible: 'Invisible',
  Invulnerable: 'Invulnerable',
  Vulnerable: 'Vulnerable',
  Cursing: 'Cursing',
  Reanimated: 'Reanimated',
  Controlling: 'Controlling', //need ID of affected players
  Enthralled: 'Enthralled',
  Inspired: 'Inspired',
  Dueling: 'Dueling', // need ID of castBy or affected Player
  Immobilized: 'Immobilized',
  Silenced: 'Silenced',
  Firing: 'Firing', // caster condition from Fireball
  TakingFire: 'Taking Fire', // being attacked by Fireball
  Combustible: 'Combustible', // when pyromancer is attacked, triggers Fight Fire with Fire
  Empowered: 'Empowered',
  Scorching: 'Scorching',
  // external conditions with penalties
  Blizzard: 'Blizzard',
  Dancing: 'Dancing', // when i move you move
  Whirlwind: 'Whirlwind',
};

export const SpellConditionEffects: {[condition: string]: SpellCondition } = {
  [WizardConditions.Invisible]: {
    conditionName: WizardConditions.Invisible,
    clearOnSelfCast: true,
    clearOnEnemyCast: true,
    clearOnCasterSpellCast: false,
    clearOnLevel: false,
  },
  [WizardConditions.Invulnerable]: {
    conditionName: 'Invulnerable',
    clearOnLevel: false,
    clearOnEnemyCast: true,
  },
  [WizardConditions.Vulnerable]: {
    conditionName: 'Vulnerable',
    clearOnLevel: true,
    spell: SpellNames.CurseOfVulnerability,
  },
  [WizardConditions.Cursing]: {
    conditionName: 'Cursing',
    clearOnLevel: false,
  },
  [WizardConditions.Reanimated]: {
    conditionName: WizardConditions.Reanimated,
    clearOnLevel: false,
  },
  [WizardConditions.Controlling]: {
    conditionName: WizardConditions.Controlling,
    clearOnLevel: false,
  },
  // [WizardConditions.Enthralled]: {},
  [WizardConditions.Inspired]: {
    conditionName: 'Inspired',
    clearOnLevel: false,
  },
  [WizardConditions.Dueling]: {
    conditionName: WizardConditions.Dueling,
    clearOnLevel: false,
  },
  [WizardConditions.Immobilized]: {
    conditionName: 'Immobilized',
    clearOnLevel: true,
    clearOnCasterSpellCast: true,
  },
  [WizardConditions.Silenced]: {
    conditionName: 'Silenced',
    clearOnLevel: true,
  },
  [WizardConditions.Firing]: {
    conditionName: WizardConditions.Firing,
    clearOnLevel: false,
    durationInSeconds: 30,
  },
  [WizardConditions.TakingFire]: {
    conditionName: WizardConditions.TakingFire,
    clearOnLevel: false,
    durationInSeconds: 30,
  },
  [WizardConditions.Combustible]: {
    conditionName: WizardConditions.Combustible,
    clearOnLevel: false,
    durationInSeconds: 10,
  },
  [WizardConditions.Scorching]: {
    conditionName: WizardConditions.Scorching,
    clearOnLevel: false,
    durationInSeconds: 600,
  },
  [WizardConditions.Empowered]: {
    conditionName: WizardConditions.Empowered,
    clearOnLevel: false,
    durationInSeconds: 30,
  },
  [WizardConditions.Blizzard]: {
    conditionName: WizardConditions.Blizzard,
    spell: SpellNames.Blizzard,
    clearOnLevel: false,
    clearOnPenalty: true, // cleared for everyone when Caster submits report
  },
  [WizardConditions.Dancing]: {
    conditionName: WizardConditions.Dancing,
    spell: SpellNames.WhenIMoveYouMove,
    clearOnLevel: false,
    clearOnPenalty: true, // cleared for everyone when Caster submits report
  },
  [WizardConditions.Whirlwind]: {
    conditionName: WizardConditions.Whirlwind,
    spell: SpellNames.Whirlwind,
    clearOnLevel: false,
    clearOnPenalty: true, // cleared for everyone when Caster submits report
  },
};

export const ConditionDescriptions = {
  [WizardConditions.Invisible]: 'You cannot be targeted by enemy spells until the next time you cast a spell. Spells that can trigger a penalty without directly targeting you (such as Scorched Earth or Blizzard) can affect you and cancel your invisibility.',
  [WizardConditions.Invulnerable]: 'Enemy spells do not affect you.',
  [WizardConditions.Vulnerable]: 'You must tape a solo cup to the top of your staff until you level up. Any time another wizard places a ping pong ball in this cup, you will lose 1 HP.',
  [WizardConditions.Cursing]: 'You have placed a Curse of Vulnerability on a player. Click the indicated button whenever someone puts a ping pong ball in the cup on their staff to reduce their HP by 1.',
  [WizardConditions.Reanimated]: 'Another wizard has raised you from the dead and is in control of your spirit. They can cast spells from your spellbook until you die again. Afterwards, you may reincarnate as normal. If the wizard controlling you dies before you do, you regain your freedom.',
  [WizardConditions.Controlling]: 'You are temporarily in control of one or more wizards and can cast spells from their spellbook.',
  [WizardConditions.Enthralled]: 'Another wizard is temporarily in control of you and can cast spells from your spellbook.',
  [WizardConditions.Dueling]: 'You are in a duel with another wizard. You cannot be targeted by other wizards aside from your opponent. You may only be in one duel at a time.',
  [WizardConditions.Inspired]: 'You may cast one standard spell without paying the SP cost, but you may not target the person who inspired you. You are unable to use your Class Feature or Archmage ability until you do so.',
  [WizardConditions.Immobilized]: 'You may not move until either you level up or the wizard who immobilized you casts another spell and loses focus.',
  [WizardConditions.Silenced]: 'You are temporarily unable to cast any spells.',
  [WizardConditions.Combustible]: 'You have a limited time to cast Fight Fire with Fire at the person who just attacked you.',
  [WizardConditions.Firing]: 'You cast Fireball at another wizard. They must drink the shot you have provided before the timer expires. You must select whether they drank the shot before the timer expired.',
  [WizardConditions.TakingFire]: 'Someone has launched a Fireball at you! Drink the shot they bring you before the timer expires or lose 3 HP. If the Fireball was a counter attack (cast with Fight Fire with Fire), then you must prepare your own shot.',
  [WizardConditions.Empowered]: 'Your spells are more powerful. You can choose to either double the number of targets your spells can affect or double the effects of your spells. This does not affect the duration of any conditions caused by your spells.',
  [WizardConditions.Scorching]: 'Your Scorched Earth spell is in effect.',
  [WizardConditions.Blizzard]: 'The Blizzard spell is in effect.',
  [WizardConditions.Dancing]: 'Another wizard has cast "When I Move, You Move" at you. When they perform a dance move, you have a few seconds to perform the same move. The wizard can do this 3 times. If you do not perform the dance move quickly enough any of those 3 times, you will lose 10 GHD points.',
  [WizardConditions.Whirlwind]: 'Another wizard has Cast "Whirlwind" at you. When they say the word "Whirlwind", you must spin around 3 times.',
}

interface SpellList {
  Bard: Spell[];
  Cleric: Spell[];
  Druid: Spell[];
  Hydromancer: Spell[];
  Necromancer: Spell[];
  Pyromancer: Spell[];
}

export const SPELLS: SpellList = {
  Bard: [
    {
      name: SpellNames.MagicalAttunement,
      class: WizardClasses.Bard,
      description: 'Your senses attune to the hum of magical energies around you, giving you information about the class, spell points, and conditions of other wizards.',
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 0,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.WhenIMoveYouMove,
      class: WizardClasses.Bard,
      description: "Choose up to three wizards and perform a dance move. Whenever you do the move again, those wizards must do it with you. You may do this 3 times over the span of a few minutes. Any wizards who don't perform the dance move within a few seconds of you each time will lose 10 GHD points.",
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 3,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: SpellConditionEffects[WizardConditions.Dancing],
      externalEffect: true,
    },
    {
      name: SpellNames.Invisibility,
      class: WizardClasses.Bard,
      description: "You cannot be targeted by other wizards' spells until you cast another spell.",
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: SpellConditionEffects[WizardConditions.Invisible],
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
      requiresCustomLogic: true,
    },
    {
      name: SpellNames.RushOfKnowledge,
      class: WizardClasses.Bard,
      description: 'Pick a standard spell (not a Class Feature or Archmage Ability) from any other wizard class and use it once.',
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
      requiresCustomLogic: true,
      requiresChoices: true,
    },
    {
      name: SpellNames.BittersweetSymphony,
      class: WizardClasses.Bard,
      description: 'Every other wizard gains one free cast of any of their standard spells. They must all use this spell within the next 30 seconds. You are immune to the effects of any spells cast during this time.',
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: MaxSpellTargets,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: 'Inspired',
        clearOnLevel: false,
        clearOnSelfCast: true,
      },
      externalEffect: false,
      requiresCustomLogic: true,
    },
  ],
  Cleric: [
    {
      name: SpellNames.DivineShield,
      class: WizardClasses.Cleric,
      description: 'Each time you gain a level, you automatically summon a protective bubble that can absorb one enemy spell before disappearing.',
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
      requiresCustomLogic: true,
    },
    {
      name: SpellNames.Heal,
      class: WizardClasses.Cleric,
      description: 'You heal yourself for up to 2 HP. You cannot exceed your max HP (your wizard level + 5).',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 2,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
      requiresCustomLogic: true,
    },
    {
      name: SpellNames.Silence,
      class: WizardClasses.Cleric,
      description: 'Choose a wizard. They cannot cast spells until they gain a level.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: 'Silenced',
        clearOnLevel: true,
      },
      externalEffect: false,
    },
    {
      name: SpellNames.RighteousChampion,
      class: WizardClasses.Cleric,
      description: 'Choose a wizard. They must help you during a drinking game in one of the following ways: (1) They do any required drinking for you. You may continue playing even while they are drinking. (2) They play instead of you but you must do any required drinking yourself. Whatever points you would win from that game are split between you and your champion. You cannot choose a wizard that is already playing at the same time as you.',
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
    },
    {
      name: SpellNames.Tithe,
      class: WizardClasses.Cleric,
      // TODO: Make it so Tithe can be casted on a future game and collected later?
      description: 'Choose any game (other than Horse Race) that has finished and had scores added to the scoreboard. You subtract 10% of the GHD Points won by every other wizard and may keep up to 25 of those points for yourself. Once any cleric has tithed a game, it cannot be tithed again.',
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: 0,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
      requiresCustomLogic: true,
      requiresChoices: true,
    },
  ],
  Druid: [
    {
      name: SpellNames.Enervate,
      class: WizardClasses.Druid,
      description: 'When you have 0 SP, you may choose 2 wizards. They lose 1 SP each and you gain 1 SP from each.',
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 2,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 1,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: -1,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.ChainLightning,
      class: WizardClasses.Druid,
      description: 'Choose up to 3 wizards. They all lose 1 HP.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 3,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: -1,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.NaturesGrasp,
      class: WizardClasses.Druid,
      description: 'Choose a wizard. They cannot move from their current location until they level up or until you cast another spell.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: 'Immobilized',
        clearOnLevel: true,
        clearOnCasterSpellCast: true,
      },
      externalEffect: true,
    },
    {
      name: SpellNames.Whirlwind,
      class: WizardClasses.Druid,
      description: "Choose up to three wizards. When you say the word 'whirlwind' they must spin around in a complete circle 3 times.",
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 3,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: SpellConditionEffects[WizardConditions.Whirlwind],
      externalEffect: true,
    },
    {
      name: SpellNames.ForceOfNature,
      class: WizardClasses.Druid,
      description: "You channel nature's fury to empower your spells. For the next 30 seconds, you can choose to either double the targets or double the effects of your spells. Doubled effects do not apply to the duration of Nature's Grasp.",
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        conditionName: 'Empowered',
        clearOnLevel: false,
        durationInSeconds: 30,
      },
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
  ],
  Hydromancer: [
    {
      name: SpellNames.HailStones,
      class: WizardClasses.Hydromancer,
      description: 'During any game involving ping pong balls, you may summon 2 extra balls that you can use according to the game rules. In games where players take turns (such as Beer Ball, Beer Pong, etc.), this might mean taking an extra turn or using a ball to tap the table early in Beer Ball. In games without turns (such as Hungry Hungry Hippos or Flip Cup), this might mean using one of your extra balls instead of chasing one that rolled away. You can only use each extra ball once per game, there are no rebounds / rollbacks allowed.',
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
    },
    {
      name: SpellNames.IceBarrier,
      class: WizardClasses.Hydromancer,
      description: 'You surround yourself with a shield of ice that can absorb 1 enemy spell before shattering.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        conditionName: 'Invulnerable',
        clearOnLevel: false,
        clearOnEnemyCast: true,
      },
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.FrostLance,
      class: WizardClasses.Hydromancer,
      description: 'Choose a wizard. You launch a sharp icicle at them. They lose 2 HP.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: -2,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.FrostBite,
      class: WizardClasses.Hydromancer,
      description: 'Choose up to 3 other wizards and fill a cup with ice cubes. Each wizard must put an ice cube in their mouth until it melts.',
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
    },
    {
      name: SpellNames.Blizzard,
      class: WizardClasses.Hydromancer,
      description: "All other wizards must stand together in a cluster and hold out a cup. (Alternatively, everyone can write their name on a cup and place their cups somewhere in a cluster.) Fill your hands (or a cup) with ping pong balls (1 for each player) and stand 10 feet outside the cluster. You may throw your ping pong balls into the group of cups (all at once or one at a time). For each ball that lands in a wizard's cup, that wizard loses 5 GHD points. This may not be cast during games.",
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: MaxSpellTargets,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: SpellConditionEffects[WizardConditions.Blizzard],
      externalEffect: true,
      requiresCustomLogic: true,
    },
  ],
  Necromancer: [
    {
      name: SpellNames.EquivalentExchange,
      class: WizardClasses.Necromancer,
      description: "You may convert 1 HP into 1 SP or vice versa.",
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 0,
      selfTargetOnly: true,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
      requiresCustomLogic: true,
      requiresChoices: true,
    },
    {
      // TODO: In future, could replace the cup with making them lose an extra 1 HP whenever they are attacked
      name: SpellNames.CurseOfVulnerability,
      class: WizardClasses.Necromancer,
      description: "Choose a wizard. They must tape a solo cup to the top of their staff until they level up. Any time another wizard launches/places a ping pong ball into their cup, the target wizard loses 1 HP.",
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: 'Vulnerable',
        clearOnLevel: true,
      },
      externalEffect: true,
    },
    {
      name: SpellNames.RitualSacrifice,
      class: WizardClasses.Necromancer,
      description: 'Choose a wizard. You lose 3 HP and they lose 10 GHD points.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: -3,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: -10,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.DrainLife,
      class: WizardClasses.Necromancer,
      description: 'Choose 3 wizards. They each lose 1 HP and you gain 1 HP from each.',
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 3,
      selfTargetOnly: false,
      casterHitPointEffect: 1,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: -1,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: false,
    },
    {
      name: SpellNames.RaiseDead,
      class: WizardClasses.Necromancer,
      description: 'If another wizard dies and has not yet reincarnated, you may bring them back to life at 3 HP. Until they are killed, they are under your control and you may cast any of their spells using their SP.',
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        conditionName: WizardConditions.Controlling,
        clearOnLevel: false,
      },
      targetHitPointEffect: 3,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: WizardConditions.Reanimated,
        clearOnLevel: false,
      },
      externalEffect: false,
      requiresCustomLogic: true,
    },
  ],
  Pyromancer: [
    {
      //note: same stats as Fireball but with 0 SP
      name: SpellNames.FightFireWithFire,
      class: WizardClasses.Pyromancer,
      description: 'When you are attacked, you have a 10 second window to use this ability which lets you cast Fireball back at your attacker without spending any SP. The target then has 45 seconds to prepare their own shot and drink it.',
      type: 'Class Feature',
      spellPointCost: 0,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        ...SpellConditionEffects[WizardConditions.Firing],
        durationInSeconds: 45,
      },
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        ...SpellConditionEffects[WizardConditions.TakingFire],
        durationInSeconds: 45,
      },
      externalEffect: true,
      requiresCustomLogic: true,
    },
    {
      name: SpellNames.Fireball,
      class: WizardClasses.Pyromancer,
      description: 'Hand a wizard a shot of liquor (or some unpleasant liquid for sober players). They must consume it within 30 seconds. If they do not finish the drink in time, they lose 3 HP.',
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: SpellConditionEffects[WizardConditions.Firing],
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: SpellConditionEffects[WizardConditions.TakingFire],
      externalEffect: true,
      requiresCustomLogic: true,
    },
    {
      name: SpellNames.ScorchedEarth,
      class: WizardClasses.Pyromancer,
      description: "Designate an area (e.g. a corner of the room, a hallway, a doorway). For ten minutes, whenever someone (yourself included) enters that area, they must constantly hop from one foot to the other to avoid getting burned. If they don't, they lose 1 HP. If their feet are not touching the ground (i.e. they are on a piece of furniture), they are safe.",
      type: 'Standard Spell',
      spellPointCost: 1,
      targets: 0,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        conditionName: 'Scorching',
        clearOnLevel: false,
        durationInSeconds: 600,
      },
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
    },
    {
      name: SpellNames.AgniKai,
      class: WizardClasses.Pyromancer,
      description: 'You may challenge any other wizard to a special duel. The duel involves a 1 on 1, sudden death version of one of the following games: (1) Beer Pong - each wizard has only one cup, (2) Flip Cup - the first wizard to flip their cup wins, (3) Stack Cup - the first wizard to bounce a ball into their cup wins, (4) BOAT Race - the first wizard to chug a canned beverage wins. The winner gains 10 GHD points and the loser loses 10 GHD points and 1 HP. You may not cast this while you or your opponent are playing a game. You and your opponent cannot be targeted while dueling.',
      type: 'Standard Spell',
      spellPointCost: 2,
      targets: 1,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: {
        conditionName: 'Dueling',
        clearOnLevel: false,
        durationInSeconds: 300,
      },
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: {
        conditionName: 'Dueling',
        clearOnLevel: false,
      },
      externalEffect: true,
      requiresCustomLogic: true,
      //note: requiresChoices should not be true as the choices are made after casting
      requiresChoices: false,
    },
    {
      name: SpellNames.HeatThingsUp,
      class: WizardClasses.Pyromancer,
      description: 'Your fiery temperament causes events to spiral out of control. You may either (1) raise the stakes of any game that has not started yet (excluding Horse Race / Wisest Wizard) by doubling the points earned from that game or (2) unleash a wave of wild magic, giving each player a random condition (Invisible, Silenced, Empowered, etc.).',
      type: 'Archmage Ability',
      spellPointCost: 3,
      targets: 0,
      selfTargetOnly: false,
      casterHitPointEffect: 0,
      casterSpellPointEffect: 0,
      casterScoreEffect: 0,
      casterLevelEffect: 0,
      casterConditionEffect: null,
      targetHitPointEffect: 0,
      targetSpellPointEffect: 0,
      targetScoreEffect: 0,
      targetLevelEffect: 0,
      targetConditionEffect: null,
      externalEffect: true,
      requiresChoices: true,
    },
  ],
};

// Conditions that can be randomly applied by certain spells
export const RandomizedConditionList = [
  //buffs
  WizardConditions.Invisible,
  WizardConditions.Invulnerable,
  WizardConditions.Inspired,
  WizardConditions.Empowered,
  //debuffs
  WizardConditions.Immobilized,
  WizardConditions.Silenced,
  WizardConditions.Vulnerable,
];

// TODO: Consider ways to make these more dynamic / generic
export const SpellPenalties: { [spellName: string]: Spell } = {
  [SpellNames.CurseOfVulnerabilityPenalty]: {
    name: SpellNames.CurseOfVulnerabilityPenalty,
    class: WizardClasses.Necromancer,
    description: "The target loses 1 HP for being attacked when vulnerable.",
    type: 'Penalty',
    spellPointCost: 0,
    targets: MaxSpellTargets,
    selfTargetOnly: false,
    casterHitPointEffect: 0,
    casterSpellPointEffect: 0,
    casterScoreEffect: 0,
    casterLevelEffect: 0,
    casterConditionEffect: null,
    targetHitPointEffect: -1,
    targetSpellPointEffect: 0,
    targetScoreEffect: 0,
    targetLevelEffect: 0,
    targetConditionEffect: null,
  },
  [SpellNames.WhenIMoveYouMovePenalty]: {
    name: SpellNames.WhenIMoveYouMovePenalty,
    class: WizardClasses.Bard,
    description: "The selected targets lose 10 GHD points for not performing the required action(s) of your spell.",
    type: 'Penalty',
    spellPointCost: 0,
    targets: MaxSpellTargets,
    selfTargetOnly: false,
    casterHitPointEffect: 0,
    casterSpellPointEffect: 0,
    casterScoreEffect: 0,
    casterLevelEffect: 0,
    casterConditionEffect: null,
    targetHitPointEffect: 0,
    targetSpellPointEffect: 0,
    targetScoreEffect: -10,
    targetLevelEffect: 0,
    targetConditionEffect: null,
  },
  [SpellNames.BlizzardPenalty]: {
    name: SpellNames.BlizzardPenalty,
    class: WizardClasses.Hydromancer,
    description: "The selected targets lose 5 GHD points for each ping pong ball that lands in their cups.",
    type: 'Penalty',
    spellPointCost: 0,
    targets: MaxSpellTargets,
    selfTargetOnly: false,
    casterHitPointEffect: 0,
    casterSpellPointEffect: 0,
    casterScoreEffect: 0,
    casterLevelEffect: 0,
    casterConditionEffect: null,
    targetHitPointEffect: 0,
    targetSpellPointEffect: 0,
    targetScoreEffect: -5, // multiplied by number of balls that land in cup
    targetLevelEffect: 0,
    targetConditionEffect: null,
  },
  [SpellNames.WhirlwindPenalty]: {
    name: SpellNames.WhirlwindPenalty,
    class: WizardClasses.Druid,
    description: "The selected targets lose 10 GHD points for not performing the required action(s) of your spell.",
    type: 'Penalty',
    spellPointCost: 0,
    targets: MaxSpellTargets,
    selfTargetOnly: false,
    casterHitPointEffect: 0,
    casterSpellPointEffect: 0,
    casterScoreEffect: 0,
    casterLevelEffect: 0,
    casterConditionEffect: null,
    targetHitPointEffect: 0,
    targetSpellPointEffect: 0,
    targetScoreEffect: -10,
    targetLevelEffect: 0,
    targetConditionEffect: null,
  },
}