import {
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState, useEffect } from 'react';
import { handleSetDoc } from './firestore-helpers';

import { auth } from '../firebase';

export const signUp = async (email, password, userName) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(auth.currentUser, {
      displayName: userName,
    });

    await handleSetDoc('user-details', auth.currentUser.uid, {
      uid: auth.currentUser.uid,
      displayName: userName,
      activeTournament: '',
    });
  } catch (error) {
    alert(error);
  }
}

export const logInWithEmailAndPassword = (email, password) => {
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
}

export const logOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      alert(error)
    });
}

export const resetPassword = (email) => {
  const response = sendPasswordResetEmail(auth, email)
  .then(() => {
    // Password reset email sent!
    return 'success';
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
    console.log('errorCode :>> ', errorCode);
    console.log('errorMessage :>> ', errorMessage);
    return 'error';
  });

  return response;
}

// custom hooks
export function useAuth() {
  const [ currentUser, setCurrentUser ] = useState();

  useEffect(() => {
    // subscribe to auth on component mount
    const unsub = onAuthStateChanged(auth, (user) => { setCurrentUser(user) });
    // unsubscribe from hook on unmount
    return unsub;
  }, [])

  return currentUser;
}