import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../css/index.css';

// components
import PrivateRoute from './auth/private-route';
import Login from './login';
import SignUp from './sign-up';
import Header from './header';
import ScoreBoard from './scoreboard';
import Home from './home';
import WisestWizard from './games/wisest-wizard/wisest-wizard';
import HorseRace from './games/horse-race/horse-race';

// rules
import OverallRules from '../rules/overall-rules.mdx';
import HungryHipposRules from '../rules/hungry-hungry-hippos.mdx';
import BeerBallRules from '../rules/beer-ball.mdx';
import BeerioKartRelayRules from '../rules/beerio-kart-relay.mdx';
import BoatRaceRules from '../rules/boat-race.mdx';
import CivilWarRules from '../rules/civil-war.mdx';
import FlipTacToeRules from '../rules/flip-tac-toe.mdx';
import FlipCupSurvivorRules from '../rules/flip-cup-survivor.mdx';
import MedusaRules from '../rules/medusa.mdx';
import MournivalRules from '../rules/mournival.mdx';
import ShotRouletteRules from '../rules/shot-roulette.mdx';


function App() {
  return (
    <div className="App">
      <Header/>

      <Router>
        <Switch>
          <Route path={'/login'} component={Login} />
          <Route path={'/signup'} component={SignUp} />
          <PrivateRoute exact path={['/', '/home']}>
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path='/score'>
            <ScoreBoard />
          </PrivateRoute>

          <PrivateRoute path="/overall-rules">
            <div className='section'>
              <div className='content'>
                <OverallRules />
              </div>
            </div>
          </PrivateRoute>

          <PrivateRoute path="/wisest-wizard">
            <WisestWizard />
          </PrivateRoute>

          <PrivateRoute exact path='/beer-ball'>
            <div className='section'>
              <div className='content'>
                <BeerBallRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/beerio-kart-relay'>
            <div className='section'>
              <div className='content'>
                <BeerioKartRelayRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/boat-race'>
            <div className='section'>
              <div className='content'>
                <BoatRaceRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/civil-war'>
            <div className='section'>
              <div className='content'>
                <CivilWarRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/flip-tac-toe'>
            <div className='section'>
              <div className='content'>
                <FlipTacToeRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/flip-cup-survivor'>
            <div className='section'>
              <div className='content'>
                <FlipCupSurvivorRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute path='/horse-race'>
            <HorseRace />
          </PrivateRoute>
          <PrivateRoute exact path='/medusa'>
            <div className='section'>
              <div className='content'>
                <MedusaRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/mournival'>
            <div className='section'>
              <div className='content'>
                <MournivalRules />
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/shot-roulette'>
            <div className='section'>
              <div className='content'>
                <ShotRouletteRules />
              </div>
            </div>
          </PrivateRoute>

          <PrivateRoute exact path='/hungry-hippos'>
            <div className='section'>
              <div className='content'>
                <HungryHipposRules />
              </div>
            </div>
          </PrivateRoute>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
