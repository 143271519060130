import React, { useState } from 'react';
import { WizardConditions } from '../../../../constants';
import { playerHasCondition } from '../spell-helpers';
import { Player, Spell } from '../../../../types';
import { usePlayerData } from '../../../data-providers/players-provider';

interface EmpowerSpellChoicesProps {
  activePlayer: Player;
  activeSpell: Spell;
  selectSpellChanges: (empowerType: string) => void;
}

const EmpowerSpellChoices = ({ activePlayer, activeSpell, selectSpellChanges }: EmpowerSpellChoicesProps) => {

  return (
    <div className='block'>
      <p>
        You are currently <strong>empowered</strong> and can choose to increase the effect of your spell in certain ways.
      </p>
      <div className='pt-3'>
        <label className='label'>Choose how to empower your spell</label>
        <div className='select'>
          <select onChange={(e) => selectSpellChanges(e.target.value)}>
            <option value=''>select</option>
            <option value='doubleTargets'>Double the number of targets</option>
            <option value='doubleEffects'>Double the effects</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default EmpowerSpellChoices;
