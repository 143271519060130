import React, { useState } from 'react';
import { isTargetingDisabled } from './spell-helpers';
import { Player, Spell } from '../../../types';

interface SpellTargeterProps {
  show: Boolean;
  spell: Spell;
  handleSelect: (currentTargets: Player[]) => void;
  activePlayer: Player;
  playerTargets: Player[];
  selectedTargets: Player[];
}

const SpellTargeter = ({ 
  show, 
  spell,
  handleSelect, 
  playerTargets, 
  selectedTargets, 
  activePlayer 
}: SpellTargeterProps) => {
  // get list of all targets that CAN be targeted
  const allTargetablePlayers = playerTargets.filter(p => !isTargetingDisabled(p, spell, activePlayer));

  const containsTarget = (target) => {
    return selectedTargets.some((x) => x.userDetails?.uid === target.userDetails.uid);
  }

  const allTargetsSelected = () => {
    return selectedTargets.length === allTargetablePlayers.length;
  }

  const toggleSelectAll = () => {
    if (allTargetsSelected()) {
      handleSelect([]);
    } else {
      handleSelect(allTargetablePlayers);
    }
  }

  const handleTargetSelect = (target) => {
    let currentTargets = [...selectedTargets];

    //remove if already selected
    if (containsTarget(target)) {
      const targetIndex = currentTargets.findIndex(x => x.userDetails?.uid === target.userDetails.uid);
      currentTargets.splice(targetIndex, 1);
    } else if (currentTargets.length >= spell.targets) {
      //warn/disable/remove last target if max targets already selected
      currentTargets.pop();
      currentTargets.push(target);
    } else {
      //add if not selected
      currentTargets.push(target);
    }

    //update parent state
    handleSelect(currentTargets);
  }

  const maxTargets = spell.targets > playerTargets.length ? playerTargets.length : spell.targets;
  const targetLabel = `Choose ${spell.targets > 1 ? `up to ${maxTargets} targets` : 'a target'}`;

  if (!show) return <></>;

  return (
    <div>
      <label className='label is-size-5 has-text-centered pt-2'>{targetLabel}</label>
      <div className="field is-grouped is-grouped-multiline">
        <>
          <p className="control">
            <button
              className={`button ${allTargetsSelected() ? 'is-dark' : ''}`}
              onClick={toggleSelectAll}
              disabled={spell.targets < playerTargets.length}
            >
              All
            </button>
          </p>
        </>
        {playerTargets.map((target: Player) => {
          const targetingDisabled = isTargetingDisabled(target, spell, activePlayer);

          return (
            <>
              <p className="control">
                <button
                  className={`button ${containsTarget(target) ? 'is-dark' : ''}`}
                  onClick={() => handleTargetSelect(target)}
                  disabled={targetingDisabled}
                >
                  {target.userDetails?.displayName}
                </button>
              </p>
            </>);
        })}
      </div>
    </div>
  );
}

export default SpellTargeter;
