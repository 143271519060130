import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { castSpell } from '../../../lib/players';
import { Player, Spell } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import SpellTargeter from './spell-targeter';
import { SpellNames, WizardConditions } from '../../../constants';
import { isTargetingDisabled, playerHasCondition } from './spell-helpers';
import { trackSpellCast } from '../../../lib/wizards';

// custom spell displays
import RushOfKnowledge from './spell-displays/rush-of-knowledge';
import Tithe from './spell-displays/tithe';
import EquivalentExchange from './spell-displays/equivalent-exchange';
import MagicalAttunement from './spell-displays/magical-attunement';
import EmpowerSpellChoices from './spell-displays/empower-spell-choices';
import HeatThingsUp from './spell-displays/heat-things-up';

interface SpellCasterProps {
  open: Boolean;
  activeSpell: Spell;
  activePlayer: Player; // may be different than currentPlayer if a player is being controlled
  handleClose: () => void;
}

/* This component handles displaying the unique UI required by various spells */
const SpellCaster = ({ open, activeSpell, activePlayer, handleClose }: SpellCasterProps) => {
  const { currentPlayer, allPlayers } = usePlayerData();
   // don't allow self-targeting, including for controlled wizards
   const allOtherPlayers = allPlayers.filter(x => x.id !== currentPlayer?.id && x.id !== activePlayer.id);
  const allPlayersTargetedByDefault = activeSpell.targets > allPlayers.length;
  // get list of all targets that CAN be targeted
  const allTargetablePlayers = allOtherPlayers.filter(p => !isTargetingDisabled(p, activeSpell, activePlayer));
  const [selectedTargets, setSelectedTargets] = useState<Array<Player>>(allPlayersTargetedByDefault ? allTargetablePlayers : []);
  const [childSpell, setChildSpell] = useState<Spell|null>(null);
  const [spellOptions, setSpellOptions] = useState<any>(null);
  const [spellToCast, setSpellToCast] = useState<Spell>(childSpell ? childSpell : activeSpell);


  // TODO: track intermediate spells cast such as Rush of Knowledge and Raise Dead -> Controlled player spell
  // const trackIntermediateSpell = () => {
  //   if (!currentPlayer || !spellToCast) return;
  //   trackSpellCast(spellToCast, currentPlayer, selectedTargets, spellOptions);
  // }

  const handleSpellCast = () => {
    if (!spellToCast || !activePlayer) return;

    castSpell(spellToCast, activePlayer, selectedTargets, allPlayers, spellOptions);
    setSelectedTargets([]);
    setChildSpell(null);
    handleClose();
  }

  const handleCancel = () => {
    setSelectedTargets([]);
    setChildSpell(null);
    handleClose();
  }

  const handleChildSpellSelect = (spell: Spell|null) => {
    // handles spells cast via: Rush of Knowledge, etc
    setChildSpell(spell);

    if (!spell) {
      setSpellToCast(activeSpell);
    } else {
      setSpellToCast(spell);
    }
  }

  const getUniqueSpellDisplay = () => {
    if (!activeSpell) return;

    switch (activeSpell.name) {
      case SpellNames.RushOfKnowledge:
        return <RushOfKnowledge handleSpellSelect={handleChildSpellSelect} selectedSpell={childSpell}/>;
      case SpellNames.Tithe:
        return <Tithe handleSelect={setSpellOptions}/>
      case SpellNames.EquivalentExchange:
        return <EquivalentExchange handleClick={setSpellOptions} activePlayer={activePlayer} />
      case SpellNames.MagicalAttunement:
        return <MagicalAttunement />
      case SpellNames.HeatThingsUp:
        return <HeatThingsUp handleSelect={setSpellOptions}/>
      default:
        return <></>;
    }
  }

  const handleEmpoweredSpellChanges = (empowerType) => {
    // Note: not set to spellToCast to prevent exponentially doubling targets/effects
    let empoweredSpell = childSpell ? childSpell : activeSpell;
    empoweredSpell = cloneDeep(empoweredSpell);

    // TODO: track when a spell is empowered
    if (empowerType) empoweredSpell.empowered = empowerType;
    if (empowerType === 'doubleTargets') empoweredSpell.targets *= 2;
    if (empowerType === 'doubleEffects') {
      empoweredSpell.casterHitPointEffect *= 2;
      empoweredSpell.casterSpellPointEffect *= 2;
      empoweredSpell.casterScoreEffect *= 2;
      empoweredSpell.targetHitPointEffect *= 2;
      empoweredSpell.targetSpellPointEffect *= 2;
      empoweredSpell.targetScoreEffect *= 2;
    }

    setSpellToCast(empoweredSpell);
  }

  const showSpellTargeter = spellToCast.targets > 0 || false;
  const disableConfirm = (spellToCast.targets > 0 && selectedTargets.length < 1) || (spellToCast.requiresChoices && !spellOptions);
  const disableCancel = (spellToCast.name === SpellNames.MagicalAttunement);

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{activeSpell.name}</p>
          {!disableCancel &&
            <button className="delete" aria-label="close" onClick={handleCancel}/>
          }
        </header>
        <section className="modal-card-body">
          {playerHasCondition(activePlayer, WizardConditions.Empowered) &&
            <EmpowerSpellChoices
              activeSpell={activeSpell}
              activePlayer={activePlayer}
              selectSpellChanges={handleEmpoweredSpellChanges}
            />
          }

          {getUniqueSpellDisplay()}

          <SpellTargeter 
            show={showSpellTargeter}
            spell={spellToCast}
            handleSelect={setSelectedTargets}
            playerTargets={allOtherPlayers}
            selectedTargets={selectedTargets}
            activePlayer={activePlayer}
          />

          {/* TODO: display a message when there are no other contents displayed in the spell casting modal */}

        </section>
        <footer className="modal-card-foot">
          <button 
            className="button is-primary" 
            onClick={handleSpellCast}
            disabled={disableConfirm}
          >
            Confirm
          </button>
         
          <button className="button" onClick={handleCancel} disabled={disableCancel}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default SpellCaster;
