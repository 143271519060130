import { HorseRaceWagerChoices } from "../../../types";
export const MinHorseRaceWager = 5;
export const MaxHorseRaceWager = 50;

interface WagerMap {
  [any: string]: HorseRaceWager;
}
interface Choice {
  index: number;
  id: 'suitLoses'|'colorFirst'|'colorFirstAndSecond'|'suitFirstOrSecond'|'colorSecond'|'suitFirst'|'rapido'|'colorThird'|'colorFourth'|'suitSecond'|'suitThird'|'suitFourth';
  label: string;
  type: 'suit'|'color';
}
export interface HorseRaceWager {
  name: string;
  description: string;
  odds: string;
  numericOdds: number;
  firstRaceExclusive: boolean;
  choices: Choice[];
}

export const suitColorMap = {
  'Spade': 'black',
  'Diamond': 'red',
  'Club': 'black',
  'Heart': 'red',
};

export const HORSE_RACE_WAGERS: WagerMap = {
  EasyDoesIt: {
    name: 'Easy Does It',
    description: 'Bet which suit loses',
    odds: '1:3',
    numericOdds: 0.33333333333,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'suitLoses', type: 'suit', label: 'Last place' },
    ],
  },
  HarveyDent: {
    name: 'Harvey Dent',
    description: 'Bet which color horse (red/black) wins',
    odds: '1:1',
    numericOdds: 1,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'colorFirst', type: 'color', label: '1st place' },
    ]
  },
  ToPlace: {
    name: 'To Place',
    description: 'Bet which suit comes 1st OR 2nd',
    odds: '1:1',
    numericOdds: 1,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'suitFirstOrSecond', type: 'suit', label: 'Suit' },
    ]
  },
  TwoTone: {
    name: 'Two Tone',
    description: 'Bet which color horse (red/black) wins and which color comes 2nd',
    odds: '2:1',
    numericOdds: 2,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'colorFirst', type: 'color', label: '1st place' },
      { index: 1, id: 'colorSecond', type: 'color', label: '2nd place' },
    ]
  },
  ToWin: {
    name: 'To Win',
    description: 'Bet which suit wins',
    odds: '3:1',
    numericOdds: 3,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'suitFirst', type: 'suit', label: '1st place' },
    ]
  },
  Rapido: {
    name: 'Rapido',
    description: 'Bet which suit is the first out of the gate',
    odds: '3:1',
    numericOdds: 3,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'rapido', type: 'suit', label: 'Suit' },
    ]
  },
  SherwinWilliams: {
    name: 'Sherwin Williams',
    description: 'Bet which color horse (red/black) comes in each place (1-4)',
    odds: '5:1',
    numericOdds: 5,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'colorFirst', type: 'color', label: '1st place' },
      { index: 1, id: 'colorSecond', type: 'color', label: '2nd place' },
      { index: 2, id: 'colorThird', type: 'color', label: '3rd place' },
      { index: 3, id: 'colorFourth', type: 'color', label: '4th place' },
    ]
  },
  DoubleTrouble: {
    name: 'Double Trouble',
    description: 'Bet that a red/black horse comes in 1st and 2nd',
    odds: '5:1',
    numericOdds: 5,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'colorFirstAndSecond', type: 'color', label: 'Color' },
    ]
  },
  //TODO: consider adding in future if the odds can be adjusted depending on how many races are left
  // DailyDouble: {
  //   name: 'Daily Double',
  //   description: 'The @suit@ horse wins two races',
  //   odds: '6:1',
  //   numericOdds: 6,
  //   firstRaceExclusive: true,
  //   choices: [
  //     { index: 0, id: 'colorFirstAndSecond', type: 'suit', label: '' },
  //   ]
  // },
  // BackToBack: {
  //   name: 'Back to Back',
  //   description: 'The @suit@ horse wins two races back to back',
  //   odds: '9:1',
  //   numericOdds: 9,
  //   firstRaceExclusive: true,
  // },
  // FavoriteColor: {
  //   name: 'Favorite Color',
  //   description: 'A @color@ horse wins all three races',
  //   odds: '7:1',
  //   numericOdds: 7,
  //   firstRaceExclusive: false,
  //   choices: [
  //     { index: 0, id: 'colorFirstAndSecond', type: 'color', label: '' },
  //   ]
  // },
  NeckAndNeck: {
    name: 'Neck and Neck',
    description: 'Bet which suit comes in first and which suit comes in second',
    odds: '11:1',
    numericOdds: 11,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'suitFirst', type: 'suit', label: '1st place' },
      { index: 1, id: 'suitSecond', type: 'suit', label: '2nd place' },
    ]
  },
  Superfecta: {
    name: 'Superfecta',
    description: 'Bet which suit comes in each place (1-4)',
    odds: '23:1',
    numericOdds: 23,
    firstRaceExclusive: false,
    choices: [
      { index: 0, id: 'suitFirst', type: 'suit', label: '1st place' },
      { index: 1, id: 'suitSecond', type: 'suit', label: '2nd place' },
      { index: 2, id: 'suitThird', type: 'suit', label: '3rd place' },
      { index: 3, id: 'suitFourth', type: 'suit', label: '4th place' },
    ]
  },
}
