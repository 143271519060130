
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"9 min read","minutes":8.75,"time":525000,"words":1750}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "overview"
    }}>{`Overview`}</h3>
    <p>{`In its simplest form, Wisest Wizard is a drinking game where players drink beer (or other canned beverages) and use their empty cans and some duct tape to construct a “wizard staff” over the course of a night.
This version is much more fun and much more complicated.  `}</p>
    <p>{`Each player begins the game as a fledgeling wizard in a chosen school of magic.
Each of these wizard classes has access to different spells that they may cast to cause various effects such as making other wizards (players) take a drink, lose Groundhog Day points, or do something silly.  `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`There are a few ways to earn GHD points from Wisest Wizard:  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`100 points`}</strong>{` for ending the night as the Wisest Wizard (have the tallest staff).`}</li>
      <li parentName="ul"><strong parentName="li">{`50 points`}</strong>{` for ending the night as an Archmage (highest HP).`}</li>
      <li parentName="ul">{`Some spells may giving you an edge to potentially win more points in other games.`}</li>
      <li parentName="ul">{`Certain spells also cause you to gain or lose GHD points.  `}</li>
    </ul>
    <h3 {...{
      "id": "rules"
    }}>{`Rules`}</h3>
    <h4 {...{
      "id": "building-your-staff"
    }}>{`Building your staff`}</h4>
    <p>{`Every time you finish an `}<strong parentName="p">{`approved beverage`}</strong>{`, you must tape it to the top of your previous empty can.
Once you have done this, you may press the "`}<strong parentName="p">{`level up`}</strong>{`" button in your Spellbook.`}</p>
    <p><strong parentName="p">{`For this version of Wisest Wizard, approved beverages are:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`For players choosing to remain sober:`}</strong>{` Carbonated non-alcoholic canned beverages (sparkling water, sparkling juice, etc)`}</li>
      <li parentName="ul"><strong parentName="li">{`For other players:`}</strong>{` Alcoholic canned beverages (beer, hard seltzer, etc) `}<strong parentName="li">{`OR`}</strong>{` a shot of hard liquor taken `}<em parentName="li">{`in addition to`}</em>{` consuming a carbonated non-alcoholic canned beverage`}</li>
    </ul>
    <p><strong parentName="p">{`For each “level” (can added to your staff), you gain:`}</strong></p>
    <ol>
      <li parentName="ol">{`One `}<strong parentName="li">{`hit point (HP)`}</strong>{`. This represents both your health and your overall power level.
The wizard with the highest HP at any given time is considered to be an Archmage, giving him or her access to another special ability.`}</li>
      <li parentName="ol">{`One `}<strong parentName="li">{`spell point (SP)`}</strong>{`. This works like a currency that you can spend in order to cast a spell.`}</li>
    </ol>
    <p>{`For drinking games involving drinking out of cups (such as Hungry Hungry Hippos), you will pour your current approved beverage into the cups you drink out of so that drinking will still count towards Wisest Wizard.`}</p>
    <h4 {...{
      "id": "casting-spells"
    }}>{`Casting Spells`}</h4>
    <p>{`Every wizard has access to a list of spells unique to their chosen class.
Each spell costs from 0–3 `}<strong parentName="p">{`spell points`}</strong>{` depending on how powerful it is.
To cast a spell, you must have the required number of `}<strong parentName="p">{`spell points`}</strong>{` as listed in the spell description.`}</p>
    <p>{`You may cast a spell at any time unless the description says it can’t be used during games.
Once you cast a spell, you lose the `}<strong parentName="p">{`spell points`}</strong>{` required by the spell.`}</p>
    <p><strong parentName="p">{`To cast a spell, you must:`}</strong></p>
    <ol>
      <li parentName="ol">{`Select the spell in your spellbook`}</li>
      <li parentName="ol">{`Confirm which players your spell will target`}</li>
      <li parentName="ol">{`Loudly declare your spell casting to the room.
However if your spell only targets yourself such as the `}<em parentName="li">{`Bard`}</em>{` spell `}<em parentName="li">{`Invisibility`}</em>{`, you may choose to keep it secret.  `}</li>
    </ol>
    <h5 {...{
      "id": "external-effects"
    }}>{`External Effects`}</h5>
    <p>{`Some spells may require you or the wizard(s) your spell targets to perform some action outside of this app.
`}<em parentName="p">{`Unless a spell says otherwise, the general penalty when someone does not perform a required action is that they lose 10 GHD points`}</em>{`.`}</p>
    <h4 {...{
      "id": "health--hit-points-hp"
    }}>{`Health / Hit Points (HP)`}</h4>
    <p>{`Each wizard begins with 5 `}<strong parentName="p">{`health points`}</strong>{` (referred to as `}<strong parentName="p">{`HP`}</strong>{`).
As you level up by drinking and adding cans to your wizard staff, you gain `}<strong parentName="p">{`1 HP for each can`}</strong>{`.
Your `}<strong parentName="p">{`HP`}</strong>{` will go up and down during play as you add cans to your staff and as spells are cast that impact your `}<strong parentName="p">{`HP`}</strong>{`.`}</p>
    <h4 {...{
      "id": "conditions"
    }}>{`Conditions`}</h4>
    <p>{`Some spells will apply a `}<strong parentName="p">{`condition`}</strong>{` either to the wizard casting a spell, the targets of the spell, or both.  `}</p>
    <p>{`For example, the Bard spell `}<em parentName="p">{`Invisibility`}</em>{` applies the `}<strong parentName="p">{`Invisible`}</strong>{` condition to the caster, meaning they cannot be targeted by enemy spells until the next time they cast a spell.  `}</p>
    <p>{`If you are affected by a one or more conditions, you will see a message on the Wisest Wizard page that can provide more information about each of your conditions, including when they will expire.  `}</p>
    <p>{`Scroll to the bottom of this page for a full list of possible conditions.  `}</p>
    <h4 {...{
      "id": "death"
    }}>{`Death`}</h4>
    <p>{`Certain spells cast by enemy wizards may reduce your HP.`}</p>
    <p><strong parentName="p">{`If your HP is reduced to 0, you are considered dead and face the following effects:`}</strong></p>
    <ol>
      <li parentName="ol">{`You cannot cast spells`}</li>
      <li parentName="ol">{`You cannot add cans to your staff`}</li>
      <li parentName="ol">{`You cannot be attacked by other wizards`}</li>
    </ol>
    <h4 {...{
      "id": "reincarnation"
    }}>{`Reincarnation`}</h4>
    <p>{`For wizards, death is not necessarily the end.
Deceased wizards have the option of returning to life in exchange for `}<strong parentName="p">{`10 GHD points`}</strong>{`.  `}</p>
    <p><strong parentName="p">{`If you choose to reincarnate and continue playing:`}</strong></p>
    <ol>
      <li parentName="ol">{`Your `}<strong parentName="li">{`HP`}</strong>{` is restored to your starting HP (5) plus your current level divided by 2 (rounded down)`}</li>
      <li parentName="ol">{`Your `}<strong parentName="li">{`SP`}</strong>{` is unchanged from whatever it was before you reincarnated`}</li>
      <li parentName="ol">{`You must choose a different class`}</li>
      <li parentName="ol">{`You may once again cast spells, be targeted by spells, and add cans to your staff`}</li>
      <li parentName="ol">{`You lose 10 `}<strong parentName="li">{`GHD points`}</strong>{`  `}</li>
    </ol>
    <p><strong parentName="p">{`Example`}</strong><br parentName="p"></br>{`
`}{`A level 8 Pyromancer has been reduced to `}<strong parentName="p">{`0 HP`}</strong>{`.
She had `}<strong parentName="p">{`2 SP`}</strong>{` when she died.  `}</p>
    <p>{`She chooses to reincarnate as a Druid with `}<strong parentName="p">{`8 HP`}</strong>{` and `}<strong parentName="p">{`2 SP`}</strong>{`.  `}</p>
    <p>{`She loses `}<strong parentName="p">{`10 GHD points`}</strong>{`.  `}</p>
    <h4 {...{
      "id": "special-roles"
    }}>{`Special Roles`}</h4>
    <p>{`There are two special roles that can be earned during play:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Wisest Wizard`}</strong>{` and `}<strong parentName="p">{`Archmage`}</strong>{`.`}</p>
    <h5 {...{
      "id": "the-wisest-wizard"
    }}>{`The Wisest Wizard`}</h5>
    <p>{`The wizard with the tallest staff (i.e. the person who has drank the most) is known as the `}<strong parentName="p">{`Wisest Wizard`}</strong>{`.
Even if you are reduced to `}<strong parentName="p">{`0 HP`}</strong>{`, you still hold the title as long as you have the tallest staff.  `}</p>
    <p>{`Whoever holds this title at the end of the game earns bragging rights and a significant amount of points.  `}</p>
    <p>{`There can only be one `}<strong parentName="p">{`Wisest Wizard`}</strong>{`.
If two or more players are tied for having the tallest staff, then no one can claim the title.  `}</p>
    <h5 {...{
      "id": "the-archmages"
    }}>{`The Archmage(s)`}</h5>
    <ul>
      <li parentName="ul">{`The wizard with the highest `}<strong parentName="li">{`HP`}</strong>{` at any given time is considered an `}<strong parentName="li">{`Archmage`}</strong>{` and has access to a special ability specific to their class.`}</li>
      <li parentName="ul">{`The gray box under your wizard stats at the top of this page will indicate whether or not you a currently an `}<strong parentName="li">{`Archmage`}</strong>{`.`}</li>
      <li parentName="ul">{`Unlike the `}<strong parentName="li">{`Wizest Wizard`}</strong>{`, if two or more wizards are tied for highest `}<strong parentName="li">{`HP`}</strong>{` then each can claim the title of `}<strong parentName="li">{`Archmage`}</strong>{`.  `}</li>
    </ul>
    <h4 {...{
      "id": "classes"
    }}>{`Classes`}</h4>
    <p>{`Each class has:`}</p>
    <ul>
      <li parentName="ul">{`A unique list of spells focused on different aspects of play`}</li>
      <li parentName="ul">{`A `}<strong parentName="li">{`Class Feature`}</strong>{` (a spell / passive ability that represents the thematic elements of that class)`}</li>
      <li parentName="ul">{`An `}<strong parentName="li">{`Archmage Ability`}</strong>{` (a spell / ability that is only available to wizards of that class while they hold the title of `}<strong parentName="li">{`Archmage`}</strong>{`).`}</li>
    </ul>
    <p>{`Click on the `}<em parentName="p">{`Classes`}</em>{` tab to explore the abilities of each class.  `}</p>
    <h4 {...{
      "id": "full-list-of-conditions"
    }}>{`Full List of Conditions`}</h4>
    <p><em parentName="p">{`These are listed as a reference, you don’t need to memorize them. If a condition is applied to you, you will be able to view more details about it from your Spellbook.`}</em>{`  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Controlling:`}</strong>{` You are temporarily in control of one or more wizards and can cast spells from their spellbook.`}</li>
      <li parentName="ul"><strong parentName="li">{`Combustible:`}</strong>{` You have a limited time to cast Fight Fire with Fire at the person who just attacked you.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dancing:`}</strong>{` Another wizard has cast “When I Move, You Move” at you. When they perform a dance move, you have a few seconds to perform the same move. The wizard can do this 3 times. If you do not perform the dance move quickly enough any of those 3 times, you will lose 10 GHD points.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dueling:`}</strong>{` You are in a duel with another wizard. You cannot be targeted by other wizards aside from your opponent. You may only be in one duel at a time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Empowered:`}</strong>{` Your spells are more powerful. You can choose to either double the number of targets your spells can affect or double the effects of your spells. This does not affect the duration of any conditions caused by your spells.`}</li>
      <li parentName="ul"><strong parentName="li">{`Enthralled:`}</strong>{` Another wizard is temporarily in control of you and can cast spells from your spellbook.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inspired:`}</strong>{` You may cast one standard spell without paying the SP cost, but you may not target the person who inspired you. You are unable to use your Class Feature or Archmage ability until you do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Invisible:`}</strong>{` You cannot be targeted by enemy spells until the next time you cast a spell. Spells that can trigger a penalty without directly targeting you (such as Scorched Earth or Blizzard) can affect you and cancel your invisibility.`}</li>
      <li parentName="ul"><strong parentName="li">{`Invulnerable:`}</strong>{` Enemy spells do not affect you.`}</li>
      <li parentName="ul"><strong parentName="li">{`Immobilized:`}</strong>{` You may not move until either you level up or the wizard who immobilized you casts another spell and loses focus.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reanimated:`}</strong>{` Another wizard has raised you from the dead and is in control of your spirit. They can cast spells from your spellbook until you die again. Afterwards, you may reincarnate as normal. If the wizard controlling you dies before you do, you regain your freedom.`}</li>
      <li parentName="ul"><strong parentName="li">{`Silenced:`}</strong>{` You are temporarily unable to cast any spells.`}</li>
      <li parentName="ul"><strong parentName="li">{`Taking Fire:`}</strong>{` Someone has launched a Fireball at you. Drink the shot they bring you before the timer expires or lose 3 HP.  If the Fireball was a counter attack (cast with Fight Fire with Fire), then you must prepare your own shot.`}</li>
      <li parentName="ul"><strong parentName="li">{`Vulnerable:`}</strong>{` You must tape a solo cup to the top of your staff until you level up. Any time another wizard places a ping pong ball in this cup, you will lose 1 HP.`}</li>
      <li parentName="ul"><strong parentName="li">{`Whirlwind:`}</strong>{` Another wizard has Cast “Whirlwind” at you. When they say the word “Whirlwind”, you must spin around 3 times.`}</li>
    </ul>
    <h5 {...{
      "id": "tracking-conditions"
    }}>{`Tracking Conditions`}</h5>
    <p>{`The following conditions are just intended to help players track the effects of their spells that impact others.
If you have one of these conditions, you will see a message that you can expand to take action.  `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Blizzard:`}</strong>{` The Blizzard spell is in effect.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cursing:`}</strong>{` You have placed a Curse of Vulnerability on a player. Click the indicated button whenever someone puts a ping pong ball in the cup on their staff to reduce their HP by 1.`}</li>
      <li parentName="ul"><strong parentName="li">{`Firing:`}</strong>{` You cast Fireball at another wizard. They must drink the shot you have provided before the timer expires. You must select whether they drank the shot before the timer expired.`}</li>
      <li parentName="ul"><strong parentName="li">{`Scorching:`}</strong>{` Your Scorched Earth spell is in effect.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "overview",
    level: 3,
    title: "Overview",
    children: [
        
      ]
  },
{
    id: "rules",
    level: 3,
    title: "Rules",
    children: [
        
      ]
  }
]

export const frontMatter = {}

