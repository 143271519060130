import React, { useState } from 'react';
import { WizardConditions } from '../../../../constants';
import { submitAgniKaiResults } from '../../../../lib/wizards';
import { playerHasCondition } from '../spell-helpers';
import { Player } from '../../../../types';
import { usePlayerData } from '../../../data-providers/players-provider';

interface AgniKaiProps {
  activePlayer: Player;
}


const AgniKaiResults = ({ activePlayer }: AgniKaiProps) => {
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>();
  const { allPlayers } = usePlayerData();

  const duelists: Player[]|undefined = allPlayers.filter(p => playerHasCondition(p, WizardConditions.Dueling) && p.wizard.conditions[WizardConditions.Dueling].affectedPlayers.includes(activePlayer.id));

  const handleAgniKaiResults = async () => {
    const winner = duelists.find(p => p.id === selectedPlayerId);
    const loser = duelists.find(p => p.id !== selectedPlayerId);

    if (winner && loser) await submitAgniKaiResults(winner, loser);
    setSelectedPlayerId('');
    setShowModal(!showModal);
  }

  const toggleModal = () => {
    setSelectedPlayerId('');
    setShowModal(!showModal);
  }

  return (
    <div className=''>
      <article className="message is-small is-warning">
        <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
          <p className=''>
            Submit your Agni Kai duel results
          </p>
          <button className='button is-small is-warning' onClick={toggleModal}>
            More
          </button>
        </div>
      </article>

      <div className={`modal ${showModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Submit Agni Kai Results</p>
          <button className="delete" aria-label="close" onClick={toggleModal}/>
        </header>
        <section className="modal-card-body">
          <label className='label'>Who won the duel?</label>
          <div className='select'>
            <select onChange={(e) => setSelectedPlayerId(e.target.value)}>
              <option value=''>select</option>
              {duelists.map(player => (
                <option value={player.id}>{player.userDetails?.displayName}</option>
              ))}
            </select>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button 
            className="button is-primary" 
            onClick={handleAgniKaiResults}
            disabled={Boolean(!selectedPlayerId)}
          >
            Confirm
          </button>
          <button className="button" onClick={toggleModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
    </div>
  );
}

export default AgniKaiResults;
