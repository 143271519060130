import { SpellNames, WizardConditions } from '../../../constants';
import { Player, Spell } from '../../../types';

// check to see if a wizard has a certain condition
export function playerHasCondition(player: Player, condition: string) {
  const conditions = player.wizard.conditions;
  return Object.keys(conditions).includes(condition);
}

export function playerHasAnyOfConditions(player: Player, conditions: string[]) {
  const wizardConditions = Object.keys(player.wizard.conditions);
  let hasCondition = false;
  for (let i = 0; i < conditions.length; i++) {
    if (wizardConditions.includes(conditions[i])) {
      hasCondition = true;
      break;
    }
  }
  return hasCondition;
}

// determines whether a given player can be targeted by a given spell
export const isTargetingDisabled = (target: Player, spell: Spell, activePlayer: Player) => {
  const activePlayerConditions = activePlayer.wizard.conditions;
  const targetConditions = target.wizard.conditions;
  const targetIsInvisible = playerHasCondition(target, WizardConditions.Invisible);
  const targetIsReanimated = playerHasCondition(target, WizardConditions.Reanimated);
  const targetIsDead = target.wizard.hitPoints < 1;

  //caster is inspired and target was the inspirer
  const casterIsInspired = playerHasCondition(activePlayer, WizardConditions.Inspired);
  if (casterIsInspired && activePlayerConditions[WizardConditions.Inspired].castBy === target.id) return true;

  // target is dueling someone other than the caster
  const targetIsDueling = playerHasCondition(target, WizardConditions.Dueling);
  if (targetIsDueling && !targetConditions[WizardConditions.Dueling].affectedPlayers.includes(activePlayer.id)) return true;

  // TODO: add a spell property for AoE effects that bypass Invisibility
  if (targetIsInvisible && spell.name !== SpellNames.ScorchedEarthPenalty && spell.name !== SpellNames.Blizzard) return true;
  if (targetIsDead && spell.name !== SpellNames.RaiseDead) return true;
  if (!targetIsDead && spell.name === SpellNames.RaiseDead) return true;
  if (targetIsReanimated && spell.name === SpellNames.RaiseDead) return true;

  // Fight Fire with Fire
  if (spell.name === SpellNames.FightFireWithFire && !activePlayerConditions[WizardConditions.Combustible].affectedPlayers.includes(target.id)) {
    return true;
  }

  return false;
}