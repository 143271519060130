import { db } from '../firebase';
import { 
  collection, 
  deleteField, 
  doc, 
  query,
  updateDoc,
  where,
  runTransaction,
} from "firebase/firestore"; 
import getValue from 'get-value';
import { getUserDetails } from './user-details';
import { User } from '@firebase/auth';
import { Player, Spell, NewPlayer, PlayerNotification } from '../types';
import { getSpellEffects, trackSpellCast } from './wizards';
import { generateFirestoreId } from './firestore-helpers';


export function createPlayer(tournamentId: string, user: User, userDetails, isHost: boolean): NewPlayer {
  return {
    tournamentId: tournamentId,
    userDetails: userDetails,
    isHost: isHost,
    // TODO: Get game list dynamically
    score: {
      WisestWizard: 0,
      BeerioKartRelay: 0,
      BeerBall: 0,
      BoatRace: 0,
      CivilWar: 0,
      FlipTacToe: 0,
      FlipCupSurvivor: 0,
      HungryHungryHippos: 0,
      HorseRace: 0,
    },
    // TODO: After MVP, game specific settings will depend on which games are selected
    // to be part of the tournament
    wizard: {
      class: '',
      startingClass: '',
      level: 0,
      hitPoints: 5,
      spellPoints: 0,
      spellsCast: 0,
      timesTargeted: 0,
      isArchmage: false,
      conditions: {},
      reincarnations: [],
    },
    horseRaceBets: [],
  }
}

export async function getTournamentPlayersQuery(user: User|null|undefined) {
  //TODO: handle undefined user?
  if (!user) return;

  try {
    //fetch user details to get active tournament
    const userDetails = await getUserDetails(user);
    const activeTournament = userDetails && userDetails['activeTournament'];
    //query to fetch player data for active tournament
    return query(collection(db, 'players'), where('tournamentId', '==', activeTournament));
  } catch (error) {
    // TODO: do something
    alert(error);
  }

}

// Note: This runs without transactions. Be careful when using it for updating values that may have
// simultaneous updates such as Wisest Wizard properties
export async function updatePlayerProperties(playerId: string, updates) {
  try {
    await updateDoc(doc(db, 'players', playerId), updates);
  } catch (error) {
    // TODO: do something
    alert(error);
  }
}

// with transactions
export async function updatePlayerProperties_NEW(playerId: string, rawUpdates) {
  try {
    await runTransaction(db, async (transaction) => {
      const playerRef = doc(db, 'players', playerId);
      const playerDoc = await transaction.get(playerRef);
      const player = playerDoc.data() as Player;

      // create updates object
      let updates = {};
      // must handle number and string values
      for (const [key, value] of Object.entries(rawUpdates)) {
        console.log(`${key}: ${value}`);
        const currentValue = getValue(player, key);
        updates[key] = currentValue 
      }

      // transaction.update(playerRef, updates);
    })
  } catch (error) {
    // TODO: do something
    alert(error);
  }
}


export async function deletePlayerProperty(player: Player, property) {
  if (!player.id) return;

  try {
    await updateDoc(doc(db, 'players', player.id), {
      [property]: deleteField(),
    });
  } catch (error) {
    // TODO: do something
    alert(error);
  }
}

export async function runSpellTransaction(spell: Spell, casterId: string, targetIds: string[], allPlayerIds: string[], spellOptions: any) {
  try {
    await runTransaction(db, async (transaction) => {
      const allOtherPlayerIds = allPlayerIds.filter(id => id !== casterId && !targetIds.includes(id));
      const casterRef = doc(db, 'players', casterId);
      const casterDoc = await transaction.get(casterRef);
      const caster = casterDoc.data() as Player;

      let targets: Player[] = await Promise.all(targetIds.map(async (id) => {
        const playerRef = doc(db, 'players', id);
        const playerDoc = await transaction.get(playerRef);
        return playerDoc.data() as Player;
      }));
      let otherPlayers: Player[] = await Promise.all(allOtherPlayerIds.map(async (id) => {
        const playerRef = doc(db, 'players', id);
        const playerDoc = await transaction.get(playerRef);
        return playerDoc.data() as Player;
      }))

      const allPlayers = [caster, ...targets, ...otherPlayers];

      let { playerMap, playersWithUpdates, messages } = getSpellEffects(spell, caster, targets, allPlayers, spellOptions);

      playersWithUpdates.forEach(async playerId => {
        const playerRef = doc(db, 'players', playerId);
        const updates = playerMap[playerId];
        transaction.set(playerRef, updates);
      });

      Object.keys(messages).forEach(playerId => {
        const newMessageRef = doc(db, 'notifications', generateFirestoreId());
        const playerMessages = messages[playerId];

        playerMessages.forEach(msg => {
          const notification: PlayerNotification = {
            uid: playerMap[playerId].userDetails?.uid || '',
            tournamentId: playerMap[playerId].tournamentId,
            message: msg,
            type: 'WisestWizard',
          }
          transaction.set(newMessageRef, notification);
        })
      })
    });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
}

// handle spell cast
export async function castSpell(spell: Spell, caster: Player, targets: Player[], allPlayers: Player[], spellOptions: any) {
  const targetIds = targets.map(x => x.id);
  const allPlayerIds = allPlayers.map(x => x.id);
  trackSpellCast(spell, caster, targets, spellOptions);

  await runSpellTransaction(spell, caster.id, targetIds, allPlayerIds, spellOptions);
}

