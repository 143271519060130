import dayjs from 'dayjs';
import React, {useState, useEffect, useRef} from 'react';
import { func, string } from 'prop-types';

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}

export default function Countdown({ expirationTime, onTimerFinish }) {
  const [secondsRemaining, setSecondsRemaining] = useState();
  const [status, setStatus] = useState(STATUS.STARTED);

  useEffect(() => {
    // allow timer to reset if expirationTime changes
    setSecondsRemaining(getSecondsFromNow(expirationTime));
  }, [expirationTime])

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
        onTimerFinish();
      }
    },
    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )

  return (
    <span>{getTimeMessage(secondsRemaining)}</span>
  )
}

function getSecondsFromNow(expiration) {
  const secFromNow = dayjs(expiration).diff(dayjs(), 'second');
  return secFromNow > 0 ? secFromNow : 0; 
}

// converts seconds to HH:MM:SS format
function secondsToTime(e){
  var hours = Math.floor(e / 3600).toString().padStart(2,'0'),
      minutes = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
      seconds = Math.floor(e % 60).toString().padStart(2,'0');
  
  return `${hours}:${minutes}:${seconds}`;
}

function getTimeMessage(e){
  var hours = Math.floor(e / 3600),
      minutes = Math.floor(e % 3600 / 60),
      seconds = Math.floor(e % 60);
  
  let message = `${seconds} seconds`;
  if (minutes > 0) message = `${minutes} minutes and ${seconds} seconds`;
  if (hours > 0) message = secondsToTime(e);
  
  return message;
}

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

Countdown.defaultProps = {
  onTimerFinish: () => null,
}

Countdown.propTypes = {
  expirationTime: string,
  onTimerFinish: func,
}
