
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.065,"time":123900,"words":413}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "horse-race"
    }}>{`Horse Race`}</h3>
    <h4 {...{
      "id": "materials"
    }}>{`Materials`}</h4>
    <p>{`A deck of cards `}</p>
    <h4 {...{
      "id": "setup"
    }}>{`Setup`}</h4>
    <p>{`The setup for this game will resemble a tall “L”.  `}</p>
    <p>{`First, find the four Aces and arrange them side by side (face-up).  `}</p>
    <p>{`Then, shuffle the deck and lay the top 7 cards from the deck face-down in a column next to the Aces, with the first card starting above the row of Aces.  `}</p>
    <p>{`Finally, appoint someone as the race “announcer”, who will flip the cards throughout the game.  `}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p><strong parentName="p">{`Basic rules:`}</strong><br parentName="p"></br>{`
`}{`Horse Race involves gambling on what suit of cards will win a “horse race”, where the Ace from each suit progresses by cards from their suit being randomly drawn by the “announcer”.`}</p>
    <p>{`The announcer begins the game by flipping over one card at a time from the top of the deck of cards.  `}</p>
    <p>{`Aces advance one row at a time if they match the suit of the card that is flipped. `}</p>
    <p>{`For example, if the three of clubs is flipped, the Ace of Clubs advances one row.
This process continues until one card eventually advances past the final row.`}</p>
    <p>{`Whenever all four Aces have reached the same row for the first time (they don’t all need to be there at the same time), the card representing that row in the column of face-down cards is flipped over, and the Ace of that suit is moved back 1 row.  `}</p>
    <p><strong parentName="p">{`Groundhog Day rules:`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`Each player places 1 bet per round, wagering a certain number of Groundhog Day points on which “horse” will win (or some other scenario listed in the betting sheet).  `}</li>
      <li parentName="ul">{`Each player must bet between a minimum of 5 and a maximum of 50 points per round.`}</li>
      <li parentName="ul">{`If a player has less than 5 points, they must bet all the points they have.`}</li>
      <li parentName="ul">{`If one or more player has 0 points to bet, the group can vote on whether to give `}<em parentName="li">{`every`}</em>{` player an additional number of points so that everyone may participate.`}</li>
      <li parentName="ul">{`The deck should be shuffled after each round to keep the probability of bets consistent.`}</li>
      <li parentName="ul">{`For ambiance, play some version of the Kentucky Derby song during each race.  `}</li>
    </ul>
    <h4 {...{
      "id": "heats"
    }}>{`Heats`}</h4>
    <p>{`We will have 3 races.
Everyone participates each round.  `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`For each race:`}</p>
    <ul>
      <li parentName="ul">{`If your bet is correct, you gain points equal to the points you staked multiplied by the odds of the bet you chose. `}</li>
      <li parentName="ul">{`If your bet is wrong, you lose the points you staked.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "horse-race",
    level: 3,
    title: "Horse Race",
    children: [
        
      ]
  }
]

export const frontMatter = {}

