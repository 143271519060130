import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { SpellNames, WizardConditions, SpellPenalties, SpellConditionEffects } from '../../../../constants';
import { Player, Spell } from '../../../../types';
import { castSpell, deletePlayerProperty } from '../../../../lib/players';
import { usePlayerData } from '../../../data-providers/players-provider';
import SpellTargeter from '../spell-targeter';
import BlizzardTracker from './blizzard-tracker';

interface PenaltyTrackerProps {
  activePlayer: Player;
}

const PenaltyTracker = ({ activePlayer }: PenaltyTrackerProps) => {
  const { allPlayers } = usePlayerData();
  const [showModal, setShowModal] = useState<boolean>();
  const [activeCondition, setActiveCondition] = useState<string>();
  const [penaltySpell, setPenaltySpell] = useState<Spell|null>(null);
  const [possibleTargets, setPossibleTargets] = useState<Array<Player>>([]);
  const [selectedTargets, setSelectedTargets] = useState<Array<Player>>([]);
  const [blizzardPenalties, setBlizzardPenalties] = useState<any>();

  // See if any players have a relevant condition cast by the active player
  const conditionTracking = {};
  allPlayers.forEach(player => {
    const conditions = player.wizard.conditions;
    Object.keys(conditions).forEach(conditionId => {
      if (conditions[conditionId].castBy === activePlayer.id) {
        const otherAffectedPlayers = conditionTracking[conditionId]?.affectedPlayers || [];
        conditionTracking[conditionId] = { 
          affectedPlayers: [...otherAffectedPlayers, player.id] 
        };
      }
    })
  });

  const penaltiesAccrueOverTime = [WizardConditions.Vulnerable];
  const validConditions = [WizardConditions.Vulnerable, WizardConditions.Blizzard, WizardConditions.Dancing, WizardConditions.Whirlwind];
  const filteredConditions = Object.keys(conditionTracking).filter(x => validConditions.includes(x));

  const closeModal = () => {
    setShowModal(false);
    setActiveCondition('');
    setPenaltySpell(null);
  }

  const viewMore = (conditionName: string) => {
    setShowModal(true);
    setActiveCondition(conditionName);
    setPenaltySpell(getPenaltySpellForCondition(conditionName));

    const playerTargets = allPlayers.filter(p => conditionTracking[conditionName].affectedPlayers.includes(p.id));
    setPossibleTargets(playerTargets);
  }

  const handleConditionOver = async (conditionName: string) => {
    possibleTargets.forEach(async target => {
      await deletePlayerProperty(target, `wizard.conditions.${conditionName}`);
    })
  }

  const getPenaltySpellForCondition = (conditionName: string) => {
    const penaltySpellConditionMap = {
      [WizardConditions.Vulnerable]: SpellPenalties[SpellNames.CurseOfVulnerabilityPenalty],
      [WizardConditions.Blizzard]: SpellPenalties[SpellNames.BlizzardPenalty],
      [WizardConditions.Dancing]: SpellPenalties[SpellNames.WhenIMoveYouMovePenalty],
      [WizardConditions.Whirlwind]: SpellPenalties[SpellNames.WhirlwindPenalty],
    };

    return penaltySpellConditionMap[conditionName];
  }

  const submitPenalty = async (activeCondition: string) => {
    if (!penaltySpell) return;

    // multiply blizard penalty scoreEffect by number of balls
    if (penaltySpell.name === SpellNames.BlizzardPenalty) {
      selectedTargets.forEach(async t => {
        const spellCopy = cloneDeep(penaltySpell); //clone spell to avoid mutation
        spellCopy.targetScoreEffect *= blizzardPenalties[t.id];
        await castSpell(spellCopy, activePlayer, [t], allPlayers, null);
      })
    } else {
      await castSpell(penaltySpell, activePlayer, selectedTargets, allPlayers, null);
    }

    // Only clear conditions that have penalties reported all at once
    if (!penaltiesAccrueOverTime.includes(activeCondition)) {
      await handleConditionOver(activeCondition);
    }

    setShowModal(!showModal);
    setSelectedTargets([]);
  }

  const handleBlizzardChange = (penalties) => {
    setBlizzardPenalties(penalties);
    // setSelectedTargets to those with blizzard penalties
    const blizzardTargets = possibleTargets.filter(t => Object.keys(penalties).includes(t.id))
    setSelectedTargets(blizzardTargets);
  }


  return (
    <div className=''>
      {activePlayer && filteredConditions.map(conditionName => (
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              Record penalties for {SpellConditionEffects[conditionName].spell}
            </p>
            <button className='button is-small is-warning' onClick={() => viewMore(conditionName)}>
              More
            </button>
          </div>
        </article>
      ))}

      {showModal && activeCondition && penaltySpell &&
        <div className={`modal ${showModal ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{penaltySpell.name}</p>
              <button className="delete" aria-label="close" onClick={closeModal}/>
            </header>
            <section className="modal-card-body">
              <label className='label'>{penaltySpell.description}</label>
                
              <div className='box'>
                <SpellTargeter 
                  show={penaltySpell.targets > 0 && activeCondition !== WizardConditions.Blizzard}
                  spell={penaltySpell}
                  handleSelect={setSelectedTargets}
                  playerTargets={possibleTargets}
                  selectedTargets={selectedTargets}
                  activePlayer={activePlayer}
                />

                {/* For Blizzard, distribute points with max = to number of players */}
                {activeCondition === WizardConditions.Blizzard && 
                  <BlizzardTracker targets={possibleTargets} handleChange={handleBlizzardChange}/>
                }
              </div>

              {!penaltiesAccrueOverTime.includes(activeCondition) &&
                <div>
                  <p><i>Note: Penalties for this spell must be recorded all at once. Once you submit the penalties, the condition applied to the wizards targeted by your spell will be cleared and you will not be able to submit additional penalties.</i></p>
                </div>
              }

              {penaltiesAccrueOverTime.includes(activeCondition) &&
                <div>
                  <p><i>Note: Penalties for this spell may be recorded as they happen until the condition caused by the spell expires.</i></p>
                </div>
              }

            </section>
            <footer className="modal-card-foot">
              {/* Confirm not disabled in case no one gets a penalty */}
              <button 
                className="button is-primary" 
                onClick={() => submitPenalty(activeCondition)}
              >
                Confirm
              </button>
              <button className="button" onClick={closeModal}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      }

    </div>
  );
}

export default PenaltyTracker;
