// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from '@firebase/firestore';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


export const firebaseConfig = {
  apiKey: "AIzaSyBkbe0-0Yq5ydKw5kl6LGemAcRDRoRDdbM",
  authDomain: "groundhog-day-games.firebaseapp.com",
  databaseURL: "https://groundhog-day-games-default-rtdb.firebaseio.com",
  projectId: "groundhog-day-games",
  storageBucket: "groundhog-day-games.appspot.com",
  messagingSenderId: "933734832910",
  appId: "1:933734832910:web:492ab910f96c9b5242955f"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);

if (window.location.hostname === "localhost") {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9000');
  connectFunctionsEmulator(functions, 'localhost', 5001)
};

export default firebaseApp;