import { HORSE_RACE_WAGERS, suitColorMap } from '../components/games/horse-race/constants';
import { Player, HorseRacePlayerBet, HorseRaceRoundResults, Tournament } from '../types';
import { updatePlayerProperties } from './players';
import { updateTournamentProperties } from './tournaments';

export const placeHorseRaceBet = async (player: Player, bet: HorseRacePlayerBet) => {
  const updates = { 
    [`horseRaceBets.${bet.raceNumber}`]: {
      ...bet,
      choices: bet.choices, // clear any previous choices
    }
  };

  try {
    await updatePlayerProperties(player.id, updates);
  } catch (e) {
    alert(e);
  }
}

export const updateRaceProperties = async (tournamentId: string, raceNumber: number, newStatus: string, results: HorseRaceRoundResults) => {
  try {
    const updates = {
      [`horseRace.${raceNumber}.status`]: newStatus,
      [`horseRace.${raceNumber}.results`]: results,
    };

    await updateTournamentProperties(tournamentId, updates);
  } catch (e) {
    alert(e);
  }
}

export const isBetIncorrect = (betDetails: HorseRacePlayerBet, raceResults: HorseRaceRoundResults) => {
  const { 
    suitLoses,
    colorFirst,
    colorFirstAndSecond,
    suitFirstOrSecond,
    colorSecond,
    suitFirst,
    rapido,
    colorThird,
    colorFourth,
    suitSecond,
    suitThird,
    suitFourth,
  } = betDetails.choices;

  let incorrect = false;

  if (suitLoses && suitLoses === raceResults.firstPlace) incorrect = true;
  if (colorFirst && colorFirst !== suitColorMap[raceResults.firstPlace]) incorrect = true;
  if (colorFirstAndSecond && (colorFirstAndSecond !== suitColorMap[raceResults.firstPlace] || colorFirstAndSecond !== suitColorMap[raceResults.secondPlace])) incorrect = true;
  if (suitFirstOrSecond && !(suitFirstOrSecond === raceResults.firstPlace || suitFirstOrSecond === raceResults.secondPlace )) incorrect = true;
  if (colorSecond && colorSecond !== suitColorMap[raceResults.secondPlace]) incorrect = true;
  if (colorThird && colorThird !== suitColorMap[raceResults.thirdPlace]) incorrect = true;
  if (colorFourth && colorFourth !== suitColorMap[raceResults.fourthPlace]) incorrect = true;
  if (rapido && rapido !== raceResults.firstOut) incorrect = true;
  if (suitFirst && suitFirst !== raceResults.firstPlace) incorrect = true;
  if (suitSecond && suitSecond !== raceResults.secondPlace) incorrect = true;
  if (suitThird && suitThird !== raceResults.thirdPlace) incorrect = true;
  if (suitFourth && suitFourth !== raceResults.fourthPlace) incorrect = true;

  return incorrect;
}

export const calculateNewScore = (betDetails: HorseRacePlayerBet, betIsIncorrect: boolean, player: Player) => {
  const stake = betDetails.stakedPoints;
  const wager = HORSE_RACE_WAGERS[betDetails.wager];
  const odds = wager.numericOdds;
  let newScore, pointChange;

  if (betIsIncorrect) {
    newScore = player.score.HorseRace - stake;
    pointChange = -stake;
  }
  else {
    const winnings = stake * odds;
    newScore = player.score.HorseRace + winnings;
    pointChange = winnings;
  }

  return { newScore, pointChange };
}

export const calculateRaceResults = async (tournament: Tournament, raceNumber: number, allPlayers: Player[]) => {
  const raceResults = tournament.horseRace[raceNumber].results;

  allPlayers.forEach(async player => {
    const betDetails = player.horseRaceBets[raceNumber];
    // ignore players without bets 
    if (!betDetails) return;

    const betIsIncorrect: boolean = isBetIncorrect(betDetails, raceResults);
    const { newScore, pointChange } = calculateNewScore(betDetails, betIsIncorrect, player);

    // update player Horse Race score
    try {
      const newPlayerProperties = {
        'score.HorseRace': newScore,
        [`horseRaceBets.${raceNumber}.correct`]: !betIsIncorrect,
        [`horseRaceBets.${raceNumber}.pointChange`]: pointChange,
      };

      await updatePlayerProperties(player.id, newPlayerProperties);
  
    } catch (e) {
      alert(e);
    }

  });

}