import React from 'react';
import { Switch, useLocation, Route } from 'react-router-dom';
import PrivateRoute from '../../auth/private-route';
import HorseRaceRules from '../../../rules/horse-race.mdx';
import HorseRaceBidSheet from './horse-race-bid-sheet';
import HorseRaceReview from './horse-race-review';


const HorseRace = () => {
  const location = useLocation();

  function isTabActive(path) {
    if (location.pathname === path) return 'is-active';
    return '';
  }

  return (
    <div className='section'>
      <h2 className="title is-4">Horse Race</h2>

      <div className="tabs is-fullwidth">
        <ul>
          <li className={`${isTabActive('/horse-race')}`}>
            <a href='/horse-race'>Rules</a>
          </li>
          <li className={`${isTabActive('/horse-race/place-bets')}`}>
            <a href='/horse-race/place-bets'>Place bets</a>
          </li>
          <li className={`${isTabActive('/horse-race/review-bets')}`}>
            <a href='/horse-race/review-bets'>Review</a>
          </li>
        </ul>
      </div>

      <Switch>
        <Route exact path='/horse-race'>
          <div className="content">
            <HorseRaceRules />
          </div>
        </Route>
        <PrivateRoute path='/horse-race/place-bets'>
          <HorseRaceBidSheet/>
        </PrivateRoute>

        <PrivateRoute path='/horse-race/review-bets'>
          <HorseRaceReview />
        </PrivateRoute>
      </Switch>

    </div>
  );
}

export default HorseRace;
