export * from './wisest-wizard-constants';

// games
export const GameNames = {
  BeerBall: 'Beer Ball',
  BeerioKartRelay: 'Beerio Kart Relay',
  BoatRace: 'Boat Race',
  CivilWar: 'Civil War',
  FlipTacToe: 'Flip Tac Toe',
  FlipCupSurvivor: 'Flip Cup Survivor',
  HorseRace: 'Horse Race',
  HungryHungryHippos: 'Hungry Hungry Hippos',
  WisestWizard: 'Wisest Wizard',
  ShotRoulette: 'Shot Roulette',
  Medusa: 'Medusa',
  IrreplaceABall: 'Irreplace-a-Ball',
  Mournival: 'Mournival',
}

// GHD 2022 games
export const GHD_2022_GAMES = [
  GameNames.BeerBall,
  GameNames.BeerioKartRelay,
  GameNames.FlipTacToe,
  GameNames.FlipCupSurvivor,
  GameNames.HorseRace,
  GameNames.HungryHungryHippos,
  GameNames.ShotRoulette,
  GameNames.IrreplaceABall,
  GameNames.Mournival,
  GameNames.WisestWizard,
  GameNames.CivilWar,
];
