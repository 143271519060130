
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.56,"time":33600,"words":112}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "medusa"
    }}>{`Medusa`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Be the last player standing.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Everyone sits around a table with a cup of their chosen beverage and a ping pong ball. Each player bows their head while another player (or everyone together) counts down from 3. On the count of 1, everyone needs to look up and stare into the eyes of another player at the table.`}</p>
    <p>{`If two players make eye contact, both players need to shout “MEDUSA!”, finish their drink, and bounce the ping pong ball into it. The first player to do so continues playing while the other is eliminated.`}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`The last player standing advances to the final game.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "medusa",
    level: 3,
    title: "Medusa",
    children: [
        
      ]
  }
]

export const frontMatter = {}

