
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.515,"time":90900,"words":303}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "flip-tac-toe"
    }}>{`Flip Tac Toe`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Win the most games of Flip Cup - Tic Tac Toe.  `}</p>
    <h4 {...{
      "id": "setup"
    }}>{`Setup`}</h4>
    <p>{`Using some kind of colored tape, make a 3x3 tic-tac-toe board on a table.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Players will be randomly divided into even teams and form a line on opposite sides of the table.
Opposing team members face off one at a time, starting with a cup turned upside down on the edge of the table and using their fingers to try to flip the cup right side up.
When a player successfully flips a cup over, they may place it in any spot on the board.  `}</p>
    <p>{`There are two rounds:  `}</p>
    <h5 {...{
      "id": "round-1"
    }}>{`Round 1`}</h5>
    <p>{`After a player places a cup on the board, they move to the back of the line and the next player on their team steps in to play.  `}</p>
    <p>{`Once one team wins a game of tic-tac-toe, reset the board and begin a new game starting with the next players in line for each team.  `}</p>
    <p>{`Continue until everyone on each team has played at least once.  `}</p>
    <h5 {...{
      "id": "round-2"
    }}>{`Round 2`}</h5>
    <p>{`Once a player flips the cup over, they can place it in a spot on the board, grab another cup, and repeat until one player wins the game of Tic Tac Toe.  `}</p>
    <p>{`When a game ends, the next players in line for each team will face off.  `}</p>
    <p>{`Continue until everyone on each team has played.  `}</p>
    <p>{`If there are an uneven number of players, someone on the team with more players can play again for no additional points.  `}</p>
    <p><strong parentName="p">{`Example play:`}</strong><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=k9UGQhz3vPg"
      }}>{`https://www.youtube.com/watch?v=k9UGQhz3vPg`}</a>{`  `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <h5 {...{
      "id": "round-1-1"
    }}>{`Round 1`}</h5>
    <p>{`Whichever team won more games overall earns 50 points.  `}</p>
    <h5 {...{
      "id": "round-2-1"
    }}>{`Round 2`}</h5>
    <ul>
      <li parentName="ul">{`Each player (on either team) that wins an individual game earns 25 points.`}</li>
      <li parentName="ul">{`Whichever team won more games overall earns 25 points.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "flip-tac-toe",
    level: 3,
    title: "Flip Tac Toe",
    children: [
        
      ]
  }
]

export const frontMatter = {}

