
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.73,"time":43800,"words":146}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Enervate - 0 SP`}</strong><br parentName="p"></br>{`
`}{`When you have 0 SP, you may choose up to 2 wizards.
They lose 1 SP each and you gain 1 SP from each.`}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`Chain Lightning - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose up to 3 wizards. They all lose 1 HP.`}</p>
    <p><strong parentName="p">{`Nature’s Grasp - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard.
They cannot move from their current location until they level up or until you cast another spell.`}</p>
    <p><strong parentName="p">{`Whirlwind- 2 SP`}</strong><br parentName="p"></br>{`
`}{`Choose up to three wizards.
When you say the word “whirlwind” they must spin around in a complete circle 3 times.`}</p>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Force of Nature - 3 SP`}</strong><br parentName="p"></br>{`
`}{`You channel nature’s fury to empower your spells.
For the next 30 seconds, you can choose to either double the targets or double the effects of your spells.
Doubled effects do not apply to the duration of Nature’s Grasp.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

