
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.79,"time":107400,"words":358}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "beerio-kart-relay"
    }}>{`Beerio Kart Relay`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Have your team complete three laps of Mario Kart with the fastest time.  `}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Players will be randomly split into teams of 2–3 and pick a single driver per team.`}</p>
    <ul>
      <li parentName="ul">{`Each player on the team must complete at least one full lap`}</li>
      <li parentName="ul">{`Each player must start and finish an approved beverage during their lap`}</li>
      <li parentName="ul">{`Drivers must be at a complete stop while drinking (no drinking and driving/ coasting!)`}</li>
    </ul>
    <p>{`Teams of 2 can either decide how to split the third lap (and drink) between themselves OR another person (randomly selected from a group of volunteers) can act as their third team member and play again for no points.  `}</p>
    <p><strong parentName="p">{`Approved beverages:`}</strong><br parentName="p"></br>{`
`}{`You may choose a can of beer, sparkling cocktail (e.g. strawberita), or sparkling water / juice as your beverage.  `}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`If all teams are competing in a single race, scoring will be determined by the place each team finishes in.  `}</p>
    <p>{`If teams are competing across multiple races, each team will have a designated time keeper (someone who is not competing in the current race). `}</p>
    <p>{`All time keepers will start a timer when the race begins and stop the timer when the team they are tracking completes their final lap.
The four teams with the fastest times (across all heats) will earn points.`}</p>
    <p><strong parentName="p">{`Note:`}</strong><br parentName="p"></br>{`
`}{`Some races in Mario Kart will end early if everyone but the last driver has finished, which can skew the team times.
If teams are competing across multiple races, we will have an additional driver who is not controlled by anyone in order to let all teams finish.  `}</p>
    <p><strong parentName="p">{`1st place team:`}</strong>{` 100 points each`}<br parentName="p"></br>{`
`}<strong parentName="p">{`2nd place team:`}</strong>{` 75 points each`}<br parentName="p"></br>{`
`}<strong parentName="p">{`3rd place team:`}</strong>{` 50 points each`}<br parentName="p"></br>{`
`}<strong parentName="p">{`4th place team:`}</strong>{` 25 points each  `}</p>
    {
      /* #### Heats
      We will have two heats with 3 teams of 2-3 people in each heat.
      Option 1: 4 people play twice (for no additional points).
      Heat 1: 3v3v3
      Heat 2: 3v3v3
      Option 2: Some teams have 2 players instead of 3. Those teams can decide how to split the extra lap and drink.
      Heat 1: 3v2v2
      Heat 2: 3v2v2 */
    }
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "beerio-kart-relay",
    level: 3,
    title: "Beerio Kart Relay",
    children: [
        
      ]
  }
]

export const frontMatter = {}

