import {
  updateProfile, User,
} from "firebase/auth";
import { handleUpdateDoc } from './firestore-helpers';
import { db } from '../firebase';
import { doc } from "@firebase/firestore";
import { getDoc } from "firebase/firestore";

export const updateDisplayName = async (user: User, name: string) => {
  try {

  } catch (error) {
    //TODO: handle error
    alert(error);
  }

  updateProfile(user, {
    displayName: name,
  });

  handleUpdateDoc(doc(db, `user-details/${user.uid}`), {
    displayName: name,
  });
}

export const updateUserDetails = async (user: User, updates: any) => {
  try {
    handleUpdateDoc(doc(db, `user-details/${user.uid}`), updates);
  } catch (error) {
    //TODO: handle error
    alert(error);
  }
}

export const getUserDetails = async (user: User) => {
  try {
    const docSnap = await getDoc(doc(db, 'user-details', user.uid));
    if (docSnap.exists()) return docSnap.data();
    return null;
  } catch (error) {
    //TODO: handle error
    alert(error);
  }
}
