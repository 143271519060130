import React, { useState } from 'react';
import { deleteNotification } from '../../../lib/notifications';
import { PlayerNotification } from '../../../types';
import { useNotifications } from '../../data-providers/notifications-provider';

interface WizardNotificationsProps {
  notifications: PlayerNotification[];
}

const WizardNotifications = ({notifications} : WizardNotificationsProps) => {
  const [showModal, setShowModal] = useState<boolean>();

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const deleteMessage = async (messageId) => {
    deleteNotification(messageId);
  }

  const clearAllMessages = async () => {
    notifications.forEach(n => {
      if (n.id) deleteNotification(n.id);
    })
  }


  return (
    <div className=''>

      <article className="message is-small is-info mt-2">
        <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
          <p className=''>
            You have unread notifications
          </p>
          <button className='button is-small is-info' onClick={toggleModal}>
            More
          </button>
        </div>
      </article>

      <div className={`modal ${showModal ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Notifications</p>
          </header>
          <section className="modal-card-body">
            {notifications.map(notification => {

              return (
                <div className="notification is-info is-light">
                  <button className="delete" onClick={() => deleteMessage(notification.id)}/>
                  {notification.message}
                </div>
              )
            })}

          </section>
          <footer className="modal-card-foot">
            <button 
              className="button is-primary" 
              onClick={clearAllMessages}
            >
              Clear all
            </button>
            <button className="button" onClick={toggleModal}>
              Close
            </button>
          </footer>
        </div>
      </div>

    </div>
  );
}

export default WizardNotifications;

