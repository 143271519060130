import React, { useState } from 'react';
import { WizardConditions } from '../../../constants';
import { updatePlayerProperties } from '../../../lib/players';
import { reincarnateWizard } from '../../../lib/wizards';
import { WizardClass } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';

import BardDescriptionMDX from './class-descriptions/bard.mdx';
import ClericDescriptionMDX from './class-descriptions/cleric.mdx';
import DruidDescriptionMDX from './class-descriptions/druid.mdx';
import HydromancerDescriptionMDX from './class-descriptions/hydromancer.mdx';
import NecromancerDescriptionMDX from './class-descriptions/necromancer.mdx';
import PyronmancerDescriptionMDX from './class-descriptions/pyromancer.mdx';

const CLASSES: WizardClass[] = [
  {
    name: 'Bard',
    id: 1,
    description: 'Class description TBD',
  },
  {
    name: 'Cleric',
    id: 2,
    description: 'Class description TBD',
  },
  {
    name: 'Druid',
    id: 3,
    description: 'Class description TBD',
  },
  {
    name: 'Hydromancer',
    id: 4,
    description: 'Class description TBD',
  },
  {
    name: 'Necromancer',
    id: 5,
    description: 'Class description TBD',
  },
  {
    name: 'Pyromancer',
    id: 6,
    description: 'Class description TBD',
  },
];

const WizardClasses = () => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [confirmClassModalOpen, setConfirmClassModalOpen] = useState<boolean>();
  const [chosenClass, setChosenClass] = useState<number | null>(null);
  const { currentPlayer, allPlayers } = usePlayerData();
  const currentWizard = currentPlayer?.wizard;
  const reincarnations = currentWizard && currentWizard.reincarnations || [];
  const hitPoints = currentWizard && currentWizard.hitPoints || 0;
  const currentClass = currentWizard && currentWizard.class;

  const toggleExpandedRow = (classId) => {
    if (expandedRow === classId) setExpandedRow(null);
    else setExpandedRow(classId);
  }

  const handleChooseClass = (className) => {
    setChosenClass(className);
    //pop up confirmation modal
    setConfirmClassModalOpen(true);
  }

  const handleConfirmClass = async () => {
    if (!currentPlayer) return;
    // TODO: increment class choice counter
    const updates = {
      'wizard.class': chosenClass,
      'wizard.startingClass': chosenClass,
    }
    await updatePlayerProperties(currentPlayer.id, updates);
    setConfirmClassModalOpen(false);
  }

  const handleCancelSelection = () => {
    setChosenClass(null);
    setConfirmClassModalOpen(false);
  }

  const getClassDescription = (className) => {
    switch (className) {
      case 'Bard':
        return <BardDescriptionMDX />;
      case 'Cleric':
        return <ClericDescriptionMDX/>;
      case 'Druid':
        return <DruidDescriptionMDX/>;
      case 'Hydromancer':
        return <HydromancerDescriptionMDX/>;
      case 'Necromancer':
        return <NecromancerDescriptionMDX/>;
      case 'Pyromancer':
        return <PyronmancerDescriptionMDX/>;
    }
  }

  const handleReincarnation = async (className: string) => {
    if (!currentPlayer) return;

    await reincarnateWizard(currentPlayer, className, allPlayers);
  }

  const isReincarnateDisabled = (className) => {
    if (!currentWizard) return true;
    if (className === currentWizard.class) return true;
    if (className === currentWizard.startingClass) return true;
    if (reincarnations.includes(className)) return true;
    if (playerIsReanimated && currentWizard?.hitPoints < 1) return true;

    return false;
  }

  const wizardConditions = currentWizard?.conditions || {};
  const playerIsReanimated = Object.keys(wizardConditions).includes(WizardConditions.Reanimated);

  return (
    <>
      {CLASSES.map((c) => (
        <div className="card">
          <header className="card-header is-align-items-center">
            <p className="card-header-title">
              <button
                className="card-header-icon button"
                aria-label="more options"
                onClick={() => toggleExpandedRow(c.id)}
              >
                <span className="icon">
                  <i className={`fas fa-angle-${expandedRow === c.id ? 'up' : 'down'}`} aria-hidden="true"/>
                </span>
              </button>
              {c.name}
            </p>

            {hitPoints < 1 && currentClass &&
              <>
                <button
                  className='button is-primary mr-2'
                  onClick={() => handleReincarnation(c.name)}
                  disabled={isReincarnateDisabled(c.name)}
                >
                  Reincarnate
                </button>
              </>

            }

            {hitPoints >= 0 && !currentClass &&
              <button
                className='button is-primary mr-2'
                onClick={() => handleChooseClass(c.name)}
                disabled={(currentClass && hitPoints > 0) || reincarnations.includes(c.name)}
              >
                Choose class
              </button>
            }

          </header>

          {expandedRow == c.id &&
            <div className="card-content">
              <div className="content">
                {getClassDescription(c.name)}
              </div>
            </div>
          }

        </div>
      ))}

      <div className={`modal ${confirmClassModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Are you sure?</p>
            <button className="delete" aria-label="close" onClick={handleCancelSelection}/>
          </header>
          <section className="modal-card-body">
            Once you choose your class, you will not be able to change it later. Choose wisely!
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary" onClick={handleConfirmClass}>
              Confirm
            </button>
            <button className="button" onClick={handleCancelSelection}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

export default WizardClasses;
