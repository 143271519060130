
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.505,"time":90300,"words":301}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "civil-war"
    }}>{`Civil War`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Be the last team standing.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Civil War is similar to Beer Pong. Teams of 2–3 players each will face off.  `}</p>
    <p>{`Each player has a rack of 3 or 6 cups and shoots ping pong balls in a rapid fashion at their choice of the opposing team players’ cups.
If a ball lands in a player’s cup, that player must drink the contents and remove the cup from the table.  `}</p>
    <p>{`When a player loses all of their cups, they are eliminated and can no longer shoot. When all players on a team have been eliminated, the game is over.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Drink before you shoot:`}</strong>{` If a ball lands in your cup, you must drink the cup before you keep shooting`}</li>
      <li parentName="ul"><strong parentName="li">{`No turns:`}</strong>{` Any team / player can shoot at any time (unless they have to drink first).`}</li>
      <li parentName="ul"><strong parentName="li">{`Passing:`}</strong>{` Players can pass balls off to their teammates (if for instance their cup is made and they have to drink)`}</li>
      <li parentName="ul"><strong parentName="li">{`Rebounds:`}</strong>{` No balls are off limits for rebounds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Same cupping:`}</strong>{` If a team makes two balls in the same cup at the same time, that player is immediately eliminated.`}</li>
      <li parentName="ul"><strong parentName="li">{`Shot blocking:`}</strong>{` Any player can block any shot`}</li>
    </ul>
    <h4 {...{
      "id": "teams"
    }}>{`Teams`}</h4>
    <p>{`Players will split into teams of 2–3 and compete in 2v2 or 3v3 matches.  `}</p>
    <p>{`If there are an uneven number of players that results in a 2v3 matchup, another player (randomly selected from a group of volunteers) can join the team of 2 to create an even matchup.  `}</p>
    {
      /* #### Heats
      There will be 3 heats. 13 players will split into 6 teams of 2-3 people.
      Heat 1: 2v2
      Heat 2: 2v2
      Heat 3: 3v2
      Heat 1: 3v3
      Heat 2: 3v3
      Heat 3: 3v2 */
    }
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`Winning teams earn 50 points.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "civil-war",
    level: 3,
    title: "Civil War",
    children: [
        
      ]
  }
]

export const frontMatter = {}

