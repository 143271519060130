import React from 'react';
import { WizardConditions } from '../../../../constants';
import { playerHasCondition } from '../spell-helpers';
import { Player } from '../../../../types';
import Countdown from '../../../countdown';
import { deletePlayerProperty } from '../../../../lib/players';

interface EmpowermentTimerProps {
  activePlayer: Player;
}

const EmpowermentTimer = ({ activePlayer }: EmpowermentTimerProps) => {
  const activeWizard = activePlayer.wizard;
  const activeWizardConditions = activeWizard.conditions;

  const handleEmpoweredOver = async () => {
    if (!activePlayer) return;
    await deletePlayerProperty(activePlayer, `wizard.conditions.${WizardConditions.Empowered}`);
  }

  return (
    <div className=''>

      {activePlayer && playerHasCondition(activePlayer, WizardConditions.Empowered) &&
        
        <article className="message is-small is-warning">
          <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
            <p className=''>
              You are <strong>empowered</strong> for&nbsp;
              <strong>
                <Countdown 
                  expirationTime={activeWizardConditions[WizardConditions.Empowered].expirationTime}
                  onTimerFinish={handleEmpoweredOver}
                />
              </strong>
            </p>
          </div>
        </article>
      }

    </div>
  );
}

export default EmpowermentTimer;
