/*
 * Takes an object with individual game scores and returns the total
 */
export function getTotalScore(gameScores = {}) {
  const reducer = (prev, current) => prev + current;
  const scoreArray = Object.values(gameScores);

  if (scoreArray.length > 0) return scoreArray.reduce(reducer);
  return 0;
}

// removes whitespace and converts string to title case to use as an ID
export function convertStringToId(text = '') {
  const titleCase = text.toLowerCase().replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
  );
  return titleCase.replace(/[^0-9a-z]/gi, ''); //remove non alphanumeric
}

export function roundTo2Decimals(num = 0) {
  return Math.round(((num + Number.EPSILON) * 100) / 100);
}

// get random integer between 0 and max value
export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function stripWhitespace(str = '') {
  return str.replace(/\s/g, '');
}

/*
 * Returns the current day, month and year
 * @returns {currentDay, currentMonth, currentYear} the current day, month, and year
 */

export function getCurrentDate() {
  const now = new Date(Date.now());
  const currentDay = now.getDate();
  const currentMonth = (1 + now.getMonth());
  const currentYear = now.getFullYear();

  return { currentDay, currentMonth, currentYear };
}


/*
 * Returns a debounced version of a function, which will
 * be run only "nnn" milliseconds
 * @param {function} fn - Function to debounce
 * @param {number} ms - milliseconds to defer execution
 * @returns {function} the debounced function
 */
export function debounce(fn, milliseconds = 100) {
  let timer;

  return () => {
    if (timer) window.clearTimeout(timer);

    timer = window.setTimeout(() => {
      fn();
      timer = null;
    }, milliseconds);
  };
}