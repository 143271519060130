
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.69,"time":101400,"words":338}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Fight Fire with Fire - 0 SP`}</strong><br parentName="p"></br>{`
`}{`When you are attacked, you have a 10 second window to use this ability which lets you cast Fireball back at your attacker without spending any SP.
The target then has 45 seconds to prepare their own shot and drink it.`}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`Fireball - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Hand a wizard a shot of liquor (or some unpleasant liquid for sober players).
They must consume it within 30 seconds.
If they do not finish the drink in time, they lose 3 HP.  `}</p>
    <p><strong parentName="p">{`Scorched Earth - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Designate an area (e.g. a corner of the room, a hallway, a doorway).
For one game, whenever someone enters that area, they must constantly hop from one foot to the other to avoid getting burned.
If they don’t, they lose 1 HP.
If their feet are not touching the ground (i.e. they are on a piece of furniture), they are safe.`}</p>
    <p><strong parentName="p">{`Agni Kai - 2 SP`}</strong><br parentName="p"></br>{`
`}{`You may challenge any other wizard to a special duel.
The duel involves a 1 on 1, sudden death version of one of the following games:`}</p>
    <ul>
      <li parentName="ul">{`Beer Pong: each wizard has only one cup. `}</li>
      <li parentName="ul">{`Flip Cup: the first wizard to flip their cup wins. `}</li>
      <li parentName="ul">{`Stack Cup: the first wizard to bounce a ball into their cup wins.`}</li>
      <li parentName="ul">{`BOAT Race: the first wizard to chug a canned beverage wins.
The winner gains 10 GHD points and the loser loses 10 GHD points and 1 HP.
You may not cast this while you or your opponent are playing a game.
You and your opponent cannot be targeted while dueling.`}</li>
    </ul>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Heat Things Up - 3 SP`}</strong><br parentName="p"></br>{`
`}{`Your fiery temperament causes events to spiral out of control.
You may either:  `}</p>
    <ol>
      <li parentName="ol">{`Raise the stakes of any game that has not started yet (excluding Horse Race / Wisest Wizard). All scores from this game are `}<em parentName="li">{`doubled`}</em>{`. `}</li>
      <li parentName="ol">{`Unleash a wave of wild magic, giving each player a random condition (Invisible, Silenced, Empowered, etc.).`}</li>
    </ol>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

