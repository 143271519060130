
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.875,"time":52500,"words":175}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Equivalent Exchange - 0 SP`}</strong><br parentName="p"></br>{`
`}{`You may convert 1 HP into 1 SP or vice versa.`}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`Curse of Vulnerability - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard.
They must tape a solo cup to the top of their staff until they level up.
Any time another wizard launches/places a ping pong ball into their cup, the target wizard loses 1 HP.`}</p>
    <p><strong parentName="p">{`Ritual Sacrifice - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard.
You lose 3 HP and they lose 10 GHD points.`}</p>
    <p><strong parentName="p">{`Drain Life - 2 SP`}</strong><br parentName="p"></br>{`
`}{`Choose 3 wizards.
They each lose 1 HP and you gain 1 HP from each.`}</p>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Raise Dead - 3 SP`}</strong><br parentName="p"></br>{`
`}{`If another wizard dies and has not yet reincarnated, you may bring them back to life at 3 HP.
Until they are killed, they are under your control and you may cast any of their spells using their SP. `}</p>
    {
      /* If the controlled wizard has SP remaining, their SP are used to cast spells.  */
    }
    {
      /* If not, your SP are used instead.  */
    }
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

