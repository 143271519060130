
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.405,"time":84300,"words":281}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Hail Stones - 0 SP`}</strong><br parentName="p"></br>{`
`}{`During any game involving ping pong balls, you may summon 2 extra balls that you can use according to the game rules.  `}</p>
    <p>{`In games where players take turns (such as Beer Ball, Beer Pong, etc.), this might mean taking an extra turn
or using a ball to tap the table early in Beer Ball.`}</p>
    <p>{`In games without turns (such as Hungry Hungry Hippos or Flip Cup), this might mean using one of your extra balls
instead of chasing one that rolled away.`}</p>
    <p>{`You can only use each extra ball once per game, there are no rebounds / rollbacks allowed.`}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`Ice Barrier - 1 SP`}</strong><br parentName="p"></br>{`
`}{`You surround yourself with a shield of ice that can absorb 1 enemy spell before shattering.`}</p>
    <p><strong parentName="p">{`Frost Lance - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard. You launch a sharp icicle at them. They lose 2 HP.  `}</p>
    <p><strong parentName="p">{`Frostbite - 2 SP`}</strong><br parentName="p"></br>{`
`}{`Choose up to 3 other wizards and fill a cup with ice cubes. Each wizard must put an ice cube in their mouth until it melts.  `}</p>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Blizzard - 3 SP`}</strong><br parentName="p"></br>{`
`}{`All other wizards must stand together in a cluster and hold out a cup. (Alternatively, everyone can write their name on a cup and place their cups somewhere in a cluster.)  `}</p>
    <p>{`Fill your hands (or a cup) with ping pong balls (1 for each player) and stand 10 feet outside the cluster. You may throw your ping pong balls into the group of cups (all at once or one at a time). For each ball that lands in a wizard’s cup, that wizard loses 5 GHD points. This may not be cast during games.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

