
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.04,"time":62400,"words":208}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "boat-race"
    }}>{`B.O.A.T Race`}</h3>
    <h5 {...{
      "id": "boat--beer-on-a-table"
    }}><em parentName="h5">{`B.O.A.T = Beer on a table`}</em></h5>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Be the first team to finish all their drinks.`}</p>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Players will randomly be split into two even teams and line up on opposite sides of a long table (or two separate tables) with a full solo cup of their chosen beverage.  `}</p>
    <p>{`If there is an uneven number of players, the team with less players will have an extra cup to drink that they can distribute however they like amongst any players on their team.  `}</p>
    <p>{`When the race begins, players on each team will start chugging their drinks (going down the line one at a time).  `}</p>
    <p><strong parentName="p">{`Each player must follow two rules during their turn:`}</strong></p>
    <ul>
      <li parentName="ul">{`Before drinking, you must flick your cup.`}</li>
      <li parentName="ul">{`After drinking, you must turn the cup over on your head (or the table) to prove that you have finished.  `}</li>
    </ul>
    <p>{`Once they have done this, the next player in line may flick their cup and start drinking.
If you do not flick your cup or you spill your beer while drinking, you must wait 5 seconds (everyone not drinking will count down) before the next person can begin drinking.`}</p>
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`Each player on the winning team earns 50 points.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "boat-race",
    level: 3,
    title: "B.O.A.T Race",
    children: [
        
      ]
  }
]

export const frontMatter = {}

