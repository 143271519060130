import { db } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  arrayUnion,
  DocumentData,
  setDoc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { handleAddDoc, handleSetDoc, handleUpdateDoc, } from './firestore-helpers';
import { getUserDetails, updateUserDetails } from './user-details';
import { NewPlayer, Tournament, UserDetails } from '../types';
import { User } from '@firebase/auth';
import { createPlayer } from './players';
import { stripWhitespace } from './utils';


export function makeJoinCode(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function createTournament(user: User|null|undefined, tournamentName: string) {
  //TODO: handle undefined user?
  if (!user) return;

  // create tournament
  const data: Tournament = {
    // TODO: ensure fully unique join codes
    joinCode: makeJoinCode(5),
    name: tournamentName,
    hosts: [user.uid],
    players: [],
    events: [],
    active: true,
    status: 'NotStarted',
    conditions: [],
    horseRace: {},
  };

  try {
    // TODO: set any other active tournaments to inactive
    const q = query(collection(db, 'tournaments'), where('active', '==', true), where('players', 'array-contains', user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
      setDoc(doc(db, 'tournaments',  docSnap.id), { active: false }, { merge: true });
    });

    const tournament = await handleAddDoc('tournaments', data);
    // update active tournament in user details
    await setDoc(doc(db, 'user-details', user.uid), {
      activeTournament: tournament.id,
    }, { merge: true });
    const userDetails = await getUserDetails(user);
    // create host player
    const host: NewPlayer = createPlayer(tournament.id, user, userDetails, true);
    const playerId = `${stripWhitespace(userDetails?.displayName)}${user.uid}${data.joinCode}`;
    await handleSetDoc('players', playerId, host);

    // add host as player in tournament
    await handleUpdateDoc(doc(db, `tournaments/${tournament.id}`), {
      players: arrayUnion(user.uid),
    });

    // redirect to tournament management page
  } catch (error) {
    // TODO: do something
    alert(error);
  }

}

export async function joinTournament(user: User|null|undefined, joinCode: string) {
  //TODO: handle undefined user?
  if (!user) return;

  // 1. Check if user already has a Player id in the existing tournament. If so, use that.
  //  This way players can rejoin tournaments if needed since they can only view one at a time.

  try {
    // get tournament by joinCode
    const q = query(collection(db, 'tournaments'), where('joinCode', '==', joinCode));
    const snapshot = await(getDocs(q));
    const results: DocumentData[] = [];
    snapshot.forEach((doc) => {
      results.push({id: doc.id, ...doc.data()});
    });

    const playerAlreadyInTournament = results.some(x => x.players.includes(user.uid));

    // 2. If user does not exist in tournament, create a new Player object for them
    const tournament = results.shift();
    if (!playerAlreadyInTournament && tournament) {
      await setDoc(doc(db, 'user-details', user.uid), {
        activeTournament: tournament.id,
      }, { merge: true });
      const userDetails = await getUserDetails(user);
      const newPlayer = createPlayer(tournament.id, user, userDetails, false);
      // TODO: After GHD, go back to just adding a doc without a custom ID. The custom ID is only used for easy DB fixes during GHD.
      const playerId = `${stripWhitespace(userDetails?.displayName)}${user.uid}${tournament.joinCode}`;
      await handleSetDoc('players', playerId, newPlayer);

      // 3. Add player to tournament
      await handleUpdateDoc(doc(db, `tournaments/${tournament.id}`), {
        players: arrayUnion(user.uid),
      });
    } else if (tournament) {
      // 4. If user already exists in tournament:
      // - set this tournamenent as their active one
      // - continue to redirect to scoreboard
      await updateUserDetails(user, {
        activeTournament: tournament.id,
      });
    }

  } catch (error) {
    alert(error);
  }

}

// Deprecated
// export async function getActiveTournament(user: User|null|undefined) {
//   if (!user) return null;
//   const userDetails = await getUserDetails(user) as UserDetails;
//   const activeTournamentId = userDetails.activeTournament;

//   const queryDoc = await getDoc(doc(db, 'tournaments', activeTournamentId));
//   const activeTournament = { id: queryDoc.id, ...queryDoc.data() };

//   return activeTournament as Tournament;
// }

export async function updateTournamentStatus(tournamentId: string, newStatus: string) {
  try {
    await handleUpdateDoc(doc(db, `tournaments/${tournamentId}`), {
      status: newStatus,
    });
  } catch (e) {
    alert(e);
  }
}

export async function updateTournamentConditions(tournamentId: string, updates) {
  try {
    await handleUpdateDoc(doc(db, `tournaments/${tournamentId}`), {
      conditions: arrayUnion(updates),
    });
  } catch (e) {
    alert(e);
  }
}

export async function updateTournamentProperty(tournamentId: string, property: string, update) {
  try {
    await handleUpdateDoc(doc(db, `tournaments/${tournamentId}`), {
      [property]: update,
    });
  } catch (e) {
    alert(e);
  }
}


export async function updateTournamentProperties(tournamentId: string, updates) {
  try {
    await updateDoc(doc(db, 'tournaments', tournamentId), updates);
  } catch (e) {
    alert(e);
  }
}