import React, { useState, useEffect } from 'react';
import { getTotalScore, roundTo2Decimals } from '../../../lib/utils';
import { placeHorseRaceBet } from '../../../lib/horse-race';
import { HorseRacePlayerBet } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import { HORSE_RACE_WAGERS, MaxHorseRaceWager, MinHorseRaceWager } from './constants';
import { useTournamentData } from '../../data-providers/tournament-provider';

interface ChoiceSelectProps {
  id: string;
  handleSelect: (id: string, value: string) => void;
  value?: string;
  disabled?: boolean;
}
export const SuitSelect = ({ id, handleSelect, value, disabled = false }: ChoiceSelectProps) => {
  const SUIT_OPTIONS = [
    'Club',
    'Diamond',
    'Heart',
    'Spade',
  ];
  return (
    <div className="select is-medium is-fullwidth">
      <select onChange={(e) => handleSelect(id, e.target.value)} value={value} required disabled={disabled}>
        <option value=''>select suit</option>
        {SUIT_OPTIONS.map(suit => (
          <option value={suit}>
            {suit}
          </option>
        ))}
      </select>
    </div>
  )
}

export const ColorSelect = ({ id, handleSelect, value, disabled = false }: ChoiceSelectProps) => {
  const COLOR_OPTIONS = ['red', 'black'];
  return (
    <div className="select is-medium is-fullwidth">
      <select onChange={(e) => handleSelect(id, e.target.value)} value={value} required disabled={disabled}>
        <option value=''>select color</option>
        {COLOR_OPTIONS.map(color => (
          <option value={color}>
            {color}
          </option>
        ))}
      </select>
    </div>
  )
}

const defaultBidSelection: HorseRacePlayerBet = {
  raceNumber: 1,
  wager: '',
  stakedPoints: 0,
  choices: {},
}

const HorseRaceBidSheet = () => {
  const [bidSelection, setBidSelection] = useState<HorseRacePlayerBet>(defaultBidSelection);
  const [betDetailsModalOpen, setBetDetailsModalOpen] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>('');
  const [selectionsMissing, setSelectionsMissing] = useState<boolean>(true);
  const [duplicateSelections, setDuplicateSelections] = useState<boolean>(false);
  const { currentPlayer } = usePlayerData();
  const { value: tournamentData } = useTournamentData();

  const playerTotalScore = roundTo2Decimals(getTotalScore(currentPlayer?.score));
  const minWager = Math.max(0, Math.min(playerTotalScore, MinHorseRaceWager));
  const maxWager = Math.max(0, Math.min(playerTotalScore, MaxHorseRaceWager));
  const sortedWagerChoices = bidSelection.wager && HORSE_RACE_WAGERS[bidSelection.wager].choices.sort((a, b) => a.index - b.index) || [];
  const horseRaceData = tournamentData?.horseRace;
  const currentRace = horseRaceData && horseRaceData[bidSelection.raceNumber];
  const disableChooseWager = currentRace?.status && currentRace?.status !== 'NotStarted';


  const handlePlaceBet = async () => {
    if (!currentPlayer) return;
    await placeHorseRaceBet(currentPlayer, bidSelection);
    handleClose();
  }

  const handleWagerSelection = (wager) => {
    setBetDetailsModalOpen(true);
    setBidSelection({
      ...bidSelection,
      wager,
    })
  }

  const handleDetailChanges = (choiceId, selectedValue) => {
    setBidSelection({
      ...bidSelection,
      choices: {
        ...bidSelection.choices,
        [choiceId]: selectedValue,
      }
    });
  }

  const handleStakeChange = (points) => {
    const numPoints = parseInt(points);
    if (isNaN(numPoints)) return;

    if (numPoints < minWager) setInputError(`You must bet at least ${minWager} points`);
    else if (numPoints > maxWager) setInputError(`The max amount you can bet is ${maxWager} points`);
    else setInputError('');

    setBidSelection({
      ...bidSelection,
      stakedPoints: numPoints,
    });
  }

  const handleClose = () => {
    setBetDetailsModalOpen(false);
    setBidSelection({
      ...defaultBidSelection,
      raceNumber: bidSelection.raceNumber,
    });
  }

  const hasDuplicates = (array) => {
    return (new Set(array)).size !== array.length;
  }

  useEffect(() => {
    // handle input validation
    if (!bidSelection.wager) return;

    const wager = HORSE_RACE_WAGERS[bidSelection.wager];
    const selectionIds = wager.choices.map(x => x.id);
    const allSelectionsMade = selectionIds.every(x => bidSelection.choices[x]);

    if (allSelectionsMade) setSelectionsMissing(false);
    else setSelectionsMissing(true);

    const duplicateSelectionsMade = hasDuplicates(Object.values(bidSelection.choices));
    if (duplicateSelectionsMade) setDuplicateSelections(true);
  }, [bidSelection])
 

  const isConfirmDisabled = () => {
    if (!bidSelection.wager) return true;
    if (!bidSelection.stakedPoints) return true;
    if (inputError) return true;
    if (selectionsMissing) return true;
  }

  const showSelectionWarning = (choiceId) => {
    if (!bidSelection.choices[choiceId]) return true;
    else return false;
  }

  const handleRaceSelection = (value) => {
    const race: number = parseInt(value);
    if (isNaN(race)) return;

    setBidSelection({
      ...bidSelection,
      raceNumber: race,
    });
  }

  const estimatedPayout = bidSelection.stakedPoints ? bidSelection.stakedPoints * HORSE_RACE_WAGERS[bidSelection.wager].numericOdds : 0;

  return (
    <div className=''>
      <div>
        <label className='label'>1. Select a race</label>
        <div className="select is-medium is-fullwidth mb-5">
          <select value={bidSelection.raceNumber} onChange={(e) => handleRaceSelection(e.target.value)} required>
            <option value=''>select race</option>
            <option value={1}>Race 1</option>
            <option value={2}>Race 2</option>
            <option value={3}>Race 3</option>
          </select>
        </div>
      </div>

      <label className='label'>2. Select a bet</label>
      {Object.keys(HORSE_RACE_WAGERS).map(wager => (
        <div className="card mb-2">
          <header className="card-header">
            <div className="card-header-title p-3 is-flex is-justify-content-space-between">
              <p>{HORSE_RACE_WAGERS[wager].name}</p>
              <p>{HORSE_RACE_WAGERS[wager].odds} odds</p>
            </div>
          </header>
          <div className="card-content p-3">
            <div className="content is-flex is-justify-content-space-between">
              <div>
                {HORSE_RACE_WAGERS[wager].description}
              </div>
              <div>
                <button
                  className='button is-primary'
                  onClick={() => handleWagerSelection(wager)}
                  disabled={disableChooseWager}
                >
                  Choose
                </button>
              </div>
            </div>
          </div>

        </div>
      ))}

      {bidSelection.wager &&
        <div className={`modal ${betDetailsModalOpen && bidSelection.wager ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Choose bet details</p>
              <button className="delete" aria-label="close" onClick={() => handleClose()}/>
            </header>
            <section className="modal-card-body">
              <div className="is-flex is-justify-content-space-between">
                <h4 className='title is-4'>{HORSE_RACE_WAGERS[bidSelection.wager].name}</h4>
                <h5 className='subtitle is-5 mt-0'>{HORSE_RACE_WAGERS[bidSelection.wager].odds} odds</h5>
              </div>
              
              <p className='mb-4'>{HORSE_RACE_WAGERS[bidSelection.wager].description}</p>

              <div>
                {sortedWagerChoices.map(choice => {
                  const ChoiceSelect = choice.type === 'suit' ? SuitSelect : ColorSelect;
                  return (
                    <div className='mb-4'>
                      <label className='label'>{choice.label}</label>
                      <ChoiceSelect 
                        id={choice.id}
                        handleSelect={handleDetailChanges}
                      />
                      {showSelectionWarning(choice.id) &&
                        <p className="help is-danger">Please make a selection</p>
                      }
                    </div>
                  )
                })}
              </div>

              <div className="is-flex is-justify-content-space-between is-align-items-center mt-4">
                <label className='label'>Points to stake</label>
                <p>Available: {roundTo2Decimals(currentPlayer?.totalScore)}</p>
              </div>
              <input 
                className="input is-medium" 
                type="number" 
                min={minWager}
                max={maxWager}
                placeholder={`number between ${minWager}-${maxWager}`}
                value={bidSelection.stakedPoints}
                onChange={(e) => handleStakeChange(e.target.value)}
              />
              {inputError && 
                <p className="help is-danger">{inputError}</p>
              }

              {Boolean(bidSelection.stakedPoints) &&
                <div className='mt-3'>
                  <p><strong>Payout if correct:</strong> {estimatedPayout}</p>
                </div>
              }
              
              {selectionsMissing && 
                <p className="help is-danger">You must fill out every field</p>
              }
              {duplicateSelections && 
                <p className="help is-danger">You selected some options more than once, make sure this is correct</p>
              }

            </section>
            <footer className="modal-card-foot">
              <button className="button is-primary" onClick={handlePlaceBet} disabled={isConfirmDisabled()}>
                Confirm
              </button>
              <button className="button" onClick={() => handleClose()}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
      }

    </div>
  );
}

export default HorseRaceBidSheet;
