
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.975,"time":58500,"words":195}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "hungry-hungry-hippos"
    }}>{`Hungry Hungry Hippos`}</h3>
    <h4 {...{
      "id": "objective"
    }}>{`Objective`}</h4>
    <p>{`Bounce ping pong balls into as many cups as you can and drink the contents.  `}</p>
    <h4 {...{
      "id": "setup"
    }}>{`Setup`}</h4>
    <ul>
      <li parentName="ul">{`Place a number of cups (5 for each player) in a cluster in the center of a table`}</li>
      <li parentName="ul">{`Fill the cups about 1/3 of the way with the group’s chosen beverage`}</li>
      <li parentName="ul">{`Each player should get 1 ping pong ball  `}</li>
    </ul>
    <h4 {...{
      "id": "game-play"
    }}>{`Game Play`}</h4>
    <p>{`Players will gather around a table with a cluster of cups in the center. Once the game begins, players will simultaneously try to bounce their ping pong ball into one of the center cups.  `}</p>
    <p>{`If your ball lands in a cup, take that cup from the center and drink the contents.
`}<em parentName="p">{`Once the cup is empty`}</em>{`, you may stack it to the side and try to capture another cup.  `}</p>
    <p>{`When all the cups in the center have been claimed, the game is over.  `}</p>
    {
      /* #### Heats
      There will be 3 heats of 4-5 players:
      Heat 1: 5 players (25 cups)
      Heat 2: 5 players (25 cups)
      Heat 3: 4 players (20 cups) */
    }
    <h4 {...{
      "id": "scoring"
    }}>{`Scoring`}</h4>
    <p>{`Players earn 30 points for each cup they claim and drink completely.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "hungry-hungry-hippos",
    level: 3,
    title: "Hungry Hungry Hippos",
    children: [
        
      ]
  }
]

export const frontMatter = {}

