
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.09,"time":65400,"words":218}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "class-feature"
    }}>{`Class Feature`}</h3>
    <p><strong parentName="p">{`Divine Shield - 0 SP`}</strong><br parentName="p"></br>{`
`}{`Each time you gain a level, you automatically summon a protective bubble that can absorb one enemy spell before disappearing.  `}</p>
    <h3 {...{
      "id": "standard-spells"
    }}>{`Standard Spells`}</h3>
    <p><strong parentName="p">{`Heal - 1 SP`}</strong><br parentName="p"></br>{`
`}{`You heal yourself for up to 2 HP. You cannot exceed your maximum HP (your wizard level + 5).`}</p>
    <p><strong parentName="p">{`Silence - 1 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard. They cannot cast spells until they gain a level.`}</p>
    <p><strong parentName="p">{`Righteous Champion - 2 SP`}</strong><br parentName="p"></br>{`
`}{`Choose a wizard. They must help you during a drinking game in one of the following ways:  `}</p>
    <ol>
      <li parentName="ol">{`They do any required drinking for you. You may continue playing even while they are drinking.`}</li>
      <li parentName="ol">{`They play instead of you but you must do any required drinking yourself.  `}</li>
    </ol>
    <p>{`Whatever points you would win from that game are split between you and your champion.  `}</p>
    <p>{`You cannot choose a wizard that is already playing at the same time as you.  `}</p>
    <h3 {...{
      "id": "archmage-ability"
    }}>{`Archmage Ability`}</h3>
    <p><strong parentName="p">{`Tithe - 3 SP`}</strong><br parentName="p"></br>{`
`}{`Choose any game (other than Horse Race) that has finished and had scores added to the scoreboard.
You subtract `}<em parentName="p">{`10% of the GHD Points`}</em>{` won by every other wizard and may keep `}<em parentName="p">{`up to 25`}</em>{` of those points for yourself.
Any additional points collected are lost.`}<br parentName="p"></br>{`
`}{`Once `}<em parentName="p">{`any`}</em>{` cleric has tithed a game, it cannot be tithed again.  `}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "class-feature",
    level: 3,
    title: "Class Feature",
    children: [
        
      ]
  },
{
    id: "standard-spells",
    level: 3,
    title: "Standard Spells",
    children: [
        
      ]
  },
{
    id: "archmage-ability",
    level: 3,
    title: "Archmage Ability",
    children: [
        
      ]
  }
]

export const frontMatter = {}

