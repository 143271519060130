import React, { createContext, useState, useEffect, useContext } from 'react';
import { PlayerNotification } from '../../types';
import { useAuth } from '../../lib/auth';
import { onSnapshot } from '@firebase/firestore';
import { getPlayerNotificationsQuery } from '../../lib/notifications';

export interface NotificationsContextState {
  status: 'loading'|'error'|'success'; 
  value: PlayerNotification[]|null;
}

const Context = createContext<NotificationsContextState|null>(null);

export const useNotifications = (): NotificationsContextState => {
  const NotificationscontextState = useContext(Context);
  if (NotificationscontextState === null) {
    throw new Error('Notifications provider error');
  }
  return NotificationscontextState;
}

const NotificationsProvider = (props) => {
  const [state, setState] = useState<NotificationsContextState>({status: 'loading', value: null});
  const currentUser: any = useAuth();
  const uid = currentUser && currentUser.uid;

  // if authenticated, fetch player data
  useEffect(() => {
    // TODO: only fetch if user has an active tournament
    if (!currentUser) return;
    
    let unsubscribe: any;
    getPlayerNotificationsQuery(currentUser)
      .then((query) => {
        if (query) {
          unsubscribe = onSnapshot(query, (querySnapshot) => {
            const notifications: PlayerNotification[] = [];
            querySnapshot.forEach((doc) => {
              const notification = {id: doc.id, ...doc.data()} as PlayerNotification;
              console.log('notification :>> ', notification);
              notifications.push(notification);
            });
            
            setState({
              status: 'success',
              value: notifications,
            })
          });
        }
      })
      
    if (unsubscribe) return unsubscribe();
  }, [currentUser])

  return (
    <Context.Provider value={state}>
      {props.children}
    </Context.Provider>
  )
}

export default NotificationsProvider;

// Allows easily mocking data for unit tests without mocking API calls
export const TestNotificationsProvider = (props) => (
  <Context.Provider value={props.state}>
    {props.children}
  </Context.Provider>
);