import { User } from '@firebase/auth';
import React, {
  useState,
} from 'react';
import { useHistory } from "react-router-dom";
import { useAuth } from '../lib/auth';
import { createTournament, joinTournament, updateTournamentStatus } from '../lib/tournaments';
import { usePlayerData } from './data-providers/players-provider';
import { useTournamentData } from './data-providers/tournament-provider';


const Home = () => {
  const [tournamentCode, setTournamentCode] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const currentUser: User | null | undefined = useAuth();
  const playerData = usePlayerData();
  const currentPlayer = playerData.currentPlayer;
  const isHost = currentPlayer && currentPlayer.isHost;
  const history = useHistory();
  const tournamentData = useTournamentData();
  const tournamentStatus = tournamentData?.value?.status || 'NotStarted';

  async function handleCreateTournament() {
    await createTournament(currentUser, tournamentName);
    tournamentData.setRefresh(true);
    playerData.setRefresh(true);
  }

  async function handleJoinTournament() {
    await joinTournament(currentUser, tournamentCode);
    tournamentData.setRefresh(true);
    playerData.setRefresh(true);
    history.push('/score');
  }

  async function handleTournamentStatusChange(newStatus) {
    if (!tournamentData.value || !tournamentData.value.id) return;
    await updateTournamentStatus(tournamentData.value.id, newStatus);
  }

  return (
    <div className='section'>
      {tournamentData.value &&
        <div className='mb-6'>
          <div className="level is-mobile">
            <div className="level-item has-text-centered is-size-5">
              <div>
                <p className="heading">Active Tournament</p>
                <p className="title is-size-5">{tournamentData.value.name}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Join Code</p>
                <p className="is-size-5 title">{tournamentData.value.joinCode}</p>
              </div>
            </div>
          </div>

          {isHost &&
            <div className="level is-mobile">
              <div className="level-item has-text-centered">
                <button 
                  className='button is-primary' 
                  onClick={() => handleTournamentStatusChange('InProgress')} 
                  disabled={tournamentStatus === 'InProgress'}
                >
                  Start
                </button>
              </div>
              <div className="level-item has-text-centered">
                <button 
                  className='button is-warning' 
                  onClick={() => handleTournamentStatusChange('Paused')} 
                  disabled={tournamentStatus !== 'InProgress'}
                >
                  Pause
                </button>
              </div>
              <div className="level-item has-text-centered">
                <button 
                  className='button is-danger' 
                  onClick={() => handleTournamentStatusChange('Ended')} 
                  disabled={tournamentStatus === 'Ended' || tournamentData.value.status === 'NotStarted'}
                >
                  End
                </button>
              </div>
            </div>
          }
          
        </div>
        

      }

      <div className="level">
        <label className='label has-text-centered'>Join a tournament</label>
        <div className="level-item has-text-centered">
          <div className="field has-addons">
            
            <div className="control">
              <input
                type='text'
                value={tournamentCode}
                onChange={(e) => setTournamentCode(e.target.value)}
                placeholder="tournament code"
                className='input'
                maxLength={10}
              />
            </div>
            <div className="control">
              <button 
                className='button is-primary'
                onClick={handleJoinTournament}
                disabled={!tournamentCode}
              >
                Join
              </button>
            </div>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <p className="title is-6">OR</p>
          </div>
        </div>

        <label className='label has-text-centered'>Host a tournament</label>
        <div className="level-item has-text-centered">
          <div className="field has-addons">
            <div className="control">
              <input 
                type='text'
                value={tournamentName}
                onChange={(e) => setTournamentName(e.target.value)}
                className='input'
                placeholder='tournament name'
                maxLength={30}
              />
            </div>
            <div className="control">
              <button 
                className='button is-dark'
                onClick={handleCreateTournament}
                disabled={!tournamentName}
              >
                Host
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
