import React, { createContext, useState, useEffect, useContext } from 'react';
import { Tournament, UserDetails } from '../../types';
import { useAuth } from '../../lib/auth';
import { doc, onSnapshot } from '@firebase/firestore';
import { User } from '@firebase/auth';
import { getUserDetails } from '../../lib/user-details';
import { db } from '../../firebase';

export interface TournamentContextState {
  status: 'loading'|'error'|'success'; 
  value: Tournament|null;
  setRefresh: (refresh: boolean) => void;
}

const Context = createContext<TournamentContextState|null>(null);

export const useTournamentData = (): TournamentContextState => {
  const TournamentcontextState = useContext(Context);
  if (TournamentcontextState === null) {
    throw new Error('Tournament provider error');
  }
  return TournamentcontextState;
}

const TournamentDataProvider = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [state, setState] = useState<TournamentContextState>({status: 'loading', value: null, setRefresh: setRefresh});
  const currentUser: any = useAuth();

  useEffect(() => {
    // TODO: only fetch if user has an active tournament
    if (!currentUser) return;

    let unsubscribe: any;
    async function getActiveTournament(user: User) {
      if (!user) return null;
    
      const userDetails = await getUserDetails(user) as UserDetails;
      const activeTournamentId = userDetails.activeTournament;
    
      unsubscribe = onSnapshot(doc(db, 'tournaments', activeTournamentId), (doc) => {
        const activeTournament = { id: doc.id, ...doc.data() } as Tournament;
        setState({
          ...state,
          status: activeTournament ? 'success' : 'loading',
          value: activeTournament,
        });
        setRefresh(false);
      });
    }

    getActiveTournament(currentUser);

    if (unsubscribe) return unsubscribe();
  }, [currentUser, refresh])

  return (
    <Context.Provider value={state}>
      {props.children}
    </Context.Provider>
  )
}

export default TournamentDataProvider;

// Allows easily mocking data for unit tests without mocking API calls
export const TestTournamentDataProvider = (props) => {
  console.log('test TP')

  return (
  <Context.Provider value={props.state}>
    {props.children}
  </Context.Provider>
)};