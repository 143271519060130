import { db } from '../firebase';
import { 
  collection, 
  addDoc, 
  getDocs,
  updateDoc,
  doc,
  setDoc,
  query,
  where,
} from "firebase/firestore"; 

// create
export async function handleAddDoc(reference, data) {
  const docRef = await addDoc(collection(db, reference), data);
  return docRef;
}

export async function handleSetDoc(collectionName, docId, data) {
  const docRef = await setDoc(doc(db, collectionName, docId), data);
  return docRef;
}

// read
export async function getData(reference) {
  const querySnapshot = await getDocs(collection(db, reference));
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  })
  return data;
}

// update
export async function handleUpdateDoc(reference, data) {
  const docRef = await updateDoc(reference, data);
  return docRef;
}

export const generateFirestoreId = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  //assert(autoId.length === 20, 'Invalid auto ID: ' + autoId);
  return autoId;
}