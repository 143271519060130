import React, { useState } from 'react';
import { Player } from '../../../../types';

interface EquivalentExchangeProps {
  handleClick: (options: { exchange: string }) => void;
  activePlayer: Player;
}

export const ConvertHPtoSP = 'HP to SP';
export const ConvertSPtoHP = 'SP to HP';

const EquivalentExchange = ({ handleClick, activePlayer }: EquivalentExchangeProps) => {
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const activeWizard = activePlayer.wizard;

  const chooseExchange = (spellOptions) => {
    setSelectedExchange(spellOptions.exchange);
    handleClick(spellOptions);
  }

  return (
    <div className=''>
      <div className='pb-5'>
        <button 
          className={`button is-danger is-large is-fullwidth ${selectedExchange === ConvertSPtoHP ? '' : 'is-outlined'}`} 
          onClick={() => chooseExchange({exchange: ConvertSPtoHP})}
          disabled={activeWizard.spellPoints < 1}
        >
          Gain 1 HP, lose 1 SP
        </button>
      </div>
      <div>
        <button 
          className={`button is-info is-large is-fullwidth ${selectedExchange === ConvertHPtoSP ? '' : 'is-outlined'}`}
          onClick={() => chooseExchange({exchange: ConvertHPtoSP})}
          disabled={activeWizard.hitPoints < 1}
        >
          Gain 1 SP, lose 1 HP
        </button>
      </div>
    </div>
  );
}

export default EquivalentExchange;
