import React from 'react';
import dayjs from 'dayjs';
import { SpellEvent } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import { useSpellHistory } from '../../data-providers/spell-history-provider';


interface SpellActivityProps {
  type: 'allPlayers'|'currentPlayer';
}
interface SpellActivityRowProps {
  spellEvent: SpellEvent;
}

const SpellActivityRow = ({spellEvent}: SpellActivityRowProps) => {
  const { allPlayers } = usePlayerData();
  const caster = allPlayers.find(p => p.id === spellEvent.casterId);
  const casterName = caster?.userDetails?.displayName || '';
  const targetNames = allPlayers.filter(p => spellEvent.targetIds.includes(p.id)).map(p => p.userDetails?.displayName);

  // TODO: allow filtering by caster, targets, etc

  const getCasterClassStyle = () => {
    switch (spellEvent.spell.class) {
      case 'Cleric':
        return 'is-warning is-light';
      case 'Bard':
        return 'is-link is-light';
      case 'Druid':
        return 'is-success is-light';
      case 'Hydromancer':
        return 'is-info is-light';
      case 'Pyromancer':
        return 'is-danger is-light';
      case 'Necromancer':
        return '';
      default:
        return '';
    }
  }

  return (
    <div className={`notification ${getCasterClassStyle()} mb-1 py-2`}>
      <div className=''>
        <div className='pl-0'>{dayjs(spellEvent.timestamp).format('MMM D: h:mma')}</div>
        <div className=''>
          <span>
            <strong>{casterName}</strong> cast <strong>{spellEvent.spell.name}</strong> {targetNames.length > 0 ? 'at ' : ''} 
            <strong>{targetNames.join(', ')}</strong>
            {spellEvent.spellOptionsMessage && <i>&nbsp;{spellEvent.spellOptionsMessage}</i>}
            {spellEvent.empoweredMessage && <i>&nbsp;{spellEvent.empoweredMessage}</i>}
          </span>
        </div>
      </div>
    </div>
  )
}

const SpellActivityFeed = ({ type }: SpellActivityProps) => {
  const { allPlayers } = usePlayerData();
  const { allSpellHistory, playerSpellHistory } = useSpellHistory();
  const spellActivity = type === 'allPlayers' ? allSpellHistory : playerSpellHistory;

  return (
    <div className='section'>
      <h4 className='title is-4'>Spell History</h4>
      <div className='spell-history-rows'>
        {allPlayers.length > 0 && spellActivity.map(spellEvent => (
          <SpellActivityRow 
            spellEvent={spellEvent}
          />
        ))}
      </div>
    </div>
  );
}

export default SpellActivityFeed;
