import React from 'react';
import { Player } from '../../../../types';
import { usePlayerData } from '../../../data-providers/players-provider';

const MagicalAttunement = () => {
  const { currentPlayer, allPlayers } = usePlayerData();
  const allOtherPlayers: Player[] = allPlayers.filter(x => x.id !== currentPlayer?.id);

  return (
    <div className=''>
       <table className='table is-striped is-fullwidth has-text-centered'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Class</th>
            <th>SP</th>
            <th>Conditions</th>
          </tr>
        </thead>
        <tbody>
          {allOtherPlayers.map(p => {
            const playerName = p.userDetails?.displayName;
            const conditions = Object.keys(p.wizard.conditions);
            return (
              <tr>
                <td>{playerName}</td>
                <td>{p.wizard.class}</td>
                <td>{p.wizard.spellPoints}</td>
                <td>{conditions.map(condition => (
                  <div>{condition}</div>
                ))}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      
    </div>
  );
}

export default MagicalAttunement;
