import React, { useState } from 'react';
import { usePlayerData } from '../../data-providers/players-provider';
import { ConditionDescriptions } from '../../../constants';

const ConditionDetails = ({show}) => {
  const { currentPlayer } = usePlayerData();
  const currentWizard = currentPlayer?.wizard;
  const conditions = currentWizard?.conditions || [];
  const [showConditionDetails, setShowConditionDetails] = useState(false);

  const toggleConditionDetails = () => {
    setShowConditionDetails(!showConditionDetails);
  }

  if (!show) return <></>;

  return (
    <div>
      <article className="message is-small is-warning">
        <div className="message-body is-flex is-align-items-center is-justify-content-space-between">
          <p className=''>
            You are affected by certain conditions
          </p>
          <button className='button is-small is-warning' onClick={toggleConditionDetails}>
            More
          </button>
        </div>
      </article>

      <div className={`modal ${showConditionDetails ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Conditions</p>
              <button 
                className="delete" 
                aria-label="close" 
                onClick={toggleConditionDetails}
              />
            </header>
            <section className="modal-card-body">
              {Object.keys(conditions).map(x => {
                const conditionName = conditions[x].conditionName;
                return (
                  <div className='pb-3'>
                    <label className='label'>{conditionName}</label>
                    <p>{ConditionDescriptions[conditionName]}</p>
                  </div>
                )
              })}

            </section>
        </div>
      </div>
    </div>
  );
}

export default ConditionDetails;
