import React, { useState } from 'react';
import { convertStringToId } from '../../../../lib/utils';
import { Spell } from '../../../../types';
import { SPELLS } from '../../../../constants';
import SpellCard from '../spell-card';
import { usePlayerData } from '../../../data-providers/players-provider';

interface Props {
  handleSpellSelect: (spell: Spell|null) => void;
  selectedSpell: Spell|null;
}

const RushOfKnowledge = ({ handleSpellSelect, selectedSpell }: Props) => {
  const [expandedSpell, setExpandedSpell] = useState<string>('');
  const { currentPlayer } = usePlayerData();

  const toggleExpandedSpell = (spellId) => {
    if (expandedSpell === spellId) setExpandedSpell('');
    else setExpandedSpell(spellId);
  }

  const NON_BARD_SPELLS = [
    ...SPELLS.Cleric,
    ...SPELLS.Druid,
    ...SPELLS.Hydromancer,
    ...SPELLS.Necromancer,
    ...SPELLS.Pyromancer,
  ];

  const filteredSpells = NON_BARD_SPELLS.filter(s => s.type !== 'Class Feature' && s.type !== 'Archmage Ability');
  // sort alphabetically
  filteredSpells.sort((a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
  });

  return (
    <>
      {currentPlayer && !selectedSpell && filteredSpells.map((s: Spell) => {
        const spellId = convertStringToId(s.name);
        return (
          <SpellCard 
            spell={s} 
            expanded={expandedSpell === spellId} 
            toggleExpand={toggleExpandedSpell} 
            onSpellSelect={handleSpellSelect}
            spellSelectText='Select'
            player={currentPlayer}
          />
        )
      })}


      {selectedSpell && 
        <div className='has-text-centered'>
          <label className='label is-size-5'>
            Selected spell: {selectedSpell.name}
          </label>
          
          <button className='button is-link mb-6' onClick={() => handleSpellSelect(null)}>
            Pick another spell
          </button>
        </div>
      }
    </>
  );
}

export default RushOfKnowledge;
