import React, {
  useState,
} from 'react';
import { Player, Spell } from '../../../types';
import { usePlayerData } from '../../data-providers/players-provider';
import { SPELLS } from '../../../constants';
import { convertStringToId } from '../../../lib/utils';
import SpellCaster from './spell-caster';
import SpellCard from './spell-card';
import { levelUpWizard } from '../../../lib/wizards';
import { playerHasCondition } from './spell-helpers';
import { WizardConditions } from '../../../constants';

const Spellbook = () => {
  const [expandedSpell, setExpandedSpell] = useState<string>('');
  const [activeSpell, setActiveSpell] = useState<Spell | any>(); // spell being cast
  const [activePlayer, setActivePlayer] = useState<Player | any>(); // player casting spell
  const [spellCasterOpen, setSpellCasterOpen] = useState<boolean>(false);
  const [confirmLevelModalOpen, setConfirmLevelModalOpen] = useState<boolean>(false);
  const { allPlayers, currentPlayer} = usePlayerData();
  const currentWizard = currentPlayer?.wizard;

  const handleSpellCast = (spellDetails: Spell) => {
    // Normal spell logic
    setExpandedSpell('');
    setActiveSpell(spellDetails);
    setActivePlayer(currentPlayer);
    setSpellCasterOpen(true);
  }

  const handleControlledPlayerSpellCast = (spellDetails: Spell, controlledPlayer: Player) => {
    setExpandedSpell('');
    setActiveSpell(spellDetails);
    setActivePlayer(controlledPlayer);
    setSpellCasterOpen(true);
  }

  const toggleExpandedSpell = (spellId) => {
    if (expandedSpell === spellId) setExpandedSpell('');
    else setExpandedSpell(spellId);
  }

  const confirmLevelUp = async () => {
    setConfirmLevelModalOpen(true);
  }

  const handleLevelUp = async () => {
    if (!currentPlayer) return;

    await levelUpWizard(currentPlayer, allPlayers);
    setConfirmLevelModalOpen(false);
  }

  const handleCloseSpellCaster = () => {
    setSpellCasterOpen(false);
    setActiveSpell(null);
  }

  const conditions = currentWizard?.conditions || {};
  const playerIsReanimated = Object.keys(conditions).includes(WizardConditions.Reanimated);
  const levelUpDisabled = playerIsReanimated;

  const isControllingWizard = currentPlayer && playerHasCondition(currentPlayer, WizardConditions.Controlling);
  let controlledPlayers: Player[] = [];
  if (isControllingWizard) {
    const affectedPlayers = conditions[WizardConditions.Controlling].affectedPlayers;
    controlledPlayers = allPlayers.filter(p => affectedPlayers?.includes(p.id));
  }

  const showPlayerSpells = currentPlayer && currentWizard && currentWizard.class && currentWizard.hitPoints > 0;
  const currentPlayerSpells = currentWizard && SPELLS[currentWizard.class] || [];

  return (
    <div className=''>
      {currentWizard && !currentWizard.class &&
        <div className='content'>
          <div><p>You must select a class before you can cast spells.</p></div>
          <div className="pt-4 has-text-centered">
            <a href='/wisest-wizard/classes' className='button is-primary is-fullwidth'>
              Choose class
            </a>
          </div>
        </div>
      }

      {currentWizard && currentWizard.hitPoints < 1 &&
        <div className='content'>
          <div><p>You are dead. You must choose another class and be born again.</p></div>
          <div className="pt-4 has-text-centered">
            <a href='/wisest-wizard/classes' className='button is-primary is-fullwidth'>
              View class options
            </a>
          </div>
        </div>
      }

      {currentWizard?.class && currentWizard.hitPoints > 0 &&
        <button
          className='button is-primary is-fullwidth is-rounded mb-3'
          onClick={() => confirmLevelUp()}
          disabled={levelUpDisabled}
        >
          Level up
        </button>
      }

      {/* Current player's spells */}
      {showPlayerSpells && currentPlayerSpells.map((s: Spell) => {
        const spellId = convertStringToId(s.name);
        return (
          <SpellCard
            spell={s}
            expanded={expandedSpell === spellId}
            toggleExpand={toggleExpandedSpell}
            onSpellSelect={handleSpellCast}
            spellSelectText='Cast'
            player={currentPlayer as Player}
          />
        )

      })}

      {isControllingWizard && controlledPlayers.map(controlledPlayer => {
        return (
          <div>
            <p className='is-size-5 has-text-centered pt-5 pb-1'>Cast spells as {controlledPlayer.userDetails?.displayName} ({controlledPlayer.wizard.class})</p>
            {SPELLS[controlledPlayer.wizard.class].map((s: Spell) => {
              const spellId = convertStringToId(s.name);
              return (
                <SpellCard
                  spell={s}
                  expanded={expandedSpell === spellId}
                  toggleExpand={toggleExpandedSpell}
                  onSpellSelect={() => handleControlledPlayerSpellCast(s, controlledPlayer)}
                  spellSelectText='Cast'
                  player={controlledPlayer}
                />
              )
            })}
          </div>
        )
      })}

      {activeSpell &&
        <SpellCaster
          open={spellCasterOpen}
          activeSpell={activeSpell}
          activePlayer={activePlayer}
          handleClose={handleCloseSpellCaster}
        />
      }

      <div className={`modal ${confirmLevelModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Level up</p>
            <button className="delete" aria-label="close" onClick={() => setConfirmLevelModalOpen(false)}/>
          </header>
          <section className="modal-card-body">
            I solemnly swear that I have responsibly consumed an approved beverage.
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary" onClick={handleLevelUp}>
              Confirm
            </button>
            <button className="button" onClick={() => setConfirmLevelModalOpen(false)}>
              Cancel
            </button>
          </footer>
        </div>
      </div>

    </div>
  );
}

export default Spellbook;
